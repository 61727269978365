.custom-toast {
  position: fixed;
  top: 20px;
  right: -300px; /* Initially off-screen */
  background-color: #fff;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999999;
  width: 300px;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.is-uppdate-done .progress-bar{
  background-color: #4fc008 !important;
}

.is-uppdate-done span {
    color: #4fc008;
}

.custom-toast.visible {
  transform: translateX(-350px); /* Slide into view */
  opacity: 1;
}

.custom-toast.hidden {
  transform: translateX(300); /* Slide out of view */
  opacity: 0;
}
