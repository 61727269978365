.notification-canvas {
    width: 588px !important;
}

.notification-canvas .accordion-button::after{
    display: none;
}

.faq-model-faq-title {
    color: var(--font-color);
    font-family: var(--text-font-family);
    font-size: var(--heading-size);
    font-style: normal;
    font-weight: var(--text-font-weight);
    line-height: normal;
    letter-spacing: -0.6px;
    margin-top: 7px;
}

.faq-model-faq-title strong {
    font-weight: var(--heading-weigth);
}

.faq-card-main {
    border-radius: 6px;
    border: 1px solid #EFF2FF;
    display: inline-flex;
    align-items: start;
    justify-content: space-between;
    padding: 6px;
}

.faq-card-main p {
    color: var(--font-color);
    font-family: var(--text-font-family);
    font-size: var(--text-size);
    margin: 0 !important;
    padding: 0 !important;

}

.accordion-button:focus {
    border-color: var(--app-color-1) !important;
}
.accordion-button:not(.collapsed) {
    background-color: var(--icon-color) ;
    color: #EFF2FF;
    
}

.custom-accordion .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .custom-icon {
    margin-left: auto; /* Push icon to the right */
    font-size: 20px; /* Adjust icon size */
    color: var(--accordion-icon-color);
    transition: transform 0.3s ease-in-out, color 0.3s;
  }
  
  /* Rotate icon when accordion is open */
  .accordion-button:not(.collapsed) .custom-icon {
    transform: rotate(180deg);
  }
@media (max-width: 650px) {
    .notification-canvas {
        width: 85% !important;
    }
  }
