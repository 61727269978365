.gif-wraper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #e9eeff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}

.gif-wraper-main {
  width: 100vw;
  height: 100vh;
  background: var(--background-dashboard-color);
  transition: background-color var(--transition-duration)
    var(--transition-timing-function);
}

.gif-child-wraper {
  background: #fff;
  border-radius: 20px 0 0 0;
  height: 100vh;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gif-child-wraper .spinner-border {
  color: #9fabff;
}

.gif-child-wraper img {
  margin-bottom: 50px;
}

.progress-wrap-container {
  max-width: 90%;
}

.initial-progress-wrap {
  height: 100vh;
}

.progressbar-wrapper {
  margin-left: 100px;
  width: 313px;
}

.progressbar-wrapper div div {
  background-color: #9fabff !important;
}

.progress-bar-text {
  font-size: 17px;
  font-weight: 300;
}

.screenloader-image {
  max-width: 450px;
  margin-bottom: 21px;
}

@media (max-width: 500px) {
  .screenloader-image {
    max-width: 90%;
  }

  .progressbar-wrapper {
    max-width: 64%;
  }
}

@media (max-width: 365px) {
  .progressbar-wrapper {
    width: 90%;
  }

  .screenloader-image {
    width: 90%;
  }

  .progress-bar-text {
    font-size: 14px;
  }
}
