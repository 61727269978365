.performance-working-modal-main {
  width: 100%;
  max-width: 70% !important;
}

.performance-working-modal-main .modal-content {
  border-radius: 24px !important;
  width: 100%;
}

.performance-working-modal-main .modal-body {
  padding: 20px 26px;
}

.how-performance-works-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.performance-working-modal-divider {
  height: 1px;
  width: 100%;
  background-color: #dee2f1;
}
.see-task-list-btn:hover {
  background: var(--selected-route-color-svg);
  color: white;
}

.see-task-list-btn {
  border: 1px solid var(--selected-route-color-svg);
  padding: 8px 18px;
  border-radius: 6px;
  font-size: 13px;
  color: var(--selected-route-color-svg);
  font-weight: 500;
}
@media (max-width: 767px) {
  .performance-working-modal-main {
    width: 100%;
    max-width: 98% !important;
  }
  .performance-total-main-wrap
    ul
    li:nth-child(2)
    .performance-total-list-wrap
    strong {
    color: #eca944;
    position: relative;
    left: 0px;
  }
}
