.menu-btn {
  width: 35px;
  height: 24.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.menu-btn svg {
  color: var(--exotic-font-color);
  width: 90%;
  height: 90%;
}

.menu-btn:hover {
  background-color: var(--app-color-2) !important;
}

.menu-btn:hover svg {
  color: #fff;
}
