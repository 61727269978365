.permission-header-h5 {
  color: var(--app-color-1);
  /* margin-bottom: 0 !important; */
  font-weight: var(--text-font-weight);
  font-size: 15px;
  cursor: pointer;
}

.module-modal-wrap {
  padding: 0 !important;
}

.main-module-modal .modal-header {
  border-bottom: none !important;
}

.module-select-heading {
  margin-bottom: 0 !important;
}

.user-role-show-more-button {
  color: var(--app-color-1);
  font-weight: var(--text-font-weight);
  font-size: 13px;
}

.user-role-permission-name {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
}

.no-line::after {
  content: "" !important;
}

.user-profile-modules-categories-expand {
  width: 35px;
  height: 35px;
  background-color: var(--app-color-1);
  color: white;
}

.user-permission-modules-dropdown {
  z-index: 10 !important;
}

.user-permission-modules-dropdown li {
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  padding-top: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.user-role-permission-name::after {
  content: "|";
  padding-left: 3px;
  font-weight: 500;
}

.history-date input {
  border: 1px solid #f1f4ff !important;
}

.user-role-permission-name:last-child::after {
  content: "";
  padding-left: 3px;
  font-weight: 500;
}

.custom-input-calender {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--app-color-1);
}

.custom-input-calender svg {
  margin-left: 5px;
  margin-bottom: 3px;
}

.custom-input-calender span {
  margin-left: 5px;
  color: #333;
}

.change-role-note {
  font-size: var(--text-size);
}

.permission-module-listing-wap {
  display: flex;
  flex-wrap: nowrap;
  /* overflow: hidden; */
}

.permission-module-listing-wap ul {
  display: block;
  list-style: none;
  margin: 0 !important;
  gap: 30px;
}

.permission-module-listing-wap ul li {
  background: #b0e57c;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  padding-top: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.permission-module-listing-wap ul li:not(:first-child) {
  margin-left: 8px;
}

.more-modules-btn-userinfo {
  background-color: var(--app-color-2) !important;
  color: #fff !important;
}

.more-modules-btn-userinfo:hover {
  background-color: var(--app-color-1) !important;
}

/* .permission-listing-wap ul li:not(:first-child) { */
/* margin-left: -14px; */
/* } */

actions-button-cell:has(.MultiColumn) {
  overflow: visible;
}

.ag-cel:has(.MultiColumn)l {
  overflow: visible;
}

.ag-row:has(.MultiColumn) {
  z-index: 0;
}

.ag-row.ag-row-focus:has(.MultiColumn) {
  z-index: 1;
}

.ag-root:has(.MultiColumn),
.ag-root-wrapper:has(.MultiColumn),
.ag-body-viewport:has(.MultiColumn),
.ag-body-viewport-wrapper:has(.MultiColumn),
.ag-center-cols-clipper:has(.MultiColumn),
.ag-center-cols-viewport:has(.MultiColumn) {
  overflow: visible !important;
}

.overview-profile .shimmer {
  width: 100%;
}

.profile-image-name-wrap-edit .profile-dash-image {
  height: 240px;
  border: 10px solid #fff;
  background-size: cover !important;
  border-radius: 15px;
  width: 240px;
  margin: 0 auto;
  /* margin-bottom: 10px; */
  box-shadow: 0px -2px 10.2px 0px rgba(174, 174, 174, 0.25);
}

.modal-header {
  border-bottom: 1px solid var(--divider-color);
}

.modal-header .btn-close {
  background-color: var(--search-bar-bg);
  border: 1px solid var(--divider-color);
  color: var(--font-color);
}

.profile-edit-btn-profile a {
  position: absolute;
  background-color: var(--icon-color);
  bottom: 10px;
  right: 10px;
  z-index: 4;
  border-radius: 5px;
  box-shadow: inset;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.profile-edit-btn-profile a img {
  width: 18px;
  height: 18px;
}

.edit-profile-modal-footer {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 20px; /* Space between buttons */
  padding: 20px !important;
  background-color: var(
    --bs-modal-footer-bg,
    #f8f9fa
  ); /* Optional background */
  border-top: 1px solid var(--divider-color);
}

.edit-profile-modal-footer-button-1,
.edit-profile-modal-footer-button-2 {
  padding: 0.75rem;
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Ensure buttons span their full grid space */
}

.edit-profile-modal-footer-button-1 {
  background-color: var(--icon-color2); /* Primary Button Color */
  color: #ffffff;
}

.edit-profile-modal-footer-button-2 {
  background-color: #e5ebff; /* Secondary Button Color */
  color: var(--app-color-2);
}

.edit-profile-modal-footer-button-1:hover {
  background-color: var(--icon-color);
}

.edit-profile-modal-footer-button-1:disabled,
.edit-profile-modal-footer-button-2:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.user-performance-template-settings {
  border-radius: 10px;
  border: 1px solid var(--divider-color);
  background: var(--card-color);
  padding: 20px;
}

.user-performance-template-settings-header h4 {
  font-size: 18px;
}

.user-performance-template-settings-select {
  max-width: 300px;
}

.user-performance-template-settings-detail-col {
  border-radius: 8px;
  border: 1px solid #f1f4ff;
  background: #fff;
  padding: 20px;
}

.user-performance-template-settings-detail-col h5 {
  color: var(--app-color-2);
  font-size: 16px;
  font-weight: var(--heading-weight);
}

.user-performance-template-settings-detail-col p {
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.user-performance-template-points {
  font-weight: 600 !important;
}

.user-performance-template-points-border {
  border-bottom: 1px solid #f1f4ff;
}
