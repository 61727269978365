/* Company profile edit */
.company-edit-modal-logo {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.company-edit-modal-logo-label {
  position: relative;
  cursor: pointer;
}

.company-edit-modal-logo-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd;
}

.company-edit-modal-logo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company-edit-modal-logo-input {
  display: none;
}

.company-edit-modal-select {
  appearance: auto;
}

.company-edit-modal-logo-edit-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 5px;
}

.company-edit-modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.company-edit-modal-row {
  display: flex;
  gap: 20px;
}

.company-edit-modal-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.company-edit-modal-column label,
.company-edit-modal-full label {
  align-items: center;
  display: flex;
  font-size: var(--text-size);
  font-weight: var(--heading-weight);
  margin-bottom: 8px;
}

.company-edit-modal-full {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.company-edit-modal-input:focus {
  background: var(--card-color);
  color: var(--font-color);
}

.company-edit-modal-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--divider-color);
  background: var(--card-color);
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  height: 40px;
}
.company-edit-modal-input-area:focus {
  background: var(--card-color);
  color: var(--font-color);
}
.company-edit-modal-input-area {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--divider-color);
  background: var(--card-color);
  font-size: var(--text-size);
  color: var(--font-color);
  font-weight: var(--text-font-weight);
}

.company-profile-dash-image {
  background-size: cover !important;
  border: 10px solid var(--search-bar-bg);
  border-radius: 15px;
  box-shadow: 0 -2px 10.2px 0 #aeaeae40;
  height: 240px;
  margin: 0 auto;
  width: 240px;
}

.company-profile-edit-btn-profile a {
  background-color: var(--icon-color);
  border-radius: 5px;
  bottom: 10px;
  box-shadow: inset;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 10px;
  width: 30px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-profile-edit-btn-profile a img {
  height: 18px;
  width: 18px;
}

/* Working hour modal */
/* Container for the entire body content */
.set-hour-container {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Section Labels */
.set-hour-label {
  align-items: center;
  display: flex;
  font-size: var(--text-size);
  font-weight: var(--heading-weight);
  margin-bottom: 8px;
  color: var(--exotic-font-color);
}

/* Input field styles */
.set-hour-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--divider-color);
  background: var(--search-bar-bg);
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  height: 40px;
}

.set-hour-input:focus {
  background: var(--card-color);
  color: var(--font-color);
}

/* Days Section */
.set-hour-days-section {
  display: flex;
  flex-direction: column;
}

.set-hour-days-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

/* Day Button Styles */
.set-hour-day-button {
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid var(--divider-color);
  background: var(--search-bar-bg);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.set-hour-day-button:hover {
  background-color: var(--icon-color);
  border-color: var(--icon-color);
  color: #fff;
}

.set-hour-day-selected {
  background-color: var(--icon-color);
  color: #fff;
  border-color: var(--icon-color);
}

/* Timing Section */
.set-hour-timing-section {
  display: flex;
  flex-direction: column;
}

.set-hour-timing-container {
  display: flex;
  gap: 16px;
}

.set-hour-time {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.set-hour-time-label {
  font-size: 14px;
  color: var(--font-color);
  margin-bottom: 8px;
}

.set-hour-time-input {
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.2s;
}

/* .set-hour-time-input:focus {
  border-color: #007bff;
} */

.set-hour-input svg {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  color: var(--app-color-1);
}

.select-hour-icon-date {
  position: absolute;
  right: 0;
}

.invalid-feedback-days {
  text-align: end;
  color: #e93131;
  font-size: 13px;
}

.select-hour-time-picker-slot {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.15s ease;
  border: 1px solid var(--divider-color) !important;
  color: var(--exotic-font-color);
  font-weight: 500;
  background-color:var(--card-color) !important;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.select-hour-time-picker-slot:hover {
  background-color: var(--divider-color) !important;
  color: var(--icon-color);
}

.select-hour-time-picker-slot.active {
  background-color: var(--icon-color) !important;
  color: white;
}

.select-hour-time-picker-dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  background: var(--search-bar-bg);
  border: 1px solid var(--divider-color);
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
}

/* Custom scrollbar for time slots */
.select-hour-time-picker-dropdown::-webkit-scrollbar {
  width: 6px;
}

.select-hour-time-picker-dropdown::-webkit-scrollbar-track {
  background: var(--card-color);
  border-radius: 10px;
}

.select-hour-time-picker-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.select-hour-time-picker-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.invalid-field {
  text-align: end;
  color: #e93131;
  font-size: 13px;
  margin-top: 0.25rem;
}
