.company-profile-main-wrapper {
  background: var(--card-color);
  border-radius: 20px 0 0 20px;
  min-height: 93dvh;
  padding: 25px;
}

/* Company info section */
.company-info-wrapper {
  border-radius: 10px;
  border: 1px solid var(--divider-color);
  background: var(--card-color);
  padding: 20px;
  margin-bottom: 20px;
}

.company-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.company-info-header p {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: var(--font-color);
}

.company-info-header p span {
  font-weight: var(--text-font-weight);
}

.company-profile-edit-button {
  border-radius: 8px;
  border: 0.7px solid var(--icon-color);
  background: var(--card-color);
  flex-shrink: 0;
  color: var(--exotic-font-color);
  height: 35px;
  width: 75px;
  font-size: var(--text-size);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.company-profile-edit-button svg {
  color: var(--icon-color);
}

.company-profile-edit-button:hover {
  background: var(--icon-color2);
  color: #fff;
  border: 0.7px solid var(--icon-color2);
}

.company-profile-edit-button:hover svg {
  color: #fff;
}

.company-info-section {
  display: flex;
  align-items: start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.company-info-image-section {
  border-radius: 8px;
  background: var(--card-color);
  box-shadow: 0 2px 10.2px 0 #aeaeae40; /* Adjusted for a deeper, softer shadow */
  padding: 5px;
  display: inline-block; /* Ensure it wraps around content */
}

.company-info-image-section img {
  border-radius: 8px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  background-repeat: no-repeat;
}

.company-info-basic {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.company-info-basic h3 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: var(--exotic-font-color);
}

.company-info-basic p {
  font-size: var(--text-size);
  font-weight: var(--heading-weight);
}

.company-info-basic p svg {
  color: var(--icon-color);
  margin-right: 5px;
}

.company-info-about h3 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  margin-bottom: 10px;
  color: var(--font-color);
}

.company-info-about p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

/* Company work hours*/
.company-work-wrapper {
  border-radius: 10px;
  border: 1px solid var(--divider-color);
  background: var(--card-color);
  padding: 20px;
  margin-bottom: 20px;
}

.company-work-header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.company-work-header p {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: var(--font-color);
}

.company-work-header p span {
  font-weight: var(--text-font-weight);
}

.company-work-button {
  border-radius: 8px;
  border: 0.7px solid var(--icon-color);
  background: var(--card-color);
  flex-shrink: 0;
  color: var(--exotic-font-color);
  height: 35px;
  width: 130px;
  font-size: var(--text-size);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.company-work-button svg {
  color: var(--icon-color);
}

.company-work-button:hover,
.company-work-delete-button:hover {
  background: var(--icon-color2);
  color: #fff;
  border: 0.7px solid var(--icon-color2);
}

.company-work-button:hover svg,
.company-work-delete-button:hover svg {
  color: #fff;
}

.company-work-hours-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.company-work-hour-item-wrapper {
  display: flex;
  align-items: stretch;
  gap: 24px;
  width: 100%;
}

.company-work-hour-item {
  flex: 1;
  border-radius: 8px;
  background: var(--card-color);
  box-shadow: 0px 4px 11.3px 0px rgba(0, 0, 0, 0.07);
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.company-work-hour-item-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: end;
  max-width: 100%;
}

.company-work-delete-button {
  border-radius: 8px;
  border: 0.7px solid var(--icon-color);
  background: var(--card-color);
  flex-shrink: 0;
  color: var(--exotic-font-color);
  height: 35px;
  width: 110px;
  font-size: var(--text-size);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.company-work-delete-button svg {
  color: #ff4c4c;
}

.company-work-hour-item-days {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 285px;
}

.company-work-hour-item-time {
  min-width: auto;
}

.company-work-hour-item-days-text p {
  color: #999;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.company-work-hour-item-days-text h3 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: var(--font-color);
}

.company-work-hour-item-days-icon {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eff2ff;
  border-radius: 8px;
}

.company-work-hour-item-days-icon svg {
  color: var(--app-color-1);
}

.company-work-hour-item-active-btn {
  font-size: var(--text-size);
  color: #fff;
  border-radius: 3px;
  background: #2dbf64;
  border: none;
  height: 30px;
  min-width: 70px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  gap: 5px;
}

.company-work-hour-item-inactive-btn {
  font-size: var(--text-size);
  color: #333;
  border-radius: 3px;
  background: #ccc;
  border: none;
  height: 30px;
  min-width: 90px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  gap: 5px;
}

.company-work-hour-item-section-1 {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 60%;
  justify-content: space-between;
}

.company-work-hour-item-vr {
  background: var(--app-color-1);
}

/* Company members */
.company-members {
  border-radius: 10px;
  border: 1px solid var(--divider-color);
  background: var(--card-color);
  padding: 20px;
}

.company-members-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.company-members-header p {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: var(--font-color);
}

.company-members-header p span {
  font-weight: var(--text-font-weight);
}

.company-invite-member-button {
  border-radius: 8px;
  border: 0.7px solid var(--icon-color);
  background: var(--card-color);
  flex-shrink: 0;
  color: var(--exotic-font-color);
  height: 35px;
  width: 150px;
  font-size: var(--text-size);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.company-invite-member-button svg {
  color: var(--icon-color);
}

.company-invite-member-button:hover {
  background: var(--icon-color2);
  color: #fff;
  border: 0.7px solid var(--icon-color2);
}

.company-invite-member-button:hover svg {
  color: #fff;
}

.company-members-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Responsive columns */
  gap: 16px;
}

.company-member-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 12px;
  box-shadow: 0px 4px 11.3px 0px rgba(0, 0, 0, 0.07);
  background: var(--card-color);
  cursor: pointer;
}

.company-member-item:hover {
  background: var(--chat-input-bg);
}

.company-members-more-then-one .company-member-item {
  transition: transform 0.5s ease, box-shadow 0.2s ease;
}

.company-members-more-then-one .company-member-item:hover {
  transform: scale(1.03);
}

.company-member-image-container {
  min-width: 64px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid var(--app-color-1);
  overflow: hidden;
}

.company-member-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining its aspect ratio */
  object-position: center; /* Centers the image within the container */
}

.company-member-item-team-info {
  flex-grow: 1;
  min-width: 0; /* Critical for flex containers to allow children to shrink */
}

.company-member-item-name {
  color: var(--font-color);
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis (...) to indicate overflow */
  max-width: 100%; /* Ensures the name respects the available space */
  display: block; /* Ensures it behaves as a block or inline-block element */
}

.company-member-item-role {
  font-size: var(--text-size);
  color: #999;
}

@media (max-width: 1470px) {
  .company-work-hour-item-section-1 {
    width: 80%;
  }
}

@media (max-width: 1167px) {
  .company-work-hour-item-vr {
    display: none;
  }
}

@media (max-width: 767px) {
  .company-work-hour-item-wrapper {
    flex-direction: column;
  }

  .company-work-hour-item-actions {
    flex-direction: row;
  }

  .company-work-hour-item-days {
    min-width: auto;
  }
}

@media (max-width: 525px) {
  .company-work-hour-item-section-1 {
    width: 100%;
  }

  .company-work-hour-item {
    flex-wrap: wrap-reverse;
    gap: 10px;
  }
}

@media (max-width: 425px) {
  .company-members-list {
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Allow items to shrink further if needed */
  }

  .company-edit-modal-row {
    flex-direction: column;
  }
}
