.timeline-dashboard-wraper {
  padding: 25px;
  padding-bottom: 75px;
  border-radius: 20px 0 0px 0px;
  background: var(--card-color);
  min-height: 100vh;
}

.timeline-dashboard-wraper p {
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.time-line-field-wrap h3 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  margin-bottom: 0;
}

.timeline-listing-search {
}

.timeline-listing-search ul {
  list-style: none;
  text-align: right;
  margin-bottom: 0;
}

.timeline-listing-search ul li {
  display: inline-block;
  margin-left: 10px;
}

.timeline-listing-search ul li:nth-child(1) {
  width: 250px;
}

.timeline-listing-search ul li .form-group {
  position: relative;
}

.timeline-listing-search ul li .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  background: none;
  border: none;
  outline: none;
}

.timeline-listing-search ul li .form-group button img {
  width: 20px;
}

.timeline-listing-search ul li .form-group .form-control::placeholder{
  color: var(--font-color);
} 

.timeline-listing-search ul li .form-group .form-control {
  border: 1px solid var(--divider-color);
  background-color: var(--search-bar-bg);
  color: var(--font-color);
  border-radius: 4px;
  box-shadow: none;
  font-size: 12px;
  height: 35px;
  padding-right: 35px;
}

.timeline-listing-search ul li a {
  padding: 8px 25px;
  border-radius: 6px;
  border: 1px solid #e9eeff;
  color: var(--app-color-2);
  text-decoration: none;
  font-size: var(--text-size);
}

.timeline-listing-search ul li a:hover {
  border: 1px solid var(--app-color-1);
}

.timeline-listing-search ul li a img {
  width: 18px;
  margin-right: 5px;
  position: relative;
}

.dashboard-listing-title-wraper {
}

.dashboard-listing-title-wraper ul {
  list-style: none;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.dashboard-listing-title-wraper ul li {
  flex-grow: 1;
}

.dashboard-listing-title-wraper ul li .listing-tile-dash {
  border: 1px solid #f1f4ff;
  padding: 20px 20px 10px 20px;
  border-radius: 6px;
  height: 100%;
}

.listing-tile-dash img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.heading-tile-dash strong {
  font-size: var(--heading-size);
  color: #333333;
  font-weight: var(--heading-weight);
}

.heading-tile-dash span {
  float: right;
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: var(--app-color-1);
}

.info-title-wrap {
  margin-top: 10px;
}

.timeline-per-grid-options {
  display: flex;
  border-bottom: 1px solid #f1f4ff;
  padding: 10px 0;
  font-size: var(--text-size);
  position: relative;
  font-weight: var(--text-font-weight);
  color: #333333;
  justify-content: space-between;
  align-items: center;
}

.timeline-per-grid-options p {
  margin-bottom: 0;
}

.timeline-per-grid-options p:hover {
  color: var(--app-color-1);
}

.info-title-wrap span a img {
  width: 20px;
  height: auto;
}

.info-title-wrap span:last-child {
  border: none;
  padding-bottom: 0;
}

.info-title-wrap span strong {
  font-weight: var(--text-font-weight);
  float: right;
  color: var(--app-color-2);
}

.active-project-timeline {
  /* border: 1px solid #f1f4ff; */
  margin-top: 25px;
  margin-bottom: 25px;
  /* max-width: 1350px; */
}

.active-projs h4 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: #333;
  padding: 12px 15px;
  margin-bottom: 0;
  border-top: 1px solid #f1f4ff;
  border-left: 1px solid #f1f4ff;
  border-right: 1px solid #f1f4ff;
}

.no-data-active-projects {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  margin-bottom: 0;
  border-top: 1px solid #f1f4ff;
  border-bottom: 1px solid #f1f4ff;
  border-left: 1px solid #f1f4ff;
  border-right: 1px solid #f1f4ff;
}

.active-project-timeline h4 span {
  font-weight: var(--text-font-weight);
}

.active-project-timeline {
}

.active-project-timeline .table {
  border: none;
}

.active-project-timeline .table tr {
  border: 1px solid #f1f4ff;
}

.active-project-timeline .table tr td {
  vertical-align: middle;
}

.project-list-active {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #333;
  display: flex;
  align-items: center;
}

.project-list-active span {
  min-width: 30px;
  min-height: 30px;
  background: #56baec;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 5px;
  font-size: 10px;
}
.progress {
  border-radius: 60px;
  height: 12px;
  background-color: #e9eeff;
  width: 60%;
  margin: 0 auto;
}

.progress-bar-timeline {
  /* background: var(--app-color-1) !important; */
  height: 12px !important;
}

.progress-bar-timeline-color {
  background: var(--icon-color2) !important;
}

.progress-bar-timeline-overdue {
  background: #ff0000 !important;
}

.progress-timeline {
  padding: 0 !important;
  margin-bottom: 0;
  height: 12px !important;
  overflow: hidden;
  background-color: var(--progress-timeline-bg) !important;
  border-radius: 60px;
  width: 100%;
}

.avatar-image-wrap span img {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: -10px;
}

.avatar-image-wrap button {
  width: 30px;
  height: 30px;
  background: var(--app-color-2);
  border-radius: 50%;
  color: #fff;
  border: none;
  outline: none;
  text-align: center;
  margin-left: -13px;
  font-size: 12px;
}

.timeline-progress {
  font-size: var(--text-size);
  color: #2098d1 !important;
  font-weight: var(--text-font-weight);
}

.timeline-progress img {
  width: 20px;
}

.update-date {
  font-size: var(--text-size);
  color: #333;
  font-weight: var(--text-font-weight);
}

.ongoing-task-wraper {
  /* border: 1px solid #f1f4ff; */
  /* max-width: 1500; */
}

.ongoing-task-wraper h3 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  color: #333;
  padding: 12px 8px;
  margin-bottom: 0;
  border-top: 1px solid #f1f4ff;
  border-left: 1px solid #f1f4ff;
  border-right: 1px solid #f1f4ff;
}

.ongoing-task-wraper h3 span {
  font-weight: var(--text-font-weight);
}

.ongoing-task-wraper {
}

.ongoing-task-wraper .table {
  border: none;
}

.ongoing-task-wraper .table tr {
  border: 1px solid #f1f4ff;
}

.ongoing-task-wraper .table tr td {
  vertical-align: middle;
  font-size: var(--text-size);
  color: #333;
  font-weight: var(--text-font-weight);
  padding: 0px 0px 0px 8px;
}

.modules-image img {
  width: 35px;
}

.status-tile-wrap {
  color: #fb3f3f;
}

.status-tile-wrap img {
  width: 30px;
  margin-right: 5px;
}

.timeline-main-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeline-heading-title-img {
  min-height: 50px;
  min-width: 50px;
  margin-right: 10px;
  background-color: #e9eeff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-heading-title-img svg {
  color: var(--app-color-1);
}

.table-timeline {
  min-width: 1000px;
}

.timeline-active-project-grid .ag-header {
  display: none;
}
.timeline-outgoing-project-grid .ag-header {
  display: none;
}

.timeline-dropdown {
  /* top: 0px;
  left: 85px; */
}

.timeline-drop-text {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.create-timeline-grid input[type="checkbox"]:checked ~ label:after {
  top: 43% !important;
}

.timeline-drop-text span {
  font-size: var(--text-size) !important;
  font-weight: var(--text-font-weight) !important;
  color: #333 !important;
}

.timeline-per-grid-options {
  cursor: pointer;
}

.selected-privacy {
  background-color: var(--app-color-1) !important;
  color: #fff !important;
}

.no-timeline-table-text {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.no-timeline-table-text span {
  color: var(--app-color-1);
  cursor: pointer;
}

.active-timeline-header h4 {
  border: 1px solid #f1f4ff;
  border-bottom: none;
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  padding: 12px 8px;
  margin-bottom: 0px !important;
}

.active-timeline-header h3 {
  border: 1px solid #f1f4ff;
  border-bottom: none;
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  padding: 12px 8px;
  margin-bottom: 0px !important;
}

.active-timeline-header h3 span {
  font-weight: var(--text-font-weight);
}

.not-timeline-txt {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.ongoing-task-timeline {
  margin-bottom: 25px;
}

/* .timeline-per-grid-options:hover {
  color: var(--app-color-1);
} */

@media screen and (max-width: 767px) {
  .dashboard-listing-title-wraper ul li {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 700px) {
  .timeline-main-wrap {
    flex-direction: column;
    align-items: start;
  }

  .timeline-listing-search ul {
    margin-top: 15px;
  }

  .timeline-listing-search ul li:first-child {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .timeline-listing-search ul li,
  .timeline-listing-search ul li:not(:first-child) button {
    min-width: 100%;
  }

  .timeline-listing-search ul li:not(:first-child) {
    margin-left: 0;
    margin-top: 15px;
  }

  .timeline-listing-search {
    width: 100%;
  }

  .timeline-listing-search ul {
    display: flex;
    flex-direction: column;
  }
}
