.module-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-image: url("../../../assets/bg.png");
  background-size: cover; /* This ensures the image covers the entire container */
  background-position: center; /* This centers the image within the container */
}
.module-items-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.module-item {
  width: 120px;
  height: 120px;
  border: 1px solid var(--app-color-2);
  padding: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  background-color: #fff;
}

.module-item:hover {
  background-color: var(--app-color-1);
}

.module-item:hover p,
.module-item.selected p {
  color: #fff;
}

.module-item.selected {
  background-color: var(--app-color-1); /* Add your selected background color */
}

.module-item.selected .selector-icon svg {
  color: var(--app-color-2);
}

.selector-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #007bff; /* Add your selector icon color */
}

.module-header-container {
  margin-bottom: 30px;
}

.module-btn-container button {
  border: none;
  padding: 8px 20px;
  border-radius: 6px;
  min-width: 130px;
  font-size: 12px;
  color: #fff;
  background-color: var(--app-color-1);
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.module-btn-container button:hover {
  background-color: var(--app-color-2);
}

.module-err p {
    font-size: 14px;
    color: #e93131;
}
