.project-plan-main-wraper {
  padding: 25px;
}

.timeline-dashboard-wraper {
  padding: 25px;
  padding-bottom: 75px;
  border-radius: 20px 0 0px 0px;
  background: var(--card-color);
  min-height: 100vh;
}

.timeline-dashboard-wraper p {
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.project-timeline {
  margin-bottom: 25px;
}

.plan-project-phase-section-wraper {
  border: 1px solid var(--divider-color);
  border-radius: 6px;
}

.heading-main-plan-wrap h4 {
  font-size: var(--heading-size);
  color: #333;
  width: 22%;
  float: left;
}

.heading-main-plan-wrap h4 span {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.heading-main-plan-wrap .progress {
  width: 30%;
  float: left;
}

.progress {
  border-radius: 60px;
  height: 12px;
  background: #e9eeff;
  width: 60%;
  margin: 0 auto;
  margin-top: 8px;
}

.progress-bar {
  background: #394b84;
}

.date-plan-wrap {
  font-size: var(--text-size);
  color: #333;
  margin-top: 2px;
  text-align: right;
}

.date-plan-wrap img {
  width: 20px;
}

.project-plan-main-wraper p {
  font-size: var(--text-size);
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  padding: 10px 0;
}

.project-plan-table-wrap ul {
  list-style: none;
}

.project-table-wrap {
  border: 1px solid #f1f4ff;
  margin-bottom: 15px;
}

.project-table-wrap h2 {
  font-size: 18px;
  color: #394b84;
  padding: 10px;
  font-weight: var(--heading-weigth);
}

.project-table-wrap h2 img {
  width: 20px;
}

.project-table-wrap h2 button {
  background: none;
  border: none;
  outline: none;
}

.project-table-wrap h2 button img {
  width: 15px;
}

.project-table-wrap span {
  font-size: 13px;
  font-weight: var(--text-font-weight);
}

.project-plan-table-wrap .table {
  border: 1px solid #f1f4ff;
  margin-bottom: 0;
}

.project-table-wrap .table tr {
  border: 1px solid #f1f4ff;
  font-size: 16px;
}

.project-plan-main-wraper .control-group {
  display: block;
  vertical-align: top;
  background: #fff;
  text-align: left;
}

.project-plan-main-wraper .control {
  display: block;
  position: relative;
  padding-left: 38px;
  font-size: var(--text-size);
  font-weight: var(--heading-weigth);
}

.project-plan-main-wraper .control input {
  z-index: -1;
  opacity: 0;
}

.project-plan-main-wraper .control__indicator {
  position: absolute;
  left: 0;
  height: 23px;
  width: 25px;
  background: #fff;
  border-radius: 0;
  border: 1px solid #f1f4ff;
}

.project-plan-main-wraper .control--radio .control__indicator {
  border-radius: 50%;
}

.project-plan-main-wraper .control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #56baec;
}

.project-plan-main-wraper .control input:checked ~ .control__indicator {
  background: #56baec;
}

.project-plan-main-wraper
  .control:hover
  input:not([disabled]):checked
  ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #56baec;
}

.project-plan-main-wraper .control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.project-plan-main-wraper .control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.project-plan-main-wraper .control input:checked ~ .control__indicator:after {
  display: block;
}

.project-plan-main-wraper .control--checkbox .control__indicator:after {
  left: 10px;
  top: 6px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.project-plan-main-wraper
  .control--checkbox
  input:disabled
  ~ .control__indicator:after {
  border-color: #7b7b7b;
}

.project-plan-main-wraper .control--radio .control__indicator:after {
  left: 7px;
  top: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.project-plan-main-wraper
  .control--radio
  input:disabled
  ~ .control__indicator:after {
  background: #7b7b7b;
}

.project-plan-main-wraper .select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.project-plan-main-wraper .select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.project-plan-main-wraper .select select::-ms-expand {
  display: none;
}

.project-plan-main-wraper .select select:hover,
.select select:focus {
  color: #333;
  background: #ccc;
}

.project-plan-main-wraper .select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.project-plan-main-wraper .select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}

.project-plan-main-wraper .select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: #333;
}

.project-plan-main-wraper .select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}

.heading-table-project td {
  font-size: var(--text-size);
  vertical-align: middle;
}

.heading-table-project td img {
  width: 15px;
  margin-left: 3px;
  position: relative;
}

.project-table-wrap tr td {
  font-size: 13px;
  vertical-align: middle;
  border-left: 1px solid #f1f4ff;
}

.project-table-wrap .checkbox input {
  border: 1px solid #f1f4ff;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  width: 17px;
  height: 21px;
}

.team-listing-wrap ul {
  list-style: none;
}

.team-listing-wrap ul li {
  display: inline-block;
}

.team-listing-wrap ul li .team-list-wrap {
  background: #b0e57c;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  padding-top: 4px;
}

.team-listing-wrap ul li:nth-child(2) .team-list-wrap {
  position: relative;
  left: -13px;
  background: #56baec;
}

.team-listing-wrap ul li:nth-child(3) .team-list-wrap {
  position: relative;
  left: -23px;
  background: #b4d8e7;
}

.team-listing-wrap ul li:nth-child(4) .add-team-plus {
  position: relative;
  left: -32px;
}

.team-list-wrap img {
  width: 12px;
}

.add-team-plus {
  background: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  padding-top: 4px;
  border: 1px solid #394b84;
}

.add-team-plus img {
  width: 8px;
}

.team-perorty {
  color: #56baec;
}

.team-perorty img {
  width: 20px;
}

.status-icon-wrap {
  color: #2098d1;
}

.status-icon-wrap img {
  width: 20px;
}

.custom-radios div {
  display: inline-block;
}

.custom-radios input[type="checkbox"] {
  display: none;
}

.custom-radios input[type="checkbox"] + label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: var(--text-size);
}

.custom-radios input[type="checkbox"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
  border: 1px solid #f1f4ff;
}

.custom-radios input[type="checkbox"] + label span img {
  opacity: 0;
  transition: all 0.3s ease;
}

.custom-radios input[type="checkbox"]#color-1 + label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-2 + label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-3 + label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-4 + label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]:checked + label span {
  opacity: 1;
  background: url(../../../assets/arrow.svg) center center no-repeat;
  display: inline-block;
  background-size: 40%;
}

.custom-radios {
  text-align: center;
}

.add-button-project button {
  background: none;
  border: none;
  outline: none;
}

.add-button-project button img {
  width: 18px;
  position: relative;
  top: -1px;
}

.project-main-button-wrap .add-phase-button button {
  background: none;
  border: none;
  outline: none;
  font-size: var(--text-size);
  padding: 12px 0;
}

.project-main-button-wrap .add-phase-button button img {
  width: 20px;
  position: relative;
  top: -1px;
}

.project-phase-right-btn ul {
  list-style: none;
  text-align: right;
}

.project-phase-right-btn ul li {
  display: inline-block;
}

.skip-button-wrap {
  background: none;
  border: none;
  outline: none;
  padding: 12px 30px;
  color: #333;
  font-size: var(--text-size);
}

.create-project-btn {
  background: #394b84;
  border: none;
  outline: none;
  padding: 12px 30px;
  color: #fff;
  font-size: var(--text-size);
  border-radius: 4px;
}

.project-detail-top-heading-wrap {
  margin-bottom: 10px;
}

.project-detail-top-heading-wrap h4 {
  font-size: var(--heading-size);
  color: var(--font-color);
}

.project-detail-top-heading-wrap h4 img {
  width: 25px;
  position: relative;
  top: -2px;
}

.project-detail-top-heading-wrap h4 .dot-button {
  border: 1px solid #f1f4ff;
  padding: 0px 12px;
  border-radius: 6px;
  margin-left: 5px;
}

.project-detail-top-heading-wrap h4 .dot-button img {
  width: 15px;
}

.project-detail-top-heading-wrap h4 .create-btn {
  padding: 10px 17px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: #394b84;
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -3px;
  font-weight: var(--text-font-weight);
}

.listing-top-filter-project ul {
  list-style: none;
  text-align: right;
}

.listing-top-filter-project ul li {
  display: inline-block;
  margin-left: 10px;
}

.listing-top-filter-project ul li .form-group {
  position: relative;
}

.listing-top-filter-project ul li .form-group .form-control {
  height: 40px;
  box-shadow: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
  border-radius: 6px;
  padding-right: 35px;
}

.listing-top-filter-project ul li .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  background: none;
  border: none;
  outline: none;
}

.listing-top-filter-project ul li .form-group button img {
  width: 20px;
}

.listing-top-filter-project ul li a {
  padding: 8px 25px;
  border-radius: 6px;
  border: 1px solid #e9eeff;
  color: #394b87;
  text-decoration: none;
  font-size: var(--text-size);
}

.listing-top-filter-project ul li a:hover {
  border: 1px solid var(--app-color-1);
}

.listing-top-filter-project ul li a img {
  width: 18px;
  margin-right: 5px;
  position: relative;
}

.task-main-info-list ul {
  list-style: none;
  /* margin-bottom: 10px; */
}

.project-main-wrap {
  margin-bottom: 15px;
}

.task-main-info-list ul li {
  display: inline-block;
  /* padding: 0 10px; */
  position: relative;
}

.task-main-info-list ul li:nth-child(3) {
  padding-right: 0;
}

.task-main-info-list ul li a {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #333;
  text-decoration: none;
}

.task-main-info-list ul li a span {
  color: #394b84;
}

.task-main-info-list ul li .team-listing-wrap ul li {
  padding: 0 !important;
}

.task-main-info-list ul li .team-listing-wrap ul li .team-list-wrap {
  padding-top: 0;
}

.task-main-info-list ul li .form-group .form-control {
  height: 35px;
  box-shadow: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
  width: 100px;
  appearance: auto;
}

.task-main-info-list ul li img {
  width: 20px;
  position: relative;
  top: -2px;
}

.task-main-info-list ul li:before {
  /* content: "";
  top: 10px;
  position: absolute !important;
  left: -5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d1daf7; */
}

.task-main-info-list ul li:nth-child(1) {
  padding-left: 0;
}

.task-main-info-list ul li:nth-child(1):before {
  display: none;
}

.mobileview-filter-ul {
  list-style: none;
  margin: 0;
}

.task-main-info-list ul li .team-list-wrap img {
  width: 12px !important;
  top: 0;
}

.team-listing-wrap ul li:before {
  display: none;
}

.project-info-heading-wrap {
  border: 1px solid #f1f4ff;
  padding: 4px 4px 4px 4px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.project-info-heading-wrap h4 {
  font-size: var(--heading-size);
  color: #333;
}

.project-info-heading-wrap h4 span {
  font-weight: var(--text-font-weight);
}

.progress-bar-top {
  width: 100%;
  width: 300px !important;
}

.project-info-heading-wrap ul {
  list-style: none;
}

.project-info-heading-wrap ul li {
  display: inline-block;
  padding-right: 15px;
}

.project-info-heading-wrap ul li button {
  background: none;
  border: none;
  outline: none;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.right-listi-wrap {
  text-align: right;
}

.right-listi-wrap li .form-group .form-control {
  height: 35px;
  box-shadow: none;
  outline: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
}

.right-listi-wrap li button {
  background: none;
  outline: none;
  border: none;
  outline: none;
  color: #394b87;
  font-weight: var(--heading-weigth) !important;
}

.right-listi-wrap li button img {
  width: 20px;
  position: relative;
  top: -1px;
}

.left-listing-wrap {
  margin-top: 4px;
}

.projects-heading_wrap {
  font-size: var(--heading-size);
  font-weight: var(--heading-weigth);
  margin-bottom: 0px !important;
  color: var(--font-color);
}

.projects-heading_wrap a {
  font-size: 13px;
  font-weight: var(--text-font-weight);
  color: #7e7e7e;
  margin-right: 5px;
  text-decoration: none;
}

.project-table-list-wrap .table {
  border: 1px solid #e9eeff;
}

.project-table-list-wrap .table tr {
  border: 1px solid #e9eeff;
  vertical-align: middle;
}

.project-table-heading .project-shortname-heading {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #56baec;
  color: #fff;
  text-align: center;
  padding-top: 11px;
  font-weight: var(--text-font-weight);
  text-transform: uppercase;
  font-size: 13px;
  float: left;
}

.project-heading-info {
  width: 88%;
  float: left;
}

.project-heading-info svg {
  cursor: pointer;
  color: var(--app-color-1);
}

.project-heading-info {
  padding-left: 10px;
}

.project-heading-info h5 {
  font-size: var(--text-size) !important;
  font-weight: var(--text-font-weiight);
  color: var(--font-color);
  max-width: fit-content;
}

.project-cursor {
  cursor: pointer;
}

.project-heading-info .progress {
  width: 100% !important;
  margin-top: 3px;
}

.date-info-project {
  border: 1px solid var(--app-color-2);
  border-radius: 50px;
  font-size: var(--text-size);
  padding: 4px 0;
  text-align: center;
  color: var(--app-color-2);
  margin-right: 25px;
  font-weight: var(--text-font-weiight);
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image-wrap span img {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: -15px;
}

.avatar-image-wrap button {
  width: 30px;
  height: 30px;
  background: #394b84;
  border-radius: 50%;
  color: #fff;
  border: none;
  outline: none;
  text-align: center;
  margin-left: -18px;
  font-size: 12px;
}

.project-date img {
  width: 20px;
}

.timeline-progress {
  font-size: var(--text-size);
  color: #2098d1;
}

.timeline-progress img {
  width: 20px;
}

.project-date {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.update-date {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.timeline-active-project-grid .ag-ltr .ag-cell:not(:last-child),
.project-main-grid .ag-ltr .ag-cell:not(:last-child),
.timeline-active-project-grid .ag-ltr .ag-cell:not(:last-child),
.timeline-outgoing-project-grid .ag-ltr .ag-cell:not(:last-child) {
  /* border-right: none !important; */
}

.proj-detail-ag-table .ag-ltr .ag-cell:nth-child(2) {
  border-right: none !important;
}

.proj-detail-ag-table .ag-ltr .ag-cell:nth-child(3) {
  border-right: none !important;
}

.first-header-cell {
  padding: 0px !important;
  /* padding-right: 0 !important; */
}

.active-project-no-header .ag-header {
  display: none !important;
}

/* .proj-detail-ag-table
  .ag-ltr
  .ag-header-cell:nth-child(3)
  .ag-header-cell-resize::after {
  display: none !important;
} */

.proj-detail-ag-table .ag-ltr .ag-cell:nth-child(1) {
  padding: 0px !important;
}

.project-main-grid .ag-ltr .ag-cell-inline-editing,
.proj-detail-ag-table .ag-ltr .ag-cell-inline-editing,
.timeline-active-project-grid .ag-ltr .ag-cell-inline-editing,
.timeline-outgoing-project-grid .ag-ltr .ag-cell-inline-editing {
  padding: 0 !important;
}

/* .project-main-grid .ag-header {
  display: none !important;
} */

.custom-task-detail-header-radio,
.custom-task-detail-radio {
  position: absolute;
  left: 6px;
}
.offcanvas-body {
  background: var(--card-color);
}
/*------------------Create new project------------------*/
.create-project-new-wrap {
  padding: 25px;
  border-radius: 20px;
  background: var(--card-color);
  color: var(--font-color);
}

.create-project-new-wrap h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color);
}

.create-project-new-wrap h3 span {
  font-weight: var(--text-font-weight);
}

.create-project-new-wrap p {
  font-size: var(--text-size);
  padding-bottom: 20px;
  font-weight: var(--text-font-weight);
}

.create-project-new-wrap .form-group {
  margin-bottom: 25px;
}

.create-project-new-wrap label {
  display: block;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  margin-bottom: 5px;
}

.create-project-new-wrap label img {
  width: 20px;
}

.create-project-new-wrap .form-group .form-control::placeholder {
  color: #cbcbcb;
  /* opacity: 0.5; */
}
.create-project-new-wrap .form-group .form-control {
  height: 40px;
  box-shadow: none;
  border: 1px solid var(--divider-color3);
  background-color: var(--card-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  border-radius: 6px;
  padding-right: 35px;
  color: var(--font-color);
}

.create-project-new-wrap .form-group .form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.create-project-new-wrap .radiotextsty {
  color: var(--font-color);
  font-size: var(--text-size);
  position: relative;
  top: -3px;
}

.create-project-new-wrap .customradio {
  display: inline-block !important;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  margin-right: 15px;
}

/* Hide the browser's default radio button */
.create-project-new-wrap .customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.create-project-new-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  background: #e9eeff;
}

/* When the radio button is checked, add a blue background */
.create-project-new-wrap .customradio input:checked ~ .checkmark {
  background-color: #e9eeff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.create-project-new-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.create-project-new-wrap .customradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.create-project-new-wrap .customradio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--app-color-1);
}

.create-project-new-wrap .form-check-inline {
  margin-top: 8px;
}

.project-icon-wrap ul {
  list-style: none;
  padding-left: 0;
}

.project-icon-wrap ul li {
  display: inline-block;
  margin-right: 2px;
}

.project-icon-wrap ul li button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.project-icon-wrap ul li img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.button-invite-from {
  margin-top: 25px;
}

.button-invite-from button {
  width: 50%;
  background: none;
  border: 1px solid var(--icon-color);
  color: var(--exotic-font-color);
  height: 40px;
  outline: none;
  border-radius: 6px;
}

.button-invite-from button img {
  width: 18px;
}

.textarea-group-wrap .form-control {
  box-shadow: none;
  border: 1px solid var(--divider-color3) !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  border-radius: 6px;
  padding-right: 35px;
  color: var(--font-color) !important;
  background-color: var(--card-color);
}

.textarea-group-wrap .form-control::placeholder {
  color: #cbcbcb;
}

.main-form-button-wrap {
  margin-top: 15px;
  text-align: right;
}

.main-form-button-wrap button {
  color: #fff;
  border-radius: 6px;
  background: var(--icon-color2);
  font-size: var(--text-size);
  border: none;
}

.main-form-button-wrap button:hover {
  background: var(--icon-color);
}

.project-detail-btn {
  background: transparent !important;
  margin-left: 5px;
}

.proj-detail-img {
  width: 50px;
  height: 50px;
}

.off-concave-proj-modal {
  min-width: 1264px;
}

.project-listing-detail-section-list {
  padding-left: 0px;
}

.project-detail-modal-header h2 {
  font-size: var(--heading-size);
  color: var(--exotic-font-color);
  padding: 10px;
  font-weight: var(--heading-weigth);
  margin-bottom: 0;
}

.project-detail-modal-header h2 img {
  width: 20px;
}

.project-detail-modal-header h2 span {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.progress-detail {
  padding: 0 !important;
  margin-bottom: 0;
  height: 12px !important;
  overflow: hidden;
  background-color: var(--progress-timeline-bg) !important;
  border-radius: 60px;
  width: 180px;
  margin-left: 10px;
}

.proj-detail-header-wrap {
  display: flex;
  align-items: center;
}

.proj-detail-header-wrap h4 span {
  font-size: var(--text-size);
}

.proj-detail-header-wrap h4 span span {
  font-weight: var(--text-font-weight);
}

.proj-detail-date-picker .react-datepicker-popper {
  z-index: 2;
}

.project-detail-modal-header button {
  border: none;
  background: transparent;
}

.flip-arrow {
  transform: scaleY(-1);
  /* Flip the arrow vertically */
  transition: transform 0.3s ease;
  /* Apply a smooth transition effect */
}

.flip-not-arrow {
  transform: scaleY(1);
  /* Flip the arrow vertically */
  transition: transform 0.3s ease;
  /* Apply a smooth transition effect */
}

.selected-project-profile-img {
  filter: grayscale(0);
}

.unselected-project-profile-img {
  filter: grayscale(1);
}

.project-detail-offcanvas-date .flatpickr-calendar.static.open {
  right: 0;
}

.ag-popup-editor {
  z-index: 1045 !important;
}

.dropdown-list-task-detail {
  z-index: 1045 !important;
}

.react-tooltip {
  z-index: 2;
}

.project-filter-select {
  flex: 1;
}

.project-filter-select input {
  padding: auto !important;
  height: 25px !important;
  background-color: transparent !important;
}

.project-filter-select input:focus {
  outline: 0;
  border: 1px solid #f1f4ff;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.basic-single.project-filter-select .select__control {
  border-radius: 4px;
  font-size: var(--text-size);
  padding: 0px !important;
  max-height: 35px !important;
  border: 1px solid var(--background-dashboard-color);
  color: var(--font-color) !important;
  min-height: 35px !important;
  /* Adjust height as needed */
  height: auto;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.basic-single.project-filter-select .select__control .select-icon {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #7e7e7e;
}

.basic-single.project-filter-select .select__control:hover {
  border: 1px solid #f1f4ff;
}

.basic-single .project-filter-select .select__control:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  outline: 0 !important;
}

.basic-single .project-filter-select .select__single-value {
  color: #495057;
}

.basic-single .project-filter-select .select__input {
  color: #495057;
  outline: none;
}

.project-filter-select .select__option--is-selected {
  background-color: #deebff !important;
}

.basic-single.project-filter-select .select__menu {
  z-index: 9999;
  background-color: var(--background-dashboard-color);
}

.project-details-select-priority-editor {
  width: 100% !important;
  height: 100% !important;
}

.project-tasks-select-status-editor {
  height: 100% !important;
  width: 100% !important;
}

.project-tasks-select-status-editor {
  flex: 1;
  border-radius: 4px;
}

.project-tasks-select-status-editor input {
  padding: auto !important;
  height: 100% !important;
  background-color: transparent !important;
}

.project-tasks-select-status-editor input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.basic-single.project-tasks-select-status-editor .select__control {
  border: 1px solid var(--background-dashboard-color);
  border-radius: 0px;
  font-size: var(--text-size);
  padding: 0px !important;
  max-height: 54px !important;
  min-height: 54px !important;
  min-width: 150px !important;
  /* Adjust height as needed */
  height: 42px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.basic-single.project-tasks-select-status-editor .select__control:hover {
  border: 1px solid #e9eeff !important;
}

.basic-single
  .project-tasks-select-status-editor
  .select__control:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  outline: 0 !important;
}

.basic-single .project-tasks-select-status-editor .select__single-value {
  color: #495057;
}

.basic-single .project-tasks-select-status-editor .select__input {
  color: #495057;
  outline: none;
}

.project-tasks-select-status-editor .select__option--is-selected {
  background-color: #deebff !important;
  color: #333333 !important;
}

.basic-single.project-tasks-select-status-editor .select__menu {
  z-index: 9999;
}

.project-filter-dropdown-list a {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #333;
  text-decoration: none;
}

/* For WebKit browsers (Chrome, Safari) */
.project-main-info-laptop::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 4px;
}

.project-main-info-laptop::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}

.project-main-info-laptop::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.308); /* Color of the scrollbar thumb */
  height: 3px;
  border-radius: 10px; /* Rounded corners for the thumb */
  z-index: -1 !important;
}

/* Optional: Hover effect for the scrollbar thumb */
.project-main-info-laptop::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.308); /* Darker color on hover */
  height: 3px;

  z-index: -1 !important;
}

/* Default styles for screens greater than 1150px */
.project-main-info-laptop {
  display: flex;
  overflow-x: auto;
}

.project-main-info-tablet,
.project-main-info-mobile {
  display: none;
}

.project-filter-dropdown-list .project-details-main-date-filter {
  width: min-content;
}

.project-listing-no-data-text {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
}

.project-listing-no-data-text span {
  color: var(--icon-color);
  text-decoration: underline;
  cursor: pointer;
}

.project-listing-icon-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.create-project-button {
  min-width: 134px;
  max-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  float: right;
}

.record-project-wrap {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.record-project-wrap .select__control {
  background: var(--background-dashboard-color);
  border-radius: 6px !important;
  margin-left: 5px;
  min-width: 65px;
}

.pined-icon {
  transform: rotate(316deg);
}

.filter-refresh-icon {
  background: var(--search-bar-bg);
  border: 1px solid var(--app-color-2);
  border-radius: 4px;
  padding: 2px 7px;
}

.filter-refresh-icon svg {
  width: 18px;
  height: 18px;
  color: var(--icon-color);
  cursor: pointer;
  transition: transform 0.5s ease;
  /* Add transition for smooth rotation */
}

.filter-refresh-icon:hover {
  background: var(--app-color-2);
}

.filter-refresh-icon:hover svg {
  color: #fff;
  transform: rotate(180deg);
  /* Rotate on hover */
}

.filter-refresh-icon:hover .refresh-text {
  color: #fff;
}

.filter-save-icon {
  background: var(--search-bar-bg);
  color: var(--font-color) !important;
  border: 1px solid var(--app-color-2);
  border-radius: 4px;
  padding: 2px 7px;
}

.filter-save-icon svg {
  width: 18px;
  height: 18px;
  color: var(--icon-color);
  cursor: pointer;
  /* transition: transform 0.5s ease; */
  /* Add transition for smooth rotation */
}

.filter-save-icon:hover,
.saved-filter {
  background: var(--app-color-2);
}

.filter-save-icon:hover svg,
.saved-filter svg {
  color: #fff;
  /* transform: rotate(180deg); */
  /* Rotate on hover */
}

.filter-save-icon:hover .refresh-text,
.saved-filter .refresh-text {
  color: #fff;
}

.here-text-project {
  color: var(--app-color-1);
  text-decoration: none !important;
}

.proj-detail-menu {
  width: 20px;
  /* height: 24.5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.proj-detail-menu svg {
  color: var(--font-color);
  width: 90%;
  height: 90%;
}

.proj-detail-menu:hover {
  background-color: var(--app-color-2);
  color: #fff;
}

.proj-detail-menu:hover svg {
  color: #fff;
}

/* Project page */
.project-header-search-section ul {
  list-style: none;
  text-align: right;
  margin-bottom: 0;
}

.project-header-search-section ul li {
  display: inline-block;
  margin-left: 10px;
}

/* .project-header-search-section ul li:nth-child(1) {
  width: 250px;
} */

.project-header-search-section ul li .form-group {
  position: relative;
}

.project-header-search-section ul li .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  background: none;
  border: none;
  outline: none;
}

.project-header-search-section ul li .form-group button img {
  width: 20px;
}

.project-header-search-section ul li .form-group .form-control {
  border: 1px solid #e9eeff;
  border-radius: 4px;
  box-shadow: none;
  font-size: 12px;
  height: 35px;
  padding-right: 35px;
}

.project-header-search-section ul li a {
  padding: 8px 25px;
  border-radius: 6px;
  border: 1px solid #e9eeff;
  color: var(--app-color-2);
  text-decoration: none;
  font-size: var(--text-size);
}

.project-header-search-section ul li a:hover {
  border: 1px solid var(--app-color-1);
}

.project-header-search-section ul li a img {
  width: 18px;
  margin-right: 5px;
  position: relative;
}

.pagination-section {
  flex-wrap: wrap;
  justify-content: space-between;
}

.records-per-page {
  margin-bottom: 10px;
}

.left-scroll-button,
.right-scroll-button {
  display: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  /* height: 100%; */
  height: 100%;
  /* border-radius: 50%; */
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.right-scroll-button-height-one,
.left-scroll-button-height-one {
  height: 50px !important;
}

.right-scroll-button-height-one i,
.left-scroll-button-height-one i {
  font-size: 30px !important;
}

.left-scroll-button {
  /* border-radius: 0 100px 100px 0; */
  /* Rounded corners on the right */
}

.right-scroll-button {
  /* border-radius: 100px 0 0 100px; */
}

.left-scroll-button i,
.right-scroll-button i {
  width: 80%;
  height: 80%;
  display: flex;
  /* Enable flexbox for better centering */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  font-size: 50px;
}

.left-scroll-button {
  /* left: 40px; */
  z-index: 2;
}

.right-scroll-button {
  right: 0px;
  z-index: 2;
}

/* Show buttons on hover */
.project-timeline:hover .left-scroll-button,
.project-timeline:hover .right-scroll-button {
  display: flex !important;
}

/* Show buttons on hover */
.task-timeline:hover .left-scroll-button,
.task-timeline:hover .right-scroll-button {
  display: flex !important;
}

.add-team-project span {
  font-size: var(--text-size);
  cursor: pointer;
  color: var(--app-color-1);
}

/* Other project section */
.other-projects-main h4 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weigth);
}

.other-projects-main h4 span {
  font-weight: var(--text-font-weight);
}

.selected-projects-wrap p {
  font-size: var(--text-font-size);
  font-weight: var(--text-font-weight);
  margin-bottom: 0px !important;
}

.selected-projects-wrap div {
  color: #e93131;
  font-size: var(--text-font-size);
  font-weight: var(--text-font-weight);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selected-projects-wrap span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d1daf7;
}

.control-indicator-left {
  left: -5px !important;
}

.project-list-flatpickr-wrap .flatpickr-wrapper {
  width: 348px;
  height: 54px;
}

.project-list-flatpickr-wrap .flatpickr-wrapper .form-control {
  border: none;
  height: 100%;
}

.control-indicator-right {
  left: -9.5px !important;
}

.project-name-progress-section {
  width: inherit;
  max-width: 365px;
}

.filter-dropdown-selected-project-value .select__value-container--has-value {
  /* padding-left: 0; */
}

.filter-dropdown-selected-project-value .select__control {
  background: rgba(148, 171, 255, 0.24) !important;
}

.project-list-filter-container {
  flex-shrink: 0;
}

.select-option-list {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.custom-user-selection-main-container .custom-user-search-icon {
  width: 20px;
}

.custom-user-type-text-anchor {
  cursor: pointer;
}

/* Adjustments for screens less than or equal to 620px */
@media (max-width: 767px) {
  .projects-heading_wrap,
  .project-filter-action-container,
  .selected-tasks-wrap,
  .projects-detail-heading-wrap,
  .project-info-heading-wrap,
  .project-listing-no-data-text {
    margin-left: 0px !important;
  }

  .create-project-new-wrap {
    padding: 25px 12px;
  }
}

@media (max-width: 620px) {
  .project-main-info-mobile .menu-btn {
    margin-left: 0px;
  }
}

@media (max-width: 1304px) {
  .off-concave-proj-modal {
    min-width: 100vw;
  }
}

@media (max-width: 570px) {
  .proj-detail-skip-section-wrap {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .proj-off-detail-header-wrap {
    flex-direction: column;
  }

  .proj-off-detail-header-wrap .progress-detail {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .project-offcanvas-main-wraper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.modal-backdrop {
  z-index: 1055 !important;
}
