.schedule-auth-wrapper {
  padding: 0px 110px 0;
}

.timezone-auth-form-container {
  /* border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0px 4px 19.2px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 90px;
  padding: 35px; */
}

.set-hour-container {
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0px 4px 19.2px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 90px;
  padding: 35px;
}

.timezone-select-auth .select__control {
  border: 1px solid #e9eeff;
  border-radius: 4px;
  font-size: var(--text-size);
  padding: 0px !important;
  max-height: 40px !important;
  min-height: 40px !important;
  height: auto;
  transition: border-color 0.3s, box-shadow 0.3s;
}

@media (max-width: 1630px) {
  .schedule-auth-wrapper {
    padding: 0px 55px 0;
  }
}

@media (max-width: 1410px) {
  .schedule-auth-wrapper {
    padding: 0px 27px 0;
  }
}

@media (max-width: 600px) {
  .schedule-auth-wrapper {
    padding: 25px 0px 0px 0px;
  }
}
