.modules-new-wraper {
  padding: 25px;
  border-radius: 20px 0 0px 0px;
  background: var(--card-color);
  min-height: 100vh;
}
.modules-new-wraper h2 {
  font-size: 20px;
  color: var(--font-color);
  clear: both;
  margin-bottom: 0;
}

.modules-new-wraper h2 span {
  font-weight: var(--text-font-weight);
}

.modules-new-wraper h2 strong {
  font-weight: 500;
}

.modules-new-wraper h2 a {
  color: #fff;
  border: 1px solid #94abff;
  padding: 8px 25px;
  border-radius: 6px;
  float: right;
  font-size: 13px;
  color: #394b87;
}
.modules-new-wraper ul {
  list-style: none;
  margin-top: 30px;
}
.modules-new-wraper ul li {
}
.modules-new-wraper ul li .listing-modules-wrap {
}
.listing-modules-wrap a {
  display: block;
  border: 1px solid var(--divider-color);
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 10px;
  position: relative;
}
.listing-modules-wrap a:hover {
  background: var(--app-color-1);
}

.listing-modules-wrap a:hover h5,
.listing-modules-wrap a:hover p {
  color: #fff;
}

.listing-modules-icon {
  width: 50px;
  display: inline-block;
  float: left;
}
.listing-text-wraper {
  width: 70%;
  padding-left: 10px;
  float: left;
  position: relative;
  top: 6px;
  line-height: 16px;
}
.listing-text-wraper h5 {
  color: var(--font-color);
  font-size: var(--text-size);
}
.listing-text-wraper span {
  color: #000;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.listing-text-wraper p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.module-row-wrap li,
.listing-modules-wrap {
  min-height: 100%;
}

.listing-modules-wrap a {
  min-height: 115.8px;
}

.crefix-btn {
  position: absolute;
  font-size: 12px;
  border: 1px solid var(--icon-color2);
  color: #fff;
  background-color: var(--icon-color2);
  border-radius: 4px;
  right: 15px;
  top: 15px;
  min-width: 70px;
}

.crefix-btn:hover {
  background-color: #fff;
  color: var(--icon-color2);
  border: #fff;
}

.install-modal-img {
  min-width: 83px;
  min-height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e9eeff;
  margin-bottom: 10px;
}

.install-modal-img svg {
  color: var(--app-color-1);
  width: 30px;
  height: 30px;
}

.module-row-wrap .shimmer-avatar {
  width: 50px !important;
  height: 50px !important;
}

.module-row-wrap .shimmer-card {
  height: 115px;
}

.module-row-wrap .shimmer-button--sm {
  display: none;
}

@media (max-width: 1819px) {
  .listing-modules-wrap a {
    height: 136.8px;
  }
}
/* @media (max-width: 1608px) {
  .listing-modules-wrap a {
    height: 152.8px;
  }
} */
@media (max-width: 1625px) {
  .module-row-wrap li {
    width: 33.3%;
  }

  .listing-modules-wrap a {
    height: 116.8px;
  }
  /* .listing-modules-wrap a {
    min-height: 168.8px;
  } */
}
@media (max-width: 1430px) {
  .listing-modules-wrap a {
    height: 126.8px !important;
  }
}
@media (max-width: 1300px) {
  .module-row-wrap li {
    width: 50%;
  }
}
@media (max-width: 1282px) {
  .listing-modules-wrap a {
    height: 184.8px;
  }
}
@media (max-width: 1149px) {
  .listing-modules-wrap a {
    height: 210.8px;
  }
}
@media (max-width: 510px) {
  .module-section-header-wrap {
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
  }
}
@media (max-width: 767px) {
  .listing-modules-wrap a {
    height: 100%;
  }

  .module-row-wrap li {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .crefix-btn {
    top: 117px;
  }

  .listing-modules-wrap a {
    height: 162.8px !important;
  }
}
@media (max-width: 374px) {
  .crefix-btn {
    top: 127px;
  }

  .listing-modules-wrap a {
    height: 172.8px !important;
  }
}
@media (max-width: 354px) {
  .crefix-btn {
    top: 137px;
  }

  .listing-modules-wrap a {
    height: 182.8px !important;
  }
}
