.ui-state-default,
.ui-widget-content .ui-state-default {
  text-align: center !important;
  border: none !important;
  border-radius: 4px !important;
  background-color: #94abff !important;
  color: #fff;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background-color: #384981 !important;
}

.file-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-wrapper-ag {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-item {
  cursor: pointer;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  transition: width ease-in-out 0.2s, height ease-in-out 0.2s; /* Use transition instead of transform */
}

.file-item:hover {
  width: 22px;
  height: 22px;
}

.file-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remaining-files {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #94abff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
}

.add-file {
  visibility: hidden;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #94abff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
}
.remove-file {
  visibility: hidden;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #94abff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
}

.project-detail-btn {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #94abff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
  border: none;
}

.file-list:hover .add-file,
.file-list:hover .remove-file {
  visibility: visible;
}

.ag-theme-alpine {
  --ag-alpine-active-color: #94abff !important;
  overflow: visible !important;
}

.timeline-wrapper {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  color: #fff;
  font-weight: var(--text-font-weight);
}
.agg-due-date {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  color: #fff;
  background-color: #94abff;
}

.add-files-btn-wrapper:hover .add-files-btn {
  visibility: visible;
}

.add-files-btn {
  visibility: hidden;
  cursor: pointer;
  color: #94abff;
}

.spinner-file {
  color: #94abff;
}

.add-label {
  cursor: pointer;
}

.overline-due-date {
  text-decoration: line-through;
}

.leading-grid-margin {
  margin-top: 30px;
}

.add-more-grp-btn {
  border: 1px solid #e9eeff;
  padding: 12px 24px;
  color: #333;
  font-weight: bold;
  font-size: var(--text-size);
  border-radius: 6px;
  text-decoration: none;
  background-color: #fff;
  margin-top: 30px;
}

.add-more-grp-btn:hover {
  background-color: #94abff;
  color: #fff;
}

.example-wrapper {
  display: flex;
  height: 100%;
}

.inner-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
}

.inner-col.vertical-toolbar {
  display: flex;
  flex: none;
  width: 100px;
  align-items: center;
  justify-content: center;
}

.toolbar {
  white-space: nowrap;
}

.vertical-toolbar > span {
  padding: 10px;
  margin: 10px;
  cursor: default;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-modify: none;
}

button.factory {
  height: 25px;
  border-radius: 5px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  margin-right: 2px;
}

button i {
  margin-right: 10px;
}

.bin i {
  transform: scale(1);
  transition: transform 500ms;
}

.factory-red {
  background-color: #cc333344;
}
.factory-green {
  background-color: #33cc3344;
}
.factory-blue {
  background-color: #2244cc44;
}

.red-row {
  background-color: #cc333344 !important;
}
.green-row {
  background-color: #33cc3344 !important;
}
.blue-row {
  background-color: #2244cc44 !important;
}

.scrollbar-track {
  position: relative;
}

.scrollbar-thumb {
  transition: left 0.2s ease-in-out;
}