.lightbox-wrap div:first-child {
  z-index: 1;
  background: rgba(0, 0, 0, 0.88) !important;
}

.lightbox-wrap div:first-child > div {
  display: none;
}

.lightbox-wrap > img {
  max-height: 86% !important;
}

.pdf-wrap body > pre {
  background: #fff !important;
}

.download-lightbox-icon {
  z-index: 1;
  top: 25px;
  /* bottom: 0px; */
  /* left: 0px; */
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* height: 6%; */
  /* width: 100%; */
  background-color: var(--app-color-1);
  /* position: absolute; */
  right: 70px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.download-lightbox-icon:hover {
  background-color: var(--app-color-2);
}

.download-lightbox-icon svg {
  color: #fff;
  font-size: 20px;
}

.download-lightbox-icon span {
  color: #fff;
}

.iframe-view-icon {
  background-color: var(--app-color-1);
  right: 20px;
  top: 25px;
}

.iframe-google-view-icon {
  background-color: var(--app-color-1);
  right: 60px;
  top: 12px;
}

.iframe-view-icon-pdf {
  background-color: transparent;
  right: 123px;
  top: 12px;
  width: 32px;
  height: 32px;
}

.iframe-google-view-icon-download {
  top: 12px;
  right: 105px;
}

.iframe-view-icon-pdf:hover {
  background-color: #4b4a4a;
}

.iframe-view-icon svg {
  color: #fff;
}

.lightbox-wrap {
  position: relative;
}

/* PDF Viewer*/
.pdf-viewer-wrap {
  z-index: 1;
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0.8);
}

.pdf-wrap {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.pdf-wrap iframe {
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  background: #fff;
}

.pdf-wrap svg {
  display: none;
  /* position: absolute;
  top: 17px;
  right: 127px;
  color: #fff;
  width: 22px;
  height: 22px;
  cursor: pointer; */
}
