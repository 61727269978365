.user-profile-main-wraper {
  background: var(--card-color);
  border-radius: 20px 0 0px 0px;
  min-height: 100vh;
}

.overview-profile-image svg {
  color: var(--icon-color);
}

.user-profile-main-wraper h3 {
  font-weight: var(--heading-weigth);
  color: var(--font-color);
  font-size: var(--heading-size);
  padding: 25px;
  margin-bottom: 0px;

  border-bottom: 1px solid var(--divider-color);
}

.user-profile-main-wraper h3 span {
  font-weight: var(--text-font-weight);
}

.profile-info-wraper {
  /* background: var(--app-color-1); */
  padding: 35px;
  padding-bottom: 15px;
  height: auto;
  margin-top: 25px;
}

.profile-dash-image {
  object-fit: cover;
  width: 100%;
  /* Adjust the width as needed */
  border-radius: 15px;
}

.profile-edit-btn {
  position: relative;
}

.profile-edit-btn a {
  position: absolute;
  margin-top: -60px;
  margin-right: 20px;
  background-color: var(--icon-color);
  top: 0px;
  right: 0px;
  z-index: 4;
  border-radius: 50%;
  box-shadow: inset;
  cursor: pointer;
}

.profile-edit-btn a img {
  width: 18px;
  height: 18px;
  margin: 8px;
}

.profil-image-name-wrap {
  border: 2px solid var(--divider-color);
  background: var(--card-color);
  border-radius: 15px;
  padding: 0px 25px 0 25px;
  margin-top: 45px;
}

.profil-image-name-wrap .profile-dash-image {
  height: 240px;
  border: 10px solid var(--card-color);
  background-size: cover !important;
  border-radius: 15px;
  width: 240px;
  margin: 0 auto;
  margin-top: -80px;
  margin-bottom: 10px;
  box-shadow: 0px -2px 10.2px 0px rgba(174, 174, 174, 0.25);
}

.profil-image-name-wrap span {
}

.user-profile-role-span {
  background: #e9eeff;
  padding: 8px 20px;
  font-size: 12px;
  border-radius: 50px;
  color: var(--app-color-1);
  max-height: 30px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.profil-image-name-wrap h4 {
  font-size: var(--heading-size);
  color: var(--font-color);
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: var(--heading-weigth);
}

.profil-image-name-wrap p {
  font-size: var(--text-size);
  color: #a6abbd;
}

.profile-dash-text {
  position: relative;
  /* margin-left: -27px; */
  margin-top: 22px;
}

.profil-image-name-wrap button {
  /* position: absolute; */
  right: 20px;
  top: 60px;
}

.edit-profile-btn svg {
  color: var(--app-color-1);
}

.edit-profile-btn:hover svg {
  color: #fff;
}

.edit-profile-btn img {
  margin-right: 10px;
}

.edit-profile-btn:hover {
  background-color: var(--app-color-1);
  color: #fff;
}

.prof-main-wrap {
  position: relative;
}

.profile-nav-wrap {
  border-top: 1px solid var(--divider-color);
  margin: 0 -25px;
}

.profile-nav-wrap ul {
  list-style: none;
  margin-bottom: 0px;
}

.profile-nav-wrap ul li {
  display: inline-block;
}

.profile-nav-wrap ul li a {
  display: block;
  padding: 16px 35px;
  color: var(--font-color);
  font-size: var(--text-size);
  text-decoration: none;
}

.profile-nav-wrap ul li a:hover {
  background: var(--icon-color);
  color: #fff !important;
}

.profile-nav-wrap ul li:first-child a:hover {
  border-radius: 0px 0px 0px 15px;
}

.listing-info-profile-wrap {
  padding: 35px;
  padding-top: 0;
}

.listing-info-profile-wrap h4 {
  font-size: var(--heading-size);
  color: var(--font-color);
  margin-bottom: 15px;
  font-weight: var(--heading-weigth);
  margin-bottom: 0;
}

.listing-info-profile-wrap ul {
  list-style: none;
}

.listing-info-profile-wrap ul li {
}

.listing-info-profile-wrap ul li a {
  display: flex;
  border: 1px solid var(--divider-color);
  padding: 10px;
  border-radius: 6px;
  color: var(--font-color);
  text-decoration: none;
  margin-bottom: 15px;
  flex-wrap: nowrap;
  align-items: center;
}

.listing-info-profile-wrap ul li a input {
  flex-grow: 1;
  /* Take up the remaining available space */
  /* margin-left: 10px; */
  /* Adjust the margin for better spacing */
}

.overview-profile-image {
  min-width: 40px;
  height: 40px;
  background: #f1f4ff;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-profile-image img {
  width: 25px;
  height: 25px;
}

.overview-profile-image-permissions {
  min-width: 40px;
  height: 40px;
  background: #f1f4ff;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  /* padding-top: 8px; */
  float: left;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-profile-image-permissions div {
  width: 30px;
  height: 30px;
  background-color: #b0e57c;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.overview-a-permissions {
  justify-content: space-between;
}

.number-overview-wrap,
.date-overview-wrap {
  float: left;
  /* margin-top: 6px; */
  display: flex !important;
  align-items: center;
  justify-content: start;
  flex-shrink: 0;
  font-size: var(--text-size);
  /* overflow: hidden; */
  width: max-content;
}

.number-overview-wrap {
  font-weight: var(--text-font-weight);
}

.history-date {
  margin-bottom: 0px;
}

.date-overview-wrap {
  color: var(--font-color);
}

.date-overview-wrap strong {
  font-weight: var(--heading-weigth);
}

/* Responsive tabs */
.RRT__panel {
  display: none;
}

.profile-nav-wrap {
  overflow: hidden;
  position: relative;
}

.nav-wrap-inner {
  overflow-x: auto;
  white-space: nowrap;
}

.nav-wrap-inner li a {
  cursor: pointer;
}

.scroll-arrow {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background-color: #fff;
  cursor: pointer;
}

.scroll-arrow.show {
  display: block;
}

.selected-profile-tab,
.selected-profile-tab-one {
  background-color: var(--icon-color);
  color: #fff !important;
}

.selected-profile-tab-one {
  border-radius: 0px 0px 0px 15px;
}

.overview-password {
  width: 50%;
}

.overview-language {
  width: 66%;
}

.number-overview-wrap p {
  color: var(--font-color);
  margin-bottom: 2px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.overview-margin {
  margin-top: 10px;
}

.overview-password-btn button {
  background-color: var(--icon-color2);
  color: #fff;
  border: 1px solid var(--icon-color2);
  width: 120px;
  height: 45px;
  border-radius: 6px;
  font-size: var(--text-size);
}

.profile-info-row-main {
  max-width: 1020px;
}

.number-overview-wrap input,
.number-overview-wrap .profile-lang-selector {
  height: 35px;
  border: 1px solid var(--divider-color);
  background-color: var(--search-bar-bg);
  color: var(--font-color);
  box-shadow: none;
  font-size: 12px;
  padding-right: 35px;
  border-radius: 4px;
  color: var(--font-color);
  padding-left: 10px;
}

.profile-lang-selector {
  flex-grow: 1;
}

.number-overview-wrap input:hover,
.number-overview-wrap .profile-lang-selector:hover {
  border: 1px solid var(--divider-color) !important;
  background-color: var(--search-bar-bg) !important;
 
}

.number-overview-wrap input:focus,
.number-overview-wrap .profile-lang-selector:focus {
  border: 1px solid var(--divider-color);
  background-color: var(--search-bar-bg);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.number-overview-wrap strong {
  font-size: var(--text-size);
  font-weight: var(--heading-weigth);
  color: var(--font-color);
  margin-right: 10px;
}

.profile-info-num-wrap {
  width: 88% !important;
  /* flex-grow: 1 !important; */
}

.profile-info-num-wrap strong {
  margin-right: 5px;
  width: max-content !important;
  white-space: nowrap;
  /* flex-grow: 1 !important; */
}

.profile-info-num-wrap input {
  width: 100% !important;
  flex-grow: 1 !important;
}

.profile-info-num-wrap p {
  width: 80% !important;
  flex-grow: 1 !important;
  white-space: nowrap;
}

.profile-info-num-wrap .react-datepicker-wrapper {
  width: 100% !important;
  flex-grow: 1 !important;
}

.profile-single-select {
  flex: 1;
}

.profile-single-select input {
  padding: auto !important;
  height: 25px !important;
  background-color: transparent !important;
}

.profile-single-select input:focus {
  outline: 0;
  box-shadow: none !important;
}

.basic-single.profile-single-select .select__control {
  border: 1px solid var(--divider-color);
  background-color: var(--search-bar-bg);
  border-radius: 4px;
  font-size: var(--text-size);
  padding: 0px !important;
  max-height: 35px !important;
  min-height: 35px !important; /* Adjust height as needed */
  height: auto;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.basic-single.profile-single-select .select__control:hover {
  border: 1px solid #e9eeff !important;
}

.basic-single .profile-single-select .select__control:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  border: none !important;
  outline: none !important;
}

.basic-single.profile-single-select .select__single-value {
  color: #495057;
}

.basic-single.profile-single-select .select__input {
  color: #495057;
  outline: none;
}

.profile-single-select .select__option--is-selected {
  background-color: #deebff !important;
  color: #333333 !important;
}

/* Set background color for hover effect */
.select__option--is-selected:hover {
  background-color: #f0f0f0; /* Adjust background color as needed */
}

.basic-single.profile-single-select .select__menu {
  z-index: 9999;
}

.listing-info-profile-wrap ul {
  margin-top: 15px;
}

.number-overview-wrap input:hover,
.number-overview-wrap .profile-lang-selector:hover {
  border: 1px solid #e9eeff;
  background-color: white;
  -webkit-appearance: auto;
  /* Hide default arrow on Chrome/Safari */
  -moz-appearance: auto;
  /* Hide default arrow on Firefox */
  appearance: auto;
  /* Hide default arrow on other browsers */
}

.profile-lang-selector {
  min-width: 150px;
  background-color: white;
}

.history-date {
  margin-left: 20px;
  color: var(--exotic-font-color);
}

.number-overview-wrap input:disabled {
  background-color: transparent;
  font-size: var(--text-size);
  font-weight: var(text-font-weight);
}

.number-overview-wrap select {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px !important;
}

.number-overview-wrap input:focus,
.number-overview-wrap select:focus {
  outline: none;
  border: 1px solid #cccccc;
}

.history-p-one {
  margin-bottom: 10px;
}

.history-a {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.history-p {
  display: contents;
}

.team-listing-profile {
  margin-left: 10px;
}

.team-listing-profile ul li {
  padding-top: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.team-listing-profile ul li:not(:first-child) {
  margin-left: -8px !important;
}

.overview-password-btn button:hover {
  background-color: var(--icon-color);
  border: 1px solid var(--icon-color2);
}

.react-datepicker__day--selected {
  background-color: var(--app-color-1) !important;
}

.profile-roles-span-wrap {
  flex-wrap: wrap;
}

.profile-info-email-address {
  /* min-width: 500px; */
}

.crop-btn {
  background-color: var(--icon-color2);
  border: 1px solid var(--icon-color2);
  color: #fff;
  height: 42px;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  font-size: var(--text-size);
}

.crop-btn:hover {
  background-color: var(--icon-color);
  border: 1px solid var(--icon-color);
}

.verify-email-submit-btn {
  background-color: var(--app-color-2);
  border: 1px solid var(--app-color-2);
  color: #fff;
  height: 42px;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
}

.verify-email-submit-btn:hover {
  background-color: var(--app-color-1);
  border: 1px solid var(--app-color-1);
}

.cancel-email-btn {
  background-color: var(--app-color-1);
  border: 1px solid var(--app-color-1);
  color: #fff;
  height: 42px;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
}

.cancel-email-btn:hover {
  background-color: var(--app-color-2);
  border: 1px solid var(--app-color-2);
}

.email-verification-modal h5 {
  color: var(--font-color);
  font-weight: var(--heading-weigth);
  font-size: var(--heading-size);
}

.email-verification-modal p {
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.user-personal-email {
  margin-right: 10px !important;
}

.user-permission-wrap {
  margin-bottom: 15px;
}

.password-row-info-main {
  max-width: 900px;
}

.pass-info-num-wrap {
  width: 86%;
}

.lang-info-num-wrap {
  width: 90%;
}

.lang-strong {
  min-width: 118px;
}

.lang-info-profile-wrap ul li a {
  padding: 10px 28px 10px 10px !important;
}

.last-module-count {
  background: var(--app-color-2) !important;
}

.user-profile-dual-btn-container {
  gap: 1rem;
}

.profile-main-mod-btn-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile-shimmer-thumbnail {
  border-radius: 15px;
  height: 240px;
  margin: -80px auto 10px;
  width: 240px;
}

.profile-shimmer-thumbnail .shimmer-thumbnail {
  border-radius: 15px;
  height: 240px !important;
  width: 240px;
}

.nav-wrap-inner .shimmer-button {
  margin-bottom: 0px !important;
}

.email-notify-text-container h4 {
  color: var(--exotic-font-color);
  font-size: 18px;
}

.custom-switch .react-switch-bg {
  background-color: #e9eeff !important;
  transition: background-color 0.3s ease;
}

.custom-switch .react-switch-handle {
  background: var(--icon-color) !important;
}

@media (max-width: 1300px) {
  .profile-nav-wrap ul li a {
    padding: 16px 25px;
  }
}

@media (max-width: 1000px) {
  .overview-password {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .profile-dash-text {
    text-align: center;
    margin-top: 0;
  }

  .profile-roles-span-wrap {
    justify-content: center;
  }

  .profil-image-name-wrap button {
    position: static;
    margin-bottom: 15px !important;
  }

  .profile-dash-text {
    margin-bottom: 22px;
    margin-left: 0px;
  }

  .profil-image-name-wrap .profile-dash-image {
    width: 100%;
    margin-left: 0px;
  }

  .profile-role-wrapper {
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap !important;
  }

  .team-listing-profile {
    margin-left: 0;
    margin-top: 15px;
  }

  .profile-roles-span-wrap span {
    margin-bottom: 2px;
  }

  .user-profile-modules-categories-expand {
    margin-bottom: 0 !important;
  }

  .profile-main-mod-btn-wrap {
    flex-direction: column;
  }

  .nav-item-shimmer-wrap div:not(:first-child) {
    width: 100% !important;
  }
}

@media (max-width: 550px) {
  .overview-a-permissions {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .lang-info-num-wrap ul li {
    min-width: 90%;
  }

  .overview-language {
    width: 80%;
  }
}

@media (max-width: 650px) {
  .overview-language {
    width: 100%;
  }
}

@media (max-width: 410px) {
  .history-date-wrapper {
    flex-direction: column;
  }

  .permission-header-h5 {
    margin-bottom: 15px;
  }

  .history-date {
    margin: 0;
  }

  .user-permission-wrap {
    flex-direction: column !important;
    justify-content: center !important;
  }
}

@media (max-width: 1335px) {
  .profile-info-num-wrap,
  .pass-info-num-wrap {
    width: 80% !important;
    flex-grow: 1 !important;
  }
}

@media (max-width: 650px) {
  .lang-info-num-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .profile-lang-selector {
    min-width: 100%;
    max-width: 100%;
    flex-grow: 1;
  }

  .lang-info-num-wrap {
    flex-grow: 1;
    width: 0 !important;
  }
}

@media (max-width: 500px) {
  .profile-info-num-wrap,
  .pass-info-num-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .password-input-fields {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .profile-info-wraper,
  .listing-info-profile-wrap {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media (max-width: 506px) {
  .user-profile-dual-btn-container {
    gap: 0;
  }
}

@media (max-width: 410px) {
  .history-date {
    margin-top: 10px;
  }
}
