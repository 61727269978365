.roles-dash-main-wrap {
  min-height: 100vh;
}

.permission-heading-role-wrap {
}
.permissions-cell {
  min-width: 302px;
}
.permission-cell-one {
  min-width: 98px;
}
.permission-cell-two .role-name-edit {
  width: 30px;
  height: 30px;
  visibility: hidden;
  cursor: pointer;
}
.permission-cell-two:hover .role-name-edit {
  visibility: visible;
}
.permission-cell-two {
  min-width: 172px;
}
.role-heading-list-wrap ul {
  list-style: none;
}
.role-heading-list-wrap ul li {
  display: inline-block;
}
.role-heading-list-wrap ul li:nth-child(2) {
  width: 250px;
}
.role-heading-wrap {
  font-weight: var(--heading-weigth);
  color: var(--font-color);
  font-size: var(--heading-size);
  position: relative;
  top: 4px;
}
.role-heading-wrap span {
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}
.search-group-wraper {
  position: relative;
}
.new-role-btn button {
  background: transparent;
  padding: 9px 30px;
  border: 1px solid var(--app-color-2);
  color: var(--app-color-2);
  font-size: 12px;
  outline: none;
  border-radius: 4px;
  font-weight: bold;
}

.new-role-btn button:hover {
  background: var(--app-color-1);
  color: #fff;
}

.btn-leads-cta-wrap svg {
  color: var(--app-color-1);
}

.btn-leads-cta-wrap a:hover {
  background-color: var(--app-color-1) !important;
  border: 1px solid var(--app-color-1);
}

.btn-leads-cta-wrap a:hover svg {
  color: #fff;
}

.update-role-btn {
  text-align: right;
}
.update-role-btn button {
  width: 100%;
  background: var(--app-color-1);
  border: none;
  outline: none;
  padding: 9px 30px;
  color: #fff;
  border-radius: 4px;
}
.table-role-permission-wraper {
  margin-top: 15px;
}
.table-role-permission-wraper .table {
  border: 1px solid #f1f4ff;
}
.table-role-permission-wraper .table tr td {
  border: 1px solid #f1f4ff;
  vertical-align: middle;
  font-size: 12px;
  vertical-align: top;
  padding: 8px;
}
.heading-role-permission-table {
  font-weight: bold;
  color: #3a4a83;
  font-size: 16px;
}
.action-btn {
  background: none;
  border: none;
  outline: none;
}
.action-btn img {
  width: 35px;
}
.arrow-btn-role {
  border: none;
  outline: none;
  background: none;
  position: relative;
  top: -1px;
  /* margin-top: 6px; */
}
.arrow-btn-role img {
  width: 15px;
}
.roles-list-wrap {
  background: #e9eeff !important;
  padding: 10px !important;
  margin-bottom: 5px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 45px;
}
.btn-action-role {
  display: inline-block;
  text-align: right;
  float: right;
}
.roles-list-wrap a {
  margin-left: 3px;
  position: relative;
  /* top: -4px; */
  text-decoration: none;
  /* right: -2px; */
}
/* .roles-list-wrap a:hover {
  opacity: 0.6;
} */

.active-check-role:hover {
  opacity: 1 !important;
}
.roles-list-wrap a img {
  width: 30px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}

.roles-list-wrap a:hover img {
  transform: scale(1.3); /* Adjust the desired width on hover */
}
/* .active-check-role {
  opacity: 0.2;
} */
.heading-role-permission-table td {
  padding: 8px;
}

.arrow-btn-role img {
  transition: transform 0.5s ease;
}

.flip-arrow img {
  transform: rotate(90deg); /* Flip vertically for a down arrow */
}

.form-modal-wrap {
  padding: 0 !important;
}

.role-modal h4 {
  color: var(--font-color);
}

.role-modal h4 span {
  font-weight: var(--text-font-weight) !important;
}

.date-span {
  padding: 5px !important;
  background-color: var(--app-color-1) !important;
  color: #fff !important;
  border-radius: 6px !important;
  margin-left: 0.5rem;
}

.roles-drop-role {
  margin-bottom: 3px !important;
}

@keyframes blink-one {
  0%,
  100% {
    background-color: transparent; /* Initial and final state */
  }
  50% {
    background-color: var(--app-color-1); /* Blink state */
  }
}
.table-roles-new tr:last-child {
  animation: blink-one 2s;
}

@media (max-width: 474px) {
  .new-role-btn button {
    margin-top: 15px;
  }
}
@media (max-width: 522px) {
  .role-heading-wrap {
    margin-bottom: 15px;
  }
}

/* Subcription wrap*/
.subscription-main-new-wraper {
  height: 100vh;
  background: #fff;
  padding: 25px;
  border-radius: 20px 0 0px 0;
}

.off-concave-role {
  min-width: 960px;
}
.off-concave-chat {
  min-width: 85%;
}
.off-concave-chat .offcanvas-body {
  padding: 0px;
}

.subrcip-top-heading-field-wrap {
  margin-bottom: 15px;
}

.heading-permission td {
  font-weight: var(--heading-weigth) !important;
}

.heading-permission-wrap {
  color: var(--font-color);
  font-weight: var(--heading-weigth);
  font-size: var(--heading-size);
}
.heading-permission-wrap span {
  font-weight: var(--text-font-weight);
}
.subrcip-top-heading-field-wrap {
}
.subrcip-top-heading-field-wrap .form-group {
}

.subrcip-top-heading-field-wrap .form-role {
  border: 1px solid var(--font-color);
  padding: 0 6px;
  border-radius: 0px;
  min-width: 150px;
  max-width: 150px;
  height: 36px;
}

.subrcip-top-heading-field-wrap .form-group .form-control {
  height: 34px;
  border: none;
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  box-shadow: none;
  appearance: auto !important;
  padding: 0 !important;
  font-size: var(--text-size);
}
.table-main-wraper-permission .table-responsive {
}
.table-main-wraper-permission .table-responsive .table {
  border: 1px solid #f1f4ff;
}
.table-main-wraper-permission .table-responsive .table td {
  font-size: var(--text-size);
  padding: 15px;
  color: var(--font-color);
  font-weight: var(--text-font-weight);
}
.table-main-wraper-permission .table-responsive .table tr {
  border-top: 1px solid #f1f4ff;
}
.table-permission-modules-td {
  /* border-top: 1px solid #f1f4ff !important; */
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.table-main-wraper-permission .table-responsive .table td tr {
  /* border: 1px solid #f1f4ff; */
}
.table-main-wraper-permission .table-responsive .table tr td:last-child {
  border-left: 1px solid #f1f4ff !important;
}
.heading-permission {
  font-weight: var(--heading-weigth);
  font-size: var(--text-size);
  color: #3a4a83;
}
.table-main-wraper-permission .table-responsive .table td a img {
  width: 15px;
}
.modules-permission-listing {
  margin-bottom: 15px;
}
.modules-permission-listing .modules-permission-heading {
  font-size: var(--text-size);
  color: #2e3e73;
}
.listing-edit-permission {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #f1f4ff;
}
.list-edit-module-wrap {
  color: var(--font-color);
  padding: 8px 8px 8px 8px;
  border-bottom: 1px solid #f1f4ff;
}
.listing-edit-permission ul {
  list-style: none;
}
.listing-edit-permission .onoffswitch2 {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.listing-edit-permission .onoffswitch2-checkbox {
  display: none;
}

.listing-edit-permission .onoffswitch2-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--app-color-1);
  border-radius: 5px;
}

.listing-edit-permission .onoffswitch2-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.listing-edit-permission .onoffswitch2-inner:before,
.onoffswitch2-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 30px;
  font-size: 25px;
  color: white;
  font-family: var(--text-font-family);
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.listing-edit-permission .onoffswitch2-inner:before {
  content: "";
  padding-left: 10px;
  background-color: var(--app-color-1);
  color: #ffffff;
}
.onoffswitch2 label {
  margin-bottom: 0;
}
.listing-edit-permission .onoffswitch2-inner:after {
  content: "";
  padding-right: 10px;
  background-color: #eff2ff;
  color: black;
  text-align: right;
}

.listing-edit-permission .onoffswitch2-switch {
  display: block;
  width: 33px;
  margin: 0px;
  background: #ffffff;
  border: 2px solid var(--app-color-1);
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 27px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  background-image: -moz-linear-gradient(
    center top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    center top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -o-linear-gradient(
    center top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    center top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.listing-edit-permission
  .onoffswitch2-checkbox:checked
  + .onoffswitch2-label
  .onoffswitch2-inner {
  margin-left: 0;
}

.listing-edit-permission
  .onoffswitch2-checkbox:checked
  + .onoffswitch2-label
  .onoffswitch2-switch {
  right: 0px;
}

.listing-edit-permission .cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.lising-radio-permission-wrap ul {
  display: flex;
  align-items: center;
  justify-content: end;
}

.listing-edit-permission .cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.listing-edit-permission input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 75px;
  height: 30px;
  background-color: #dddddd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}

.listing-edit-permission input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

.listing-edit-permission input.cmn-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}

.listing-edit-permission input.cmn-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 22px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}

.listing-edit-permission input.cmn-toggle-round-flat:checked + label {
  background-color: #27a1ca;
}

.listing-edit-permission input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 45px;
  background-color: #27a1ca;
}
.listing-edit-permission .radiotextsty {
  color: var(--font-color);
  font-size: var(--text-size);
  font-weight: normal;
}

.listing-edit-permission .customradio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #eff2ff;
  padding: 4px 10px 4px 6px;
}

.listing-edit-permission .customradio:first-child {
  border-top-left-radius: 5px; /* Adjust the radius as needed */
  border-bottom-left-radius: 5px; /* Adjust the radius as needed */
}

.listing-edit-permission .customradio:last-child {
  border-top-right-radius: 5px; /* Adjust the radius as needed */
  border-bottom-right-radius: 5px; /* Adjust the radius as needed */
}

/* Hide the browser's default radio button */
.listing-edit-permission .customradio input {
  /* position: absolute; */
  opacity: 1;
  cursor: pointer;
  margin-right: 4px;
}

.listing-edit-permission .customradio input[type="radio"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
}

/* Create a custom radio button */
.listing-edit-permission .checkmark {
  position: absolute;
  top: 5px;
  left: 6px;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.listing-edit-permission .customradio:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.listing-edit-permission .customradio input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid #bebebe;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.listing-edit-permission .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.listing-edit-permission .customradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .listing-edit-permission .customradio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #a3a0fb;
} */
.role-permission-radio {
  width: 18px;
  height: 18px;
}

.btn-role-concave {
  position: absolute;
  height: 40px;
  width: 40px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  background-color: var(--icon-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 77px;
  left: -40px;
  color: #fff;
  border: 1px solid var(--icon-color2);
}

.role-permission-radio:checked {
  background-color: var(--app-color-1);
}

.listing-edit-permission ul {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.listing-edit-permission ul li:last-child .list-edit-module-wrap {
  border-bottom: none;
}

.lising-radio-permission-wrap ul {
  list-style: none;
  text-align: right;
  padding-left: 0 !important;
}
.lising-radio-permission-wrap ul li {
  display: inline-block;
  padding-left: 5px;
}
.lising-radio-permission-wrap ul li:nth-child(2) {
  position: relative;
  /* top: -6px; */
}
.form-check-inline {
  display: flex;
}
.list-edit-module-wrap .heading-lead {
  display: block;
  padding-top: 5px;
}

.table-role-permission {
  min-width: 800px;
}

/* .ag-theme-alpine {
  border-radius: 10px;
} */

.role-type-table-wrap p {
  margin-bottom: 0 !important;
}

#specifyColor {
  accent-color: var(--app-color-1);
  /* Remove the default border */
  border: none;

  /* Remove the default outline */
  outline: none;
}

.customradio-role {
  width: 18px;
  height: 18px;
  border: 2px solid var(--app-color-1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}
.customradio-role .radio-mark {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background: var(--app-color-1);
  display: none;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.customradio-role input {
  display: none;
}

.customradio-role input:checked + .radio-mark {
  opacity: 1;
  display: inline-block;
}

.ellipsis-cell {
  width: 150px;
}

.role-page-description {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  margin-bottom: 0;
}

.role-page-description-admin span {
  font-weight: 500;
}

.role-page-description-admin {
  color: var(--app-color-1);
}

@media (max-width: 1004px) {
  .btn-role-concave {
    top: -10px;
    left: -10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 4px;
    background: transparent;
    border: none;
  }

  .btn-role-concave img {
    filter: invert(1);
    width: 20px;
  }
}
@media (max-width: 1000px) {
  .off-concave-role {
    min-width: 98vw;
  }
  .off-concave-chat {
    min-width: 98vw;
  }
}
@media (max-width: 522px) {
  .role-main-ui {
    flex-direction: column;
  }

  .role-main-ui-btn {
    text-align: justify !important;
  }
}
@media (max-width: 600px) {
  .role-offconcave-header-wrap {
    flex-direction: column;
  }

  .form-role {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@keyframes bottomToTop {
  from {
    opacity: 0;
    transform: translateY(20px);
    background-color: var(--app-color-1);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    background-color: #fff;
  }
}
