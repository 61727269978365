@keyframes blink {
  0%,
  50%,
  100% {
    background-color: var(--app-color-1);
    color: #fff;
  }
  25%,
  75% {
    background-color: var(
      --app-color-2
    ); /* Change this to the color you want during the blink */
    color: #fff;
  }
}

@keyframes blink-t {
  0%,
  50%,
  100% {
    background-color: var(--app-color-2);
    color: #fff;
  }
  25%,
  75% {
    background-color: var(
      --app-color-1
    ); /* Change this to the color you want during the blink */
    color: #fff;
  }
}

.banner {
  min-height: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: var(--text-size);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
}

.banner-2 {
  background-color: var(--app-color-1);
  animation: blink 6s infinite; /* Adjust the duration as needed */
}
.banner-3 {
  background-color: var(--app-color-2);
  animation: blink-t 6s infinite; /* Adjust the duration as needed */
}
