.board-container-main {
  overflow-x: auto;
}

.board-container {
  display: flex;
  gap: 20px;
  min-height: 80vh;
}

.type-container {
  flex: 1;
  background-color: #fff;
  border: 1px solid #e9eeff;
  border-radius: 6px;
  min-width: 340px;
}

.task-item-container {
  padding: 20px;
}

.type-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e9eeff;
}

.type-container-header h3 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  margin-bottom: 0;
}

.type-container-header span {
  color: var(--app-color-1);
}

.type-container-header svg {
  color: var(--app-color-2);
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.icon-head-dnd:hover {
  color: var(--app-color-1);
}

.task-item {
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: grab;
  background-color: #f4fbff;
  display: flex;
  align-items: start;
  position: relative;
  flex-direction: column;
}

.task-item-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #e5e9f8;
}

.task-item-head p {
  margin-bottom: 0 !important;
  color: var(--app-color-1);
}

.task-item-head p span {
  margin-right: 5px;
}

.task-item-iternal-completed p {
  text-decoration: line-through;
}

.task-item-iternal {
  padding: 25px 20px 25px 20px;
}

.task-item p {
  margin-bottom: 0;
  font-size: var(--text-size);
  font-weight: var(--text-font-size);
  margin-bottom: 10px;
}

.dnd-item-dropdown {
  position: absolute !important;
  top: 5px;
  right: 5px;
  background: #f4fbff;
}

.dnd-item-dropdown button,
.dnd-item-dropdown button .menu-btn {
  background: #f4fbff;
}

.dnd-item-dropdown button .menu-btn {
  margin-left: 0 !important;
}

.task-item ul {
  margin-bottom: 0;
  display: flex;
}

.team-list-last {
  border: 1px solid var(--app-color-2);
  color: #fff;
  background-color: var(--app-color-2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.team-list-last:hover {
  background-color: var(--app-color-1);
  border: 1px solid var(--app-color-1);
}

.board-scroll-btn {
  margin-right: 1.5rem;
}

.board-scroll-btn div {
  border-radius: 50%;
  background-color: var(--app-color-2);
  color: #fff;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.board-scroll-btn div:hover {
  background-color: var(--app-color-1);
}

.anchor-list-view {
  text-decoration: none;
  font-weight: var(--text-font-weight);
  font-size: var(--text-font-size);
  color: #333;
  cursor: pointer;
}

.shimmer-card-kanban {
  width: inherit;
}

.shimmer-card-kanban-main {
  padding-right: 1.5rem;
}
