.userProfileImageHeader {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.cloud-notification-text {
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-family: var(--text-font-family) !important;
}
.header-module-toggle-btn {
  border-radius: 4px;
  border: 1px solid #94abff;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.module-dropdown-item-img-div {
  width: 85px !important;
}
.module-dropdown-item-img {
  width: 100% !important;
  height: 68px !important;
  object-fit: contain;
}

.header-module-toggle-btn p {
  color: #3a4a82;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.module-dropdown-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.module-dropdown-item-task-title {
  color: #94abff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.module-dropdown-item-crm-title {
  color: #0c84eb;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.module-dropdown-item-chat-title {
  color: #946bce;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.module-dropdown-item-descp {
  color: #424242;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.cloud-txt-1 b {
  font-weight: 500 !important;
}

.cloud-txt-2 {
  font-weight: var(--text-font-weight) !important;
}

.theme-dropdown-text {
  font-size: var(--text-size) !important;
  font-weight: var(--text-font-weight) !important;
  color: #212529 !important;
}

.settings-header svg {
  color: var(--selected-route-color-svg);
}
.dropdown-menu {
  background-color: var(--search-bar-bg);
}
.dropdown-item {
  color: var(--font-color);
}

.dropend.btn-group button {
  color: var(--font-color) !important;
}

.settings-header:hover {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust the size */
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.icon-container:hover {
  background-color: rgba(148, 171, 255, 0.24); /* Light gray hover effect */
  cursor: pointer;
}
