.report-profile-main-wrap {
  border-radius: 20px;
  background: var(--card-color);
}
.calendar-date-wrap {
  padding: 15px 25px 8px 25px;
  border-bottom: 1px solid var(--divider-color);
}
.calendar-date-wrap .dropdown-date {
  width: max-content;
  position: relative;
}
.calendar-date-wrap .dropdown-date img {
  position: absolute;
  top: 9px;
  left: -12px;
}
.cursor-pointer{
  cursor: pointer;
}
.calendar-date-wrap .selected-items-priority-section {
  background: none;
  border: none;
  font-weight: bold;
}
.css-17m54mf-singleValue {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #1f2a49 !important;
}
.profile-daily-report-wrap {
  padding: 20px 25px;
}
.daily-profile-main-wrap {
  border: 1px solid var(--divider-color);
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
}
.daily-profile-main-wrap .profile-image-report {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
  float: left;
  border: 4px solid var(--search-bar-bg);
  box-shadow: 0px 9px 10px 0 var(--divider-color);
}
.profile-image-text {
  width: 70%;
  float: left;
  padding-left: 15px;
}
.profile-image-text h3 {
  color: var(--selected-route-color-svg);
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}
.profile-image-text span {
  display: block;
  color: var(--font-color);
  font-size: 14px;
  margin: 6px 0;
}
.profile-image-text ul {
  list-style: none;
}
.profile-image-text ul li {
  font-size: 14px;
  color: var(--font-color);
  padding-bottom: 5px;
}
.profile-image-text ul li strong {
  font-weight: 600;
}
.last-report-profile {
  font-size: 14px;
  margin-top: 10px;
  color: #999999;
}
.last-report-profile strong {
  font-weight: 600;
}
.performance-total-main-wrap ul {
  list-style: none;
  margin-right: 15px;
  width: 51%;
  float: right;
  margin-top: 10px;
}
.performance-total-main-wrap ul li .performance-total-list-wrap {
  font-size: 16px;
  color: var(--selected-route-color-svg);
  margin-top: 12px;
}
.performance-total-list-wrap span {
  margin-right: 5px;
}
.performance-total-list-wrap span img {
  width: 40px;
}
.performance-total-list-wrap strong {
  font-size: 30px;
  margin-left: 35px;
  color: #2098d1;
  position: relative;
  top: 5px;
}
.performance-total-main-wrap
  ul
  li:nth-child(2)
  .performance-total-list-wrap
  strong {
  color: #eca944;
  position: relative;
}
.performance-total-list-wrap strong img {
  width: 25px;
  position: relative;
  top: -5px;
}
.today-task-main-wrap {
  padding: 0 25px;
  padding-bottom: 20px;
}
.today-task-inner-wrap {
  border: 1px solid var(--divider-color);
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
}
.today-task-inner-wrap h5 {
  font-size: 18px;
  color: var(--font-color);
  font-weight: 300;
  margin-bottom: 15px;
  position: relative;
}
.today-task-inner-wrap h5 strong {
  font-weight: 600;
}
.today-task-inner-wrap h5 span {
  font-size: 22px;
  color: var(--icon-color);
  font-weight: bold;
  margin-left: 5px;
}
.today-task-inner-wrap h5 a {
  position: absolute;
  right: 0;
  border: 1px solid #94abff;
  padding: 8px 18px;
  border-radius: 6px;
  font-size: 13px;
  color: #394b87;
  font-weight: 500;
}
.today-task-inner-wrap h5 a:hover img {
  filter: brightness(0) invert(1);
}
.today-task-inner-wrap h5 a:hover {
  background: #94abff;
  color: #fff;
}
.today-task-inner-wrap h5 a img {
  margin-right: 5px;
}
.today-task-main-wrap ul {
  list-style: none;
  margin-bottom: 10px;
}
.today-task-main-wrap ul li .listing-today-task {
  background: var(--card-color);
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0 var(--divider-color);
}
.today-task-main-wrap ul li .listing-today-task:hover {
  background: var(--background-dashboard-color) !important;
}
.today-task-main-wrap ul li .listing-today-task-active {
  background: var(--background-dashboard-color) !important;
}
.listing-today-task .icon-list-today {
  display: block;
  float: left;
  width: 50px;
}
.listing-today-task .icon-list-today img {
  width: 100%;
}
.listing-today-text-wrap {
  width: 90%;
  float: left;
  padding-left: 10px;
  padding-top: 2px;
}
.listing-today-text-wrap span {
  display: block;
  font-size: 14px;
  color: var(--selected-route-color-svg);
}
.listing-today-text-wrap strong {
  font-size: 19px;
  color: #2098d1;
}
.today-task-main-wrap
  ul
  li:nth-child(2)
  .listing-today-task
  .listing-today-text-wrap
  strong {
  color: #ff5f5f;
}
.due-task-main-wrap {
  padding: 0 25px;
  padding-bottom: 20px;
}
.due-task-main-wrap .due-task-wrap {
  border: 1px solid var(--divider-color);
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
}
.due-task-wrap h5 {
  font-size: 18px;
  color: var(--font-color);
  font-weight: 300;
  margin-bottom: 15px;
  position: relative;
}
.due-task-wrap h5 strong {
  font-weight: 600;
}
.due-task-wrap h5 a {
  position: absolute;
  right: 0;
  border: 1px solid #94abff;
  padding: 8px 18px;
  border-radius: 6px;
  font-size: 13px;
  color: #394b87;
  font-weight: 500;
}
.due-task-wrap h5 a:hover img {
  filter: brightness(0) invert(1);
}
.due-task-wrap h5 a:hover {
  background: #94abff;
  color: #fff;
}
.due-task-wrap ul {
  list-style: none;
  margin-bottom: 10px;
}
.due-task-wrap ul li .due-task-list {
  background: var(--card-color);
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0 var(--divider-color);
}
.due-task-wrap ul li .due-task-list:hover {
  background: var(--background-dashboard-color) !important;
}
.due-task-wrap ul li .due-task-list-active {
  background: var(--background-dashboard-color) !important;
}
.due-task-list .icon-list-today {
  display: block;
  float: left;
  width: 50px;
}
.due-task-list .icon-list-today img {
  width: 100%;
}
.due-task-list .listing-today-text-wrap {
  width: 75%;
}
.due-task-wrap
  ul
  li:nth-child(2)
  .due-task-list
  .listing-today-text-wrap
  strong {
  color: #eba900 !important;
}
.due-task-wrap
  ul
  li:nth-child(3)
  .due-task-list
  .listing-today-text-wrap
  strong {
  color: #e8910d !important;
}
.due-task-wrap
  ul
  li:nth-child(4)
  .due-task-list
  .listing-today-text-wrap
  strong {
  color: #ff5f5f !important;
}
.due-task-list-main-wrap {
  padding: 0 25px;
  padding-bottom: 20px;
}
.due-task-list-wrap {
  border: 1px solid var(--divider-color);
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
}
.due-task-list-wrap h5 {
  font-size: 18px;
  color: var(--font-color);
  font-weight: 300;
  margin-bottom: 15px;
  position: relative;
}
.due-task-list-wrap h5 strong {
  font-weight: 600;
}

.pr-completed-tasks {
  border-radius: 8px;
  background: #e6f7ec;
  padding: 18px 34px;
  margin-bottom: 16px;
}

.pr-task-status-info-wrap p {
  font-size: 16px;
}

.pr-completed-count {
  font-size: 22px;
  color: #2dbf64;
  font-weight: bold;
}

.pr-due-tasks-section {
  padding: 22px;
  border-radius: 8px;
  background: #f2f5ff;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pr-due-tasks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 13px;
  border-radius: 8px;
}

.pr-due-count {
  font-size: 22px;
  color: #94abff;
  font-weight: bold;
}

.pr-inprogress-tasks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 13px;
  border-radius: 8px;
  background: #d9eaf9;
}

.pr-inprogress-count {
  font-size: 22px;
  color: #2098d1;
  font-weight: bold;
}

.pr-onhold-tasks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 13px;
  border-radius: 8px;
  background: #f1ece0;
}

.pr-onhold-count {
  font-size: 22px;
  color: #eba900;
  font-weight: bold;
}

.pr-review-tasks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 13px;
  border-radius: 8px;
  background: #f1e9e2;
}

.pr-review-count {
  font-size: 22px;
  color: #eba900;
  font-weight: bold;
}

.pr-overdue-tasks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 13px;
  border-radius: 8px;
  background: #f4e3ec;
}

.pr-overdue-count {
  font-size: 22px;
  color: #ff5f5f;
  font-weight: bold;
}

@media screen and (max-width: 1582px) {
  .performance-total-main-wrap ul {
    width: 69%;
  }
}
@media screen and (max-width: 1310px) {
  .performance-total-main-wrap ul {
    width: 79%;
  }
}
@media screen and (max-width: 500px) {
  .performance-total-main-wrap ul {
    width: 95%;
  }
}
