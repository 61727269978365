.team-project-heading-a {
  text-decoration: none;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  margin-right: 5px;
}

.team-project-heading-a-last {
  color: var(--icon-color);
}

.team-list-img-text-wrap img {
  width: 14px;
}

.add-team-member-wraper {
  padding: 25px;
}
.add-team-member-wraper h3 {
  color: #000;
  font-size: 20px;
}
.add-team-member-wraper h3 span {
  font-weight: var(--text-font-weight);
}
.add-team-member-wraper h3 a {
  float: right;
  border: 1px solid #94abff;
  padding: 10px 25px;
  border-radius: 6px;
  font-size: 12px;
  color: #394b84;
}
.add-team-member-wraper h3 a img {
  width: 12px;
}
.add-team-member-wraper p {
  font-size: var(--text-size);
  color: #000;
  font-weight: var(--text-font-weight);
}
.listing-form-team-member {
}
.listing-form-team-member .form-group {
  margin-bottom: 15px;
}
.listing-form-team-member .form-group label {
  font-size: var(--text-size);
  font-weight: 400;
}
.listing-form-team-member {
  margin-top: 15px;
}
.listing-form-team-member .form-control {
  height: 40px;
  border: 1px solid #f1f4ff;
  box-shadow: none;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
.checkbox-group .checkbox {
  margin-top: 25px;
}
.checkbox-group .checkbox label {
  font-size: var(--text-size);
  font-weight: 400;
}
.checkbox-group .checkbox input {
  position: relative;
  top: 2px;
  border: none;
  box-shadow: none;
  background: #f4f4f4;
  outline: none;
}
.form-btn-create {
  text-align: right;
}
.form-btn-create button {
  background: var(--app-color-2);
  padding: 10px 35px;
  border: none;
  outline: none;
  border-radius: 4px;
  color: #fff;
  font-size: var(--text-size);
}

.form-btn-create button:hover {
  background: var(--app-color-1);
}

.create-team-modal-header-wrap {
  align-items: center;
}

.create-task-checkbox {
  position: relative;
}

.create-task-checkbox input[type="checkbox"] {
  display: none;
}

.create-task-checkbox input[type="checkbox"] ~ label {
  text-indent: 20px;
}

.create-task-checkbox input[type="checkbox"]:checked ~ label:after {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  color: white;
}

.create-task-checkbox input[type="checkbox"]:checked ~ label:before {
  position: absolute;
  display: block;
  top: 1px;
  bottom: 0;
  left: 0px;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background-color: var(--app-color-1);
}

.create-task-checkbox input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 1px;
  bottom: 0;
  left: 0px;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
}

.create-team-modal-check-text {
  font-weight: var(--text-font-weight);
}

.team-selected-filter-options {
  background: rgba(148, 171, 255, 0.24) !important;
  color: var(--font-color) !important;
}

.team-selected-filter-options span {
  color: var(--icon-color) !important;
}

.team-filter-option-overdue {
  height: 35px;
  padding: 0px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-filter-option-overdue:hover {
  background: rgba(148, 171, 255, 0.24) !important;
}

.team-filter-option {
  cursor: pointer;
}

.open-target-link {
  text-decoration: none;
  cursor: pointer;
  color: #b3b3b3;
}

.open-target-link:hover {
  color: var(--icon-color);
}

.team-view-wraper {
  padding: 25px;
}
.team-view-wraper h2 {
  font-size: 20px;
}
.team-view-wraper h2 a {
  border: 1px solid #94abff;
  padding: 10px 20px;
  color: #394b84;
  float: right;
  font-size: var(--text-size);
  border-radius: 6px;
}
.team-view-wraper p {
  font-weight: var(--text-font-weight);
  color: #000;
  font-size: var(--text-size);
}

.left-team-main-wrap {
  margin-top: 20px;
}
.left-team-main-button .add-people-btn {
  display: block;
  width: 190px;
  background: var(--app-color-2);
  padding: 12px 0;
  text-align: center;
  color: #fff;
  border-radius: 6px;
  float: left;
  margin-right: 10px;
  border: 1px solid var(--app-color-2);
}

.left-team-main-button .add-people-btn:hover {
  background: var(--app-color-1);
  border: 1px solid var(--app-color-1);
}

.caret-btn-image {
  width: 72px;
  display: block;
  padding: 12px 0;
  border-radius: 6px;
  background: var(--app-color-1);
  border: 1px solid var(--app-color-1);
  text-align: center;
  float: left;
  height: 50px;
}

.caret-btn-image:hover {
  background: var(--app-color-2);
  border: 1px solid var(--app-color-2);
}

.caret-btn-image svg {
  color: #fff;
  width: 100%;
  height: 100%;
}

.member-team-info-wrap {
  border: 1px solid #f1f4ff;
  margin-top: 10px;
  border-radius: 4px;
}
.member-team-wrap {
  padding: 25px;
}
.member-team-wrap h5 {
  font-size: 20px;
}
.member-team-wrap span {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
}
.team-listing-main-wrap {
}
.team-listing-main-wrap ul {
  list-style: none;
  padding: 25px 25px 0 25px;
  border-top: 1px solid #f1f4ff;
}
.team-listing-main-wrap ul li {
  margin-bottom: 20px;
}
.team-listing-main-wrap ul li .listing-team-wrap {
  display: flex;
}
.listing-team-wrap .listing-team-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  background-size: cover !important;
}
.listing-team-text {
}
.listing-team-text h6 {
  font-size: var(--text-size);
  color: #000;
  margin-top: 8px;
}
.listing-team-text span {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #000;
}
.listing-team-text {
  line-height: 16px;
  width: 80%;
  float: left;
  padding-left: 15px;
}
.team-activity-wraper {
  padding: 25px;
  border: 1px solid #f1f4ff;
  margin-top: 20px;
  border-radius: 4px;
}
.team-activity-wraper h4 {
  font-size: 20px;
  color: #000;
}
.team-activity-wraper ul {
  list-style: none;
  padding-left: 0px;
}
.team-activity-wraper ul li {
}
.team-activity-wraper ul li .listing-activity-team {
}
.listing-activity-team-icon {
  width: 50px;
  height: 50px;
  float: left;
  background-color: #d6ddff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listing-activity-team-icon svg {
  min-width: 60%;
  color: var(--app-color-1);
}
.listing-activity-team {
  background: #f4f6ff;
  padding: 25px;
  margin-top: 20px;
  border-radius: 6px;
}
.listing-text-activity {
  width: 80%;
  float: left;
  padding-left: 15px;
  margin-top: 2px;
}
.listing-text-activity h4 {
  font-size: 18px;
  color: #000;
}
.listing-text-activity p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #000;
}

.listing-team-image {
  background: url("../../../assets/profile-image.svg") no-repeat top;
}

.team-view-wraper-head h3 {
  font-size: var(--heading-size);
}

.form-btn-create button {
  min-width: 118px;
}

.no-team-data {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.no-team-data span {
  color: var(--app-color-1);
  text-decoration: underline;
  cursor: pointer;
}

.off-concave-team {
  min-width: 1300px;
}

.team-detail-section-header img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.team-detail-section-header span {
  font-size: var(--heading-size);
  font-weight: var(--heading-font-weight);
}

.open-target-link-p {
  margin-bottom: 0px !important;
  color: #b3b3b3 !important;
  cursor: pointer;
}

.open-target-link-p:hover {
  color: var(--app-color-1) !important;
}

.no-multi-cross .css-1w941pa-MultiValueRemove {
  display: none !important;
}

.no-multi-cross .css-swicq1-MultiValueGeneric {
  padding-right: 6px;
}

.listing-team-modal-search-input-wrap input {
  width: 100%;
  border-radius: 4px;
  height: 40px;
  margin-top: 5px;
  border: 1px solid #f1f4ff;
  padding: 5px;
  padding-right: 28px;
  font-weight: var(--text-font-weight);
  margin-bottom: 12px;
  font-family: 'Poppins', sans-serif;
}

.listing-team-modal-search-input-wrap span {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.listing-team-modal-search-input-wrap input:focus {
  outline: none;
  border: 1px solid #f1f4ff;
}

.member-list-border-right .ag-ltr .ag-cell:first-child {
  border-right: none !important;
}

@media (max-width: 976px) {
  .caret-btn-image {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .caret-btn-image {
    margin-top: 0px;
  }
}

@media (max-width: 508px) {
  .create-team-modal-header-wrap {
    flex-direction: column;
    align-items: start;
  }

  .form-btn-create button {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .team-view-wraper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .team-activity-wraper,
  .listing-activity-team {
    padding: 10px;
  }
}

@media (max-width: 314px) {
  .caret-btn-image {
    margin-top: 10px;
  }

  .left-team-main-button .add-people-btn {
    width: 100%;
  }
}
