.member-detail-main-wraper {
  padding: 25px;
  padding: 30px;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
  min-height: 93dvh;
}

.members-top-section-wraper {
}

.members-top-section-wraper .member-info-wraper {
}

.member-info-wraper .member-image-wraper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover !important;
  float: left;
}


.team-details-name-wrap {
  cursor: pointer;
}

.team-details-name-wrap img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 3px;
}

.team-details-name-wrap span {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  background: #ffaeae;
  text-align: center;
  color: #fff;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  /* padding-top: 7px; */
  margin-right: 3px;
}

.member-text-wraper {
  width: 90%;
  float: left;
  padding-left: 15px;
}

.member-text-wraper h4 {
  font-size: 20px;
  margin-top: 10px;
}

.member-text-wraper h4 span {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.member-text-wraper h4 a:last-child {
  color: var(--app-color-1);
}

.member-text-wraper h4 a:hover {
  color: var(--app-color-1);
}

.member-text-wraper .create-btn {
  padding: 5px 17px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: var(--app-color-2);
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -3px;
  font-weight: var(--text-font-weight);
}
.member-text-wraper .create-btn:hover {
  padding: 5px 17px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: var(--app-color-1);
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -3px;
  font-weight: var(--text-font-weight);
}

.quick-info-wrap {
  line-height: 16px;
}

.quick-info-wrap ul {
  list-style: none;
}

.quick-info-wrap ul li {
  display: inline-block;
  font-size: 12px;
  font-weight: var(--text-font-weight);
  color: #000;
  padding: 0 15px;
  position: relative;
}

.quick-info-wrap ul li:before {
  content: "";
  position: absolute;
  left: -5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d1daf7;
  top: 4px;
}

.quick-info-wrap ul li:nth-child(1) {
  padding-left: 0 !important;
}

.quick-info-wrap ul li:nth-child(1):before {
  display: none;
}

.top-lisitng-info-wraper {
  margin-top: 10px;
}

.top-lisitng-info-wraper ul {
  list-style: none;
  text-align: right;
}

.top-lisitng-info-wraper ul li {
  display: inline-block;
  padding-left: 20px;
}

.top-lisitng-info-wraper ul li .form-group {
  position: relative;
}

.top-lisitng-info-wraper ul li .form-group .form-control {
  height: 40px;
  box-shadow: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
  border-radius: 6px;
  padding-right: 35px;
}

.top-lisitng-info-wraper ul li .form-group button {
  position: absolute;
  top: 5px;
  background: none;
  border: none;
  outline: none;
  width: 50px;
  right: 0;
}

.top-lisitng-info-wraper ul li .form-group button img {
  width: 20px;
}

.top-lisitng-info-wraper ul li a {
  display: block;
  height: 40px;
  border: 1px solid #94abff;
  padding: 7px 25px 6px 25px;
  border-radius: 6px;
  color: #394b87;
}

.top-lisitng-info-wraper ul li a img {
  width: 20px;
  position: relative;
  top: -1px;
}

.profile-listing-wraper {
}

.profile-listing-wraper ul {
  list-style: none;
  margin: 25px 0;
}

.profile-listing-wraper ul li {
}

.profile-listing-wraper ul li .listing-profile-wraper {
  border: 1px solid #f1f4ff;
  padding: 25px;
  border-radius: 6px;
}

.listing-profile-wraper h5 {
  font-size: 20px;
  color: #000;
}

.listing-profile-wraper h5 img {
  width: 50px;
  margin-right: 8px;
}

.listing-profile-wraper h5 span {
  float: right;
  color: #94abff;
  position: relative;
  top: 10px;
}

.listing-profile-info {
  margin-top: 10px;
}

.listing-profile-info span {
  display: block;
  border-bottom: 1px solid #f1f4ff;
  padding: 10px 0;
  font-size: var(--text-size);
}

.listing-profile-info span img {
  width: 20px;
  position: relative;
  top: -1px;
}

.listing-profile-info span:nth-child(3) {
  border: none;
  padding-bottom: 0;
}

.listing-profile-info span strong {
  font-weight: normal;
  float: right;
  color: #394b84;
}

.team-left-info-wrap {
  border: 1px solid #f1f4ff;
  border-radius: 6px;
}

.team-left-info-wrap h5 {
  padding: 25px;
  font-size: var(--heading-size);
  color: #333333;
  font-weight: var(--heading-weight);
}

.list-team-user-wrap {
  padding: 0 25px;
}

.team-left-info-wrap h5 a {
  font-size: var(--text-size);
  float: right;
  color: #94abff;
  position: relative;
  top: 5px;
}

.list-team-user-wrap {
}

.list-team-user-wrap ul {
  list-style: none;
  border-bottom: 1px solid #f1f4ff;
}

.list-team-user-wrap ul li {
  margin-bottom: 15px;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.list-team-user-wrap ul li img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 50%;
}

.contact-info-wraper {
  padding: 25px;
}

.contact-info-wraper h4 {
  font-size: var(--heading-size);
  color: #333333;
  font-weight: var(--heading-weight);
}

.contact-info-wraper ul {
  list-style: none;
  margin-top: 15px;
}

.contact-info-wraper ul li {
  font-size: var(--text-size);
  margin-bottom: 10px;
  font-weight: var(--text-font-weight);
}
.contact-info-wraper ul li svg {
  height: 20px;
  width: 20px;
  color: var(--app-color-1);
}

.contact-info-wraper ul li img {
  width: 20px;
  position: relative;
  top: -2px;
}

.user-profile-worked-wrap {
  border: 1px solid #f1f4ff;
  border-radius: 6px;
  padding: 25px;
}

.user-profile-worked-wrap h5 {
  font-size: var(--heading-size);
  color: #333333;
  font-weight: var(--heading-weight);
}

.user-profile-worked-wrap h5 a {
  float: right;
  font-size: var(--text-size);
  color: #94abff;
  position: relative;
  top: 5px;
  font-weight: var(--text-font-weight);
}

.user-profile-worked-wrap .table-responsive {
  margin-top: 15px;
}

.user-profile-worked-wrap .table {
  border: 1px solid #f1f4ff;
  color: #000;
  margin-bottom: 0;
}

.user-profile-worked-wrap .table tr {
  vertical-align: middle;
  font-size: var(--text-size);
  border-bottom: 1px solid #f1f4ff;
}

.table-heading {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.table-heading img {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.user-member-wrap {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.user-member-wrap img {
  width: 18px;
  position: relative;
  top: -2px;
}

.status-icon-wrap {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
.user-member-last-active {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
.status-in-progress {
  color: #2098d1;
}
.status-icon-wrap img {
  width: 20px;
}

.no-associated-proj-txt {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
