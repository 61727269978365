.project-plan-main-wraper {
  padding: 25px;
}

.top-product-heading-wraper {}

.heading-main-plan-wrap {}

.heading-main-plan-wrap h4 {
  font-size: 20px;
  color: #000;
  width: 22%;
  float: left;
}

.heading-main-plan-wrap h4 span {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.heading-main-plan-wrap .progress {
  width: 30%;
  float: left;
}

.progress {
  border-radius: 60px;
  height: 12px;
  background: #e9eeff;
  width: 60%;
  margin: 0 auto;
  margin-top: 8px;
}

.progress-bar {
  background: #394b84;
}

.date-plan-wrap {
  font-size: var(--text-size);
  color: #000;
  margin-top: 2px;
  text-align: right;
}

.date-plan-wrap img {
  width: 20px;
}

.project-plan-main-wraper p {
  font-size: var(--text-size);
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  padding: 10px 0;
}

.project-plan-table-wrap {}

.project-plan-table-wrap ul {
  list-style: none;
}

.project-plan-table-wrap ul li {}

.project-table-wrap {
  border: 1px solid #f1f4ff;
  margin-bottom: 15px;
}

.project-table-wrap h2 {
  font-size: 18px;
  color: #394b84;
  padding: 10px;
  font-weight: 500;
}

.project-table-wrap h2 img {
  width: 20px;
}

.project-table-wrap h2 button {
  background: none;
  border: none;
  outline: none;
}

.project-table-wrap h2 button img {
  width: 15px;
}

.project-table-wrap span {
  font-size: 13px;
  font-weight: var(--text-font-weight);
}

.project-plan-table-wrap .table {
  border: 1px solid #f1f4ff;
  margin-bottom: 0;
}

.project-table-wrap .table tr {
  border: 1px solid #f1f4ff;
  font-size: 16px;
}

.project-plan-main-wraper .control-group {
  display: block;
  vertical-align: top;
  background: #fff;
  text-align: left;
}

.project-plan-main-wraper .control {
  display: block;
  position: relative;
  padding-left: 38px;
  font-size: var(--text-size);
  font-weight: 500;
}

.project-plan-main-wraper .control input {
  z-index: -1;
  opacity: 0;
}

.project-plan-main-wraper .control__indicator {
  position: absolute;
  left: 0;
  height: 23px;
  width: 25px;
  background: #fff;
  border-radius: 0;
  border: 1px solid #f1f4ff;
}

.project-plan-main-wraper .control--radio .control__indicator {
  border-radius: 50%;
}

.project-plan-main-wraper .control:hover input~.control__indicator,
.control input:focus~.control__indicator {
  background: #56baec;
}

.project-plan-main-wraper .control input:checked~.control__indicator {
  background: #56baec;
}

.project-plan-main-wraper .control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
  background: #56baec;
}

.project-plan-main-wraper .control input:disabled~.control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.project-plan-main-wraper .control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.project-plan-main-wraper .control input:checked~.control__indicator:after {
  display: block;
}

.project-plan-main-wraper .control--checkbox .control__indicator:after {
  left: 10px;
  top: 6px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.project-plan-main-wraper .control--checkbox input:disabled~.control__indicator:after {
  border-color: #7b7b7b;
}

.project-plan-main-wraper .control--radio .control__indicator:after {
  left: 7px;
  top: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.project-plan-main-wraper .control--radio input:disabled~.control__indicator:after {
  background: #7b7b7b;
}

.project-plan-main-wraper .select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.project-plan-main-wraper .select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.project-plan-main-wraper .select select::-ms-expand {
  display: none;
}

.project-plan-main-wraper .select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}

.project-plan-main-wraper .select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.project-plan-main-wraper .select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}

.project-plan-main-wraper .select select:hover~.select__arrow,
.select select:focus~.select__arrow {
  border-top-color: #000;
}

.project-plan-main-wraper .select select:disabled~.select__arrow {
  border-top-color: #ccc;
}

.heading-table-project td {
  font-size: var(--text-size);
  vertical-align: middle;
}

.heading-table-project td img {
  width: 15px;
  margin-left: 3px;
  position: relative;
}

.project-table-wrap tr td {
  font-size: 13px;
  vertical-align: middle;
  border-left: 1px solid #f1f4ff;
}

.project-table-wrap .checkbox input {
  border: 1px solid #f1f4ff;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  width: 17px;
  height: 21px;
}

.team-listing-wrap ul {
  list-style: none;
}

.team-listing-wrap ul li {
  display: inline-block;
}

.team-listing-wrap ul li .team-list-wrap {
  background: #b0e57c;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-listing-wrap ul li:nth-child(2) .team-list-wrap {
  position: relative;
  left: -13px;
  background: #56baec;
}

.team-listing-wrap ul li:nth-child(3) .team-list-wrap {
  position: relative;
  left: -23px;
  background: #b4d8e7;
}

.team-listing-wrap ul li:nth-child(4) .add-team-plus {
  position: relative;
  left: -32px;
}

.team-list-wrap img {
  width: 12px;
}

.add-team-plus {
  background: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  padding-top: 4px;
  border: 1px solid #394b84;
}

.add-team-plus img {
  width: 8px;
}

.team-perorty {
  color: #56baec;
}

.team-perorty img {
  width: 20px;
}

.status-icon-wrap {
  color: #2098d1;
}

.status-icon-wrap img {
  width: 20px;
}

.custom-radios div {
  display: inline-block;
}

.custom-radios input[type="checkbox"] {
  display: none;
}

.custom-radios input[type="checkbox"]+label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: var(--text-size);
}

.custom-radios input[type="checkbox"]+label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
  border: 1px solid #f1f4ff;
}

.custom-radios input[type="checkbox"]+label span img {
  opacity: 0;
  transition: all 0.3s ease;
}

.custom-radios input[type="checkbox"]#color-1+label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-2+label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-3+label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-4+label span {
  background-color: #fff;
}

.custom-radios input[type="checkbox"]#color-1:checked+label span {
  opacity: 1;
  background: url(../../../assets/arrow-checked.png) center center no-repeat;
  display: inline-block;
  background-size: 40%;
}

.custom-radios {
  text-align: center;
}

.add-button-project {}

.add-button-project button {
  background: none;
  border: none;
  outline: none;
}

.add-button-project button img {
  width: 18px;
  position: relative;
  top: -1px;
}

.project-main-button-wrap {}

.project-main-button-wrap .add-phase-button {}

.project-main-button-wrap .add-phase-button button {
  background: none;
  border: none;
  outline: none;
  font-size: var(--text-size);
  padding: 12px 0;
}

.project-main-button-wrap .add-phase-button button img {
  width: 20px;
  position: relative;
  top: -1px;
}

.project-phase-right-btn {}

.project-phase-right-btn ul {
  list-style: none;
  text-align: right;
}

.project-phase-right-btn ul li {
  display: inline-block;
}

.skip-button-wrap {
  background: none;
  border: none;
  outline: none;
  padding: 12px 30px;
  color: #000;
  font-size: var(--text-size);
}

.create-project-btn {
  background: var(--app-color-2);
  border: none;
  outline: none;
  padding: 12px 30px;
  color: #fff;
  font-size: var(--text-size);
  border-radius: 4px;
}

.create-project-btn:hover {
  background: var(--app-color-1);
}

.project-detail-top-heading-wrap {
  margin-bottom: 30px;
}

.project-detail-top-heading-wrap h4 {
  font-size: 20px;
  color: var(--font-color);
  padding-top: 8px;
}

.project-detail-top-heading-wrap h4 img {
  width: 25px;
  position: relative;
  top: -2px;
}

.project-detail-top-heading-wrap h4 .dot-button {
  border: 1px solid #f1f4ff;
  padding: 0px 12px;
  border-radius: 6px;
  margin-left: 5px;
}

.project-detail-top-heading-wrap h4 .dot-button img {
  width: 15px;
}

.projects-detail-heading-wrap .create-btn {
  padding: 8px 12px;
  font-size: var(--text-size);
  color: #fff;
  border: none;
  border-radius: 6px;
  background: var(--exotic-btn-color);
  outline: none;
  margin-left: 15px;
  position: relative;
  top: -3px;
  font-weight: var(--heading-weight);
}

.projects-detail-heading-wrap .create-btn-2 {
  padding: 8px 12px;
  font-size: var(--text-size);
  color: #fff;
  border: none;
  border-radius: 6px;
  background: var(--icon-color);
  outline: none;
  margin-left: 15px;
  position: relative;
  top: -3px;
  font-weight: var(--heading-weight);
}

.projects-detail-heading-wrap .create-btn-2:hover {
  background: var(--exotic-btn-color);
}

.projects-detail-heading-wrap .create-btn img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.detail-create-btn {
  top: 0 !important;
  display: flex;
  align-items: center;
  line-height: 0;
}

.projects-detail-heading-wrap {
  color: var(--font-color);
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
}

.projects-detail-heading-wrap .create-btn:hover {
  background: var(--icon-color);
}

.listing-top-filter-project {}

.listing-top-filter-project ul {
  list-style: none;
  text-align: right;
}

.listing-top-filter-project ul li {
  display: inline-block;
  margin-left: 10px;
}

.listing-top-filter-project ul li .form-group {
  position: relative;
}

.listing-top-filter-project ul li .form-group .form-control {
  height: 40px;
  box-shadow: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
  border-radius: 6px;
  padding-right: 35px;
}

.listing-top-filter-project ul li .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  background: none;
  border: none;
  outline: none;
}

.listing-top-filter-project ul li .form-group button img {
  width: 20px;
}

.listing-top-filter-project ul li a {
  padding: 8px 25px;
  border-radius: 6px;
  border: 1px solid #e9eeff;
  color: #394b87;
  text-decoration: none;
  font-size: var(--text-size);
}

.listing-top-filter-project ul li a:hover {
  border: 1px solid #94abff;
}

.listing-top-filter-project ul li a img {
  width: 18px;
  margin-right: 5px;
  position: relative;
}

.team-listing-wrap {}

.task-main-info-list ul {
  list-style: none;
  /* margin-bottom: 10px; */
}

.task-main-info-list ul li {
  display: inline-block;
  /* padding: 0 10px; */
  position: relative;
}

.task-main-info-list ul li a strong {
  font-weight: var(--text-font-weight);
}

.task-main-info-list ul li:nth-child(3) {
  padding-right: 4px;
}

.task-main-info-list ul li a {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.task-main-info-list ul li a span {
  color: var(--icon-color);
}

.task-main-info-list ul li .team-listing-wrap {}

.task-main-info-list ul li .team-listing-wrap ul li {
  padding: 0 !important;
}

.task-main-info-list ul li .team-listing-wrap ul li .team-list-wrap {
  padding-top: 0;
}

.task-main-info-list ul li .form-group {}

.task-main-info-list ul li .form-group .form-control {
  height: 35px;
  box-shadow: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
  width: 100px;
}

.task-main-info-list ul li img {
  width: 20px;
  position: relative;
  top: -2px;
}

.task-main-info-list ul li:before {
  /* content: "";
  top: 40%;
  position: absolute !important;
  left: -5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d1daf7; */
}

.task-main-info-list ul li:nth-child(1) {
  padding-left: 0;
}

.task-main-info-list ul li:nth-child(1):before {
  display: none;
}

.task-main-info-list ul li:nth-child(4):before {
  /* top: 14px; */
  left: 4px;
}

.task-main-info-list ul li .team-list-wrap img {
  width: 12px !important;
  top: 0;
}

.team-listing-wrap ul li:before {
  display: none;
}

.project-info-heading-wrap {
  border: 1px solid var(--divider-color);
  padding: 4px 4px 4px 4px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.project-info-heading-wrap h4 {
  font-size: 20px;
  color: #000;
}

.project-info-heading-wrap h4 span {
  font-weight: var(--text-font-weight);
}

.progress-bar-top {
  width: 100%;
  width: 300px !important;
}

.project-info-heading-wrap {}

.project-info-heading-wrap ul {
  list-style: none;
  margin-bottom: 0;
}

.project-info-heading-wrap ul li {
  display: inline-block;
  padding-right: 15px;
}

.project-info-heading-wrap ul li button {
  background: none;
  border: none;
  outline: none;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
}

.right-listi-wrap {
  text-align: right;
}

.right-listi-wrap li {}

.right-listi-wrap li .form-group {}

.ag-center-cols-container {
  background-color: var(--card-color);
}

.right-listi-wrap li .form-group .form-control {
  height: 35px;
  box-shadow: none;
  outline: none;
  border: 1px solid #e9eeff;
  font-size: 13px;
  appearance: auto;
  border-radius: 0px;
}

.right-listi-wrap li button {
  background: none;
  outline: none;
  border: none;
  outline: none;
  color: var(--selected-route-color-svg) !important;
  font-weight: 500 !important;
}

.right-listi-wrap li button img {
  width: 20px;
  position: relative;
  top: -1px;
}

.left-listing-wrap {
  margin-top: 4px;
}

.caret-img-proj-detail {
  width: 35px;
  margin-bottom: 3px;
}

.project-details-task-table-flatpickr-container {
  background: #fff;
}

.project-details-task-table-flatpickr-container .flatpickr-day.selected {
  background-color: var(--app-color-1) !important;
  border: 1px solid var(--app-color-1) !important;
}

.project-details-task-table-flatpickr-container .flatpickr-weekday {
  color: white !important;
}

.project-details-task-table-flatpickr-container .flatpickr-weekdays {
  background-color: var(--app-color-1) !important;
}

.project-details-task-table-flatpickr-container .flatpickr-month {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-task-table-flatpickr-container .flatpickr-monthDropdown-months {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-task-table-flatpickr-container .flatpickr-prev-month,
.flatpickr-next-month {
  color: var(--app-color-1) !important;
}

.project-details-main-date-filter input {
  border: none !important;
  width: 100px !important;
  padding: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  cursor: pointer;
  background: inherit;
}

.project-details-main-date-filter input:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.project-details-main-date-filter svg {
  color: var(--icon-color);
  margin-bottom: 4px;
  margin-right: 4px;
}

.project-details-main-date-filter .flatpickr-day.selected,
.custom-date-picker-dropdown .flatpickr-day.selected {
  background-color: var(--app-color-1) !important;
  border: 1px solid var(--app-color-1) !important;
}

.project-details-main-date-filter .flatpickr-weekday,
.custom-date-picker-dropdown .flatpickr-weekday {
  color: white !important;
}

.project-details-main-date-filter .flatpickr-weekdays,
.custom-date-picker-dropdown .flatpickr-weekdays {
  background-color: var(--app-color-1) !important;
}

.project-details-main-date-filter .flatpickr-month,
.custom-date-picker-dropdown .flatpickr-month {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-main-date-filter .flatpickr-monthDropdown-months,
.custom-date-picker-dropdown .flatpickr-monthDropdown-months {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-main-date-filter .flatpickr-prev-month,
.flatpickr-next-month {
  color: var(--app-color-1) !important;
}

/* project details timeline date styling */
.project-details-main-timeline-date-filter input {
  border: none !important;
  width: 200px !important;
  padding: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  background-color: var(--card-color);
  cursor: pointer;
}

.project-details-main-timeline-date-filter input:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.project-details-main-timeline-date-filter svg {
  color: var(--app-color-1);
  margin-bottom: 4px;
  margin-right: 4px;
}

.project-details-main-timeline-date-filter .flatpickr-day.selected {
  background-color: var(--app-color-1) !important;
  border: 1px solid var(--app-color-1) !important;
}

.project-details-main-timeline-date-filter .flatpickr-weekday {
  color: white !important;
}

.project-details-main-timeline-date-filter .flatpickr-weekdays {
  background-color: var(--app-color-1) !important;
}

.project-details-main-timeline-date-filter .flatpickr-month {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-main-timeline-date-filter .flatpickr-monthDropdown-months {
  background-color: var(--app-color-1) !important;
  color: white !important;
}

.project-details-main-timeline-date-filter .flatpickr-prev-month,
.flatpickr-next-month {
  color: var(--app-color-1) !important;
}

.project-detail-form-control {
  width: 400px !important;
  font-size: var(--text-size) !important;
  font-weight: var(--text-font-weight) !important;
}

.projects-detail-heading-wrap span a:last-child {
  color: var(--icon-color);
}

.projects-detail-heading-wrap span a:hover {
  color: var(--icon-color);
}

.no-task-detail-user-list-margin {
  margin-left: 0 !important;
}

.user-permission-modules-dropdown {
  z-index: 999999 !important;
  background-color: var(--card-color) !important;
  border: 1px solid var(--list-menu-dropdown-border-color);
  color: var(--font-color) !important;
}

.drive-icon {
  cursor: pointer;
}

.drive-icon p {
  margin-bottom: 0px !important;
}

.drive-icon svg {
  color: var(--icon-color);
  width: 12px;
  height: 12px;
}

.drive-canvas-modal {
  min-width: 1200px;
}

.file-wrap-container {
  height: 175px;
  border-radius: 6px;
  border: 1px solid #f1f4ff;
  padding: 20px;
  cursor: pointer;
}

.file-wrap-container:hover {
  background-color: var(--app-color-1);
}

.file-wrap-container:hover p {
  color: #fff;
}

.file-wrap-container p {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  margin-bottom: 0 !important;
}

.no-attachment-txt {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  margin-bottom: 0 !important;
}

.drive-header {
  display: flex;
  justify-content: space-between;
}

.file-view-txt {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
  margin-bottom: 0 !important;
}

.file-view-txt span {
  font-weight: var(--text-font-weight);
}

.refresh-text {
  color: var(--font-color);
  font-size: var(--text-size);
  cursor: pointer;
}

.enter-span {
  color: var(--app-color-1);
  flex-shrink: 0;
}

.enter-span svg {
  color: var(--app-color-1);
}

.file-container {
  margin-bottom: 25px;
}

.file-viewer-modal .modal-content {
  background: transparent !important;
  border: none !important;
  min-height: 100vh;
}

.file-viewer-modal .modal-dialog {
  max-width: 100% !important;
  min-height: 100vh;
}

.file-viewer-modal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-viewer-modal .pg-viewer-wrapper {
  overflow: hidden !important;
}

.file-viewer-modal .modal-header {
  border-bottom: 0px !important;
}

.task-filter-select .select__placeholder {
  font-size: var(--text-size) !important;
  color: var(--font-color) !important;
}

.task-filter-select__filter:hover .select__placeholder {
  /* font-size: var(--text-size) !important; */
  /* color: var(--app-color-2) !important; */
  /* font-weight: 600 !important;  */
}

.proj-detail-header-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.drive-icon div:first-child {
  display: flex;
  border: 1px solid var(--divider-color);
  background: var(--search-bar-bg);
  justify-content: center;
  width: 24px;
  height: 24px;
  align-items: center;
  color: var(--icon-color);
}

.plan-project-btn-icon svg {
  color: var(--icon-color);
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
}

.drive-shimmer-wrap .grid-column-3 {
  grid-template-columns: auto auto auto auto;
  width: 100%;
}

.proj-detail-header-icon {
  width: 25px;
  margin-right: 5px;
  border-radius: 50%;
  object-fit: cover;
  max-width: 25px;
  max-height: 25px;
}

.project-detail-info-laptop {
  display: flex;
}

.project-detail-info-tablet,
.project-detail-info-mobile {
  display: none;
}

.projects-detail-heading-wrap .create-btn {
  min-height: 25px;
}

.phase-select-dropdown .form-group .form-control {
  appearance: none;
  font-size: 16px;
  font-weight: var(--text-font-weight);
  border: none;
  padding-left: 14px;
  padding-right: 25px;
  background: var(--card-color);
  color: var(--font-color);
}

.phase-select-dropdown .form-group .form-control option {
  font-size: 14px;
  /* For dropdown options */
}

.phase-select-dropdown .form-group {
  border: 1px solid var(--divider-color);
  border-radius: 6px;
  width: 100%;
  position: relative;
}



.custom-date-picker-dropdown {
  /* min-width: 100% !important; */
  border-radius: 5px !important;
  top: 45px;
  left: -10px;
  height: auto;
  overflow: hidden;
  border: none !important;
  box-shadow: 0px 4px 20.8px 0px rgba(0, 0, 0, 0.14);
}

.custom-date-picker-dropdown-shortcuts {
  padding: 14px 18px;
  background: #f1f3fc;
  display: flex;
  flex-direction: column;
}

.custom-date-picker-dropdown-shortcuts .btn-link:hover,
.selected-shortcut {
  color: var(--app-color-2) !important;
  font-weight: 600 !important;
}

.custom-date-picker-dropdown-date-container {
  padding: 14px 18px;
}

.custom-date-picker-dropdown .flatpickr-input[readonly] {
  display: none;
}

.custom-date-picker-dropdown .flatpickr-calendar.inline {
  box-shadow: none;
}

.custom-date-picker-dropdown .flatpickr-wrapper {
  background: var(--app-color-1);
}

.custom-date-picker-dropdown-date-container .css-1chuxo2-MuiPickersCalendarHeader-label {
  font-size: var(--text-size);
  font-family: "poppins", sans-serif;
}

.custom-date-picker-dropdown-date-container .css-10qtiir-MuiPickersToolbar-content {
  display: none;
}

.custom-date-picker-dropdown-date-container .css-13cnpq9-MuiPickersLayout-root .MuiPickersLayout-toolbar {
  padding: 0;
  display: none;
}

.custom-date-picker-dropdown-date-container .css-13cnpq9-MuiPickersLayout-root .MuiPickersLayout-toolbar span {
  color: var(--app-color-2);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 600;
  line-height: 142.1%;
  /* 12.789px */
  font-family: "Poppins", sans-serif;
}

.custom-date-picker-dropdown-date-container .css-iupya1-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  display: none;
}

.custom-date-select-label {
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  align-items: center;
}

.custom-date-select-label span {
  color: var(--app-color-2);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 600;
  line-height: 142.1%;
  /* 12.789px */
}

.custom-date-picker-dropdown-date-container .css-1nxbkmn-MuiPickersCalendarHeader-root {
  padding: 0;
}

.custom-date-picker-dropdown-date-container .css-4k4mmf-MuiButtonBase-root-MuiPickersDay-root,
.custom-date-picker-dropdown-date-container .css-17f9e7e-MuiTypography-root-MuiDayCalendar-weekDayLabel,
.css-144rw37,
.css-1om332n {
  font-size: var(--text-size) !important;
  font-family: "Poppins", sans-serif;
}

.custom-date-picker-dropdown-date-container .css-1pv2de5-MuiDayCalendar-weekContainer,
.custom-date-picker-dropdown-date-container .css-1rl1vrc-MuiDayCalendar-header,
.css-1bm6bgu {
  justify-content: space-between;
}

.custom-date-picker-dropdown-date-container .css-1rl1vrc-MuiDayCalendar-header:first-child span,
.custom-date-picker-dropdown-date-container .css-1bm6bgu:first-child span {
  color: var(--app-color-2);
  font-weight: 500;
}

.custom-date-picker-dropdown-shortcuts button {
  color: rgba(0, 0, 0, 0.7);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 400;
  line-height: 142.1%;
  /* 12.789px */
  text-decoration: none;
  height: 50px;
  width: 200px;
}

.custom-date-picker-dropdown-shortcuts button:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

@media (max-width: 540px) {
  .project-main-head-wrap h3 {
    gap: 10px;
  }
}

@media (max-width: 570px) {
  .project-phase-right-btn {
    margin-top: 10px;
  }

  .project-phase-right-btn ul {
    text-align: start;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 506px) {
  .project-phase-right-btn ul li button {
    width: 100%;
  }
}

@media (max-width: 650px) {

  .project-detail-info-laptop,
  .project-detail-info-tablet {
    display: none !important;
  }

  .project-detail-info-mobile {
    display: flex;
    margin-left: 1rem;
  }
}

@media (max-width: 700px) {
  .project-detail-main-search-section {
    margin-left: 1.5rem !important;
    margin-top: 5px !important;
  }
}

@media (max-width: 637px) {
  .project-detail-main-search-section {
    width: 100%;
  }

  .project-detail-main-search-section ul li {
    width: 100% !important;
  }

  .project-detail-main-search-section ul li:not(:first-child) {
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media (max-width: 1304px) {
  .offconcave-phase-detail .btn-role-concave {
    top: 2px;
    left: 2px;
    border-radius: 4px;
  }
}

@media (max-width: 1280px) {
  .project-detail-info-laptop {
    display: none;
  }

  .project-detail-info-tablet {
    display: flex;
  }
}

@media (max-width: 1352px) {
  .project-main-head-wrap {
    gap: 20px;
  }

  .project-detail-main-search-section {
    /* margin-top: 20px; */
    margin-left: 1rem;
  }
}

@media (max-width: 400px) {
  .progress-bar-top {
    width: 200px !important;
  }
}