.staff-summary-main-wraper {
  border-radius: 20px 0px 0px 20px;
  background: var(--card-color);
  padding: 25px;
}
.staff-summary-main-wraper h5 {
  font-size: 18px;
  color: var(--font-color);
  font-weight: 500;
  margin-bottom: 15px;
}
.staff-summary-main-wraper ul {
  list-style: none;
  margin: 0 -5px;
}
.staff-summary-main-wraper ul li {
  padding: 0 5px;
}
.staff-summary-main-wraper ul li .listing-staff-summary-wrap {
  border: 1px solid var(--border-color);
  padding: 15px;
  border-radius: 5px;
  height: 100%;
  transition: transform 1s ease;
  background-color: #f6f7fb;
}
.listing-staff-summary-wrap:hover {
  /* background-color: var(--selected-route-color) !important; */
}
.listing-staff-summary-top .listing-staff-profile-image img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  float: left;
  object-fit: cover;
}
.listing-staff-summary-top {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.listing-staff-profile-data {
  flex-wrap: nowrap;
}

.listing-staff-profile-name {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.listing-staff-profile-text {
  font-size: var(--text-font-size);
  color: var(--font-color);
  font-weight: 400;
  border-radius: 8px;
  border: 0.7px solid var(--divider-color);
  background: var(--search-bar-bg);
  min-width: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listing-staff-profile-text span {
  float: right;
  color: var(--app-color-1);
}
.total-staff-summary-task {
  padding: 12px 0 0 0;
}
.total-staff-summary-task h2 {
  font-size: 16px;
  font-weight: 300;
  position: relative;
  color: var(--font-color);
}
.total-staff-summary-task h2 strong {
  font-weight: 500;
}
.total-staff-summary-task h2 span {
  color: var(--icon-color);
  font-weight: 600;
}
.total-staff-summary-task-list-url {
  border: 1px solid var(--icon-color);
  padding: 8px 18px;
  border-radius: 6px;
  font-size: 13px;
  color: var(--selected-route-color-svg);
  font-weight: 500;
}
.total-staff-summary-task-list-url:hover {
  background: var(--icon-color);
  color: var(--font-color);
}
.total-staff-summary-task-list-url:hover img {
  filter: brightness(0) invert(1);
}
.total-staff-summary-task-list-url img {
  position: relative;
  top: -2px;
}
.task-info-list-wrap {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 8px;
  height: 100%;
}
.task-info-list-icon {
  width: 40px;
  height: 40px;
  float: left;
}
.task-info-list-icon img {
  width: 100%;
}
.task-info-list-text {
  width: 70%;
  float: left;
  padding-left: 5px;
  line-height: 19px;
}
.task-info-list-text span {
  display: block;
  font-size: 12px;
  color: var(--icon-color);
}
.task-info-list-text strong {
  display: block;
  color: #2098d1;
  font-size: 22px;
}
.second-color {
  color: #eca944 !important;
}
.task-info-list-text strong img {
  position: relative;
  top: -2px;
}
.no-padding {
  padding-right: 0;
}
.total-staff-summary-task-total-count-section {
  margin-bottom: 12px;
}
.staff-summery-card {
  margin-bottom: 10px;
}

.performance-down-modal {
  width: 100%;
  max-width: 500px !important;
}
.performance-down-modal .modal-content {
  border-radius: 24px !important;
  width: 500px;
}
.performance-down-modal-report-btn-active {
  border-radius: 4px;
  border: 1px solid var(--icon-color);
  background: var(--icon-color);
  color: #fff;
  font-size: var(--text-font-size);
  font-style: normal;
  font-weight: 500;
  padding: 5px 20px;
}

.performance-down-modal-report-btn {
  border-radius: 4px;
  border: 1px solid var(--divider-color);
  background: var(--search-bar-bg);
  color: var(--font-color);
  font-size: var(--text-font-size);
  font-style: normal;
  font-weight: 300;
  padding: 5px 20px;
}

.team-summery-list-type-switch {
  height: 42px;
}

.team-summery-list-type-switch .btn-check:checked + .btn {
  background-color: var(--app-color-1);
  border-color: var(--divider-color);
}

.team-summery-list-type-switch .btn {
  border-color: var(--divider-color);
  background-color: var(--card-color);
}

.team-summery-list-type-switch .btn-check:checked + .btn svg {
  color: #fff;
}

.team-summery-list-type-switch svg {
  color: #c2c2c2;
}

.staff-summery-performance-cell {
  background: #e4f3f9;
}

.staff-summery-performance-cell-down {
  background: #ffecec;
}

.staff-summery-score-cell {
  background: #fdf5e9;
}

.staff-summery-obtained-score-cell {
  background: #e6f7ec;
}

.up-trend-text {
  color: #2098d1 !important;
  font-weight: 600 !important;
}

.down-trend-text {
  color: rgb(255, 99, 99) !important;
  font-weight: 600 !important;
}

.total-points-text {
  color: #eca944 !important;
  font-weight: 600 !important;
}

.obtained-points-text {
  color: #2dbf64 !important;
  font-weight: 600 !important;
}

.obtained-points-text-300 {
  color: #2dbf64 !important;
}

.summary-list-username-cell {
  cursor: pointer;
}

.performance-default-tag {
  border-radius: 6px;
  border: 1px solid #dee2f1;
  background: #fff;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  padding: 5px 10px;
}

.performance-default-tag svg {
  color: var(--app-color-1);
  margin-bottom: 2px;
}
