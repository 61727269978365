/* CSS leads */

.leads-table-main-wraper {
  padding: 30px;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
}
.left-right-dashboard-wraper .no-pad-container {
  padding-right: 0;
}
.lead-heading-btn h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 0px !important;
  font-weight: 500;
}
.lead-heading-btn h2 img {
  position: relative;
  top: -2px;
}
.btn-leads-cta-wrap {
  text-align: right;
}
.btn-leads-cta-wrap a {
  border: 1px solid #e9eeff;
  padding: 12px 24px;
  color: #333;
  font-weight: bold;
  font-size: var(--text-size);
  margin-left: 15px;
  border-radius: 6px;
  text-decoration: none;
}
.btn-leads-cta-wrap a img {
  width: 18px;
  margin-right: 5px;
}
.leads-table-wrap {
  margin-top: 20px;
}
.leads-table-wrap .table-responsive {
}
.leads-table-wrap .table-responsive .table {
  border: 1px solid #f1f4ff;
  margin-bottom: 0;
  min-width: 767px;
}
.leads-table-wrap .table-responsive .table tr td {
  border: 1px solid #f1f4ff !important;
  font-size: 13px !important;
  color: #333 !important;
  vertical-align: middle !important;
  padding: 8px;
}
.heading-table-wrap td {
  padding: 10px 15px !important;
}
.heading-table-wrap td strong {
  color: #333 !important;
}
.status-table1 {
  background: #ece1d3;
}
.status-table2 {
  background: #fffff0;
}
.status-table3 {
  background: #def2ef;
}
.status-table4 {
  background: #fff7c2;
}
.status-table5 {
  background: #ede7de;
}
.status-table6 {
  background: #fffce6;
}
.status-table7 {
  background: #fcebeb;
}
.status-table8 {
  background: #dbdef0;
}

.leads-table-main-wraper .btn-action a {
  height: 24px;
  padding-top: 2px;
}
.record-pagination-wraper {
  margin-top: 15px;
}
.record-pagination-wraper .record-wrap {
  font-size: var(--text-size);
  color: #000000;
  margin-top: 20px;
}
.record-pagination-wraper .record-wrap input {
  padding: 8px 15px;
  width: 43.48px;
  color: #717689;
  border-radius: 6px;
  font-size: 12px;
  background: #e9eeff;
  margin-left: 5px;
  border: none;
  height: 30px;
}
.record-pagination-wraper .record-wrap input:focus {
  border: none;
  outline: none;
}
.pagination-main-wrap {
  text-align: right;
  margin-top: 15px;
}
.pagination-main-wrap a,
.page-item a,
.page-link {
  font-family: "Poppins", sans-serif !important;
  display: flex !important;
  width: 30px !important;
  height: 30px !important;
  border: 1px solid #f1f4ff !important;
  color: #000000 !important;
  font-weight: var(--text-font-weight) !important;
  font-size: var(--text-size) !important;
  text-decoration: none !important;
  padding-top: 5px !important;
  justify-content: center !important;
  align-items: center !important;
}

.page-link:hover {
  background-color: var(--app-color-2) !important;
  color: #fff !important;
}

.dashboard-area-wraper {
  margin-bottom: 10px !important;
}
// .pagination {
//   margin-top: 15px !important;
//   margin-bottom: 0px !important;
// }

.active > .page-link {
  background-color: var(--app-color-2) !important;
  color: #fff !important;
}

.lead-pgi-right {
  display: flex;
  justify-content: end;
}

.page-link {
  border-radius: 0px !important;
}

/* AG Table library*/
.ag-root-wrapper {
  border: none !important;
}

.ag-body-viewport {
  border-left: 1px solid var(--divider-color);
  border-top: 1px solid var(--divider-color);  
  border-right: 1px solid var(--divider-color);
  // border: none !important;
}

.project-timeline .ag-body-viewport{
  // border: 1px solid var(--divider-color) !important;
}

.ag-ltr .ag-cell {
  // border: none !important;
  border-right: 1px solid var(--divider-color) !important;
  color: var(--font-color) !important;
  font-size: var(--text-size) !important;
  padding: 0px 15px !important;
  vertical-align: middle !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 0 !important;
  display: flex;
  flex-direction: column;
  font-weight: var(--text-font-weight);
  /* align-items: baseline; */
  justify-content: center;
  /* border-right-width: 1px; */
}

.ag-header {
  // max-height: 36.5px !important;
  // min-height: 36.5px !important;
  background-color: var(--card-color) !important;
  font-family: "Roboto", sans-serif !important;
  border-bottom: none !important;
  border: 1px solid var(--divider-color);
}

.ag-row {
  border: none !important;
  border-bottom: 1px solid var(--divider-color) !important;
  background-color: var(--table-row);
}

.ag-row-odd {
  background-color: var(--table-row);
}

.selected-project-row {
  background-color: var(--selected-route-color) !important;
  // border-top: 1px solid var(--app-color-1);
  // border-bottom: 1px solid var(--app-color-1);
}

// .selected-hovered-row,
// .ag-row:hover {
//   background-color: #f4f6ff !important;
//   border-top: 1px solid #94abff !important;
//   border-bottom: 1px solid #94abff !important;
// }
.selected-hovered-row {
  background-color: var(--selected-route-color) !important;
  border-top: 1px solid #94abff !important;
  border-bottom: 1px solid #94abff !important;
}

.ag-header-cell {
  padding-left: 15px !important;
  // max-height: 36.5px !important;
  // min-height: 36.5px !important;
}

.ag-header-cell-text {
  color: var(--exotic-font-color) !important;
  font-size: var(--text-size);
  font-family: "Poppins", sans-serif;
  font-weight: var(--heading-weigth);
}

.ag-ltr .ag-header-cell-resize {
  right: -5px !important;
}

.ag-header-cell-resize::after {
  background-color: var(--divider-color) !important;
  height: 36.5px !important;
  top: 0 !important;
}

.ag-body-horizontal-scroll-viewport {
  // display: none;
  overflow-x: auto;
}

.ag-watermark {
  display: none !important;
}

.add-lead-new {
  height: 45px;
  border-left: 1px solid var(--divider-color);
  border-bottom: 1px solid var(--divider-color);
  border-right: 1px solid var(--divider-color);
  display: flex;
  align-items: center;
  padding: 0px 8px;
}
.add-lead-new input::placeholder {
  color: var(--font-color);
}
.add-lead-new input:focus {
  background-color: var(--search-bar-bg);
  color: var(--font-color);
}
.add-lead-new input {
  border: 1px solid var(--divider-color);
  font-size: var(--text-size);
  height: 35px;
  border-radius: 4px;
  color: var(--font-color);
  background-color: var(--search-bar-bg);
  max-width: 310px;
}

.add-lead-new input:hover {
  border: 1px solid var(--divider-color);
}

.control-select-indicator {
  top: 0px !important;
  width: 20px !important;
  height: 20px !important;
  background: var(--border-color);
  border: 1px solid var(--border-color);
}

.control-select-checkbox {
  margin-bottom: 23px;
  z-index: 1;
}

.control-select-checkbox .control__indicator:after {
  top: 4px;
  left: 8px;
}

.add-lead-new input:focus {
  // outline: none;
  // border: 1px solid #f1f4ff;
}

.required-fields-span {
  color: #ff0000;
}

@media (max-width: 500px) {
  .btn-leads-cta-wrap {
    text-align: center !important;
    margin-top: 15px;
  }
}

@media (max-width: 380px) {
  .btn-leads-cta-wrap {
    display: flex;
    flex-direction: column;
  }

  .btn-leads-cta-wrap a {
    margin-left: 0px;
    margin-top: 10px;
  }
}
