.mobile-app-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap; /* Prevents wrapping */
  gap: 10px;
  padding: 5px 1rem; /* Reduced padding for better shrinking */
  background-color: #fff;
  height: 60px;
  overflow: hidden; /* Prevents content overflow */
}

.mobile-app-banner span {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  flex-grow: 1; /* Allows shrinking */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds "..." when shrinking too much */
}

.mobile-app-banner button {
  border-radius: 6px;
  border: 1px solid var(--app-color-1);
  background: var(--app-color-1);
  font-size: var(--text-size);
  color: #fff;
  width: 100px;
  height: 40px;
  flex-shrink: 1; /* Allows button to shrink */
  min-width: 70px; /* Prevents button from disappearing completely */
}

.mobile-app-banner button:hover {
  border: 1px solid var(--app-color-2);
  background: var(--app-color-2);
}

/* Shrink everything proportionally on smaller screens */
@media (max-width: 480px) {
  .mobile-app-banner span {
    font-size: calc(var(--text-size) - 3px); /* Shrink text */
  }

  .mobile-app-banner button {
    font-size: calc(var(--text-size) - 2px);
    width: 80px; /* Reduce button width */
    height: 35px; /* Reduce button height */
  }
}

@media (max-width: 360px) {
  .mobile-app-banner span {
    font-size: calc(var(--text-size) - 5px);
  }

  .mobile-app-banner button {
    font-size: calc(var(--text-size) - 4px);
    width: 70px;
    height: 30px;
  }
}
