.down-grade-popup-wraper {
  padding: 25px;
}
.down-grade-popup-wraper h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}
.down-grade-popup-wraper p {
  font-size: 13px;
}
.search-down-grade-wrap {
  margin-top: 30px;
}

.select-user-body-wrapper {
  padding-left: 22px;
  padding-right: 22px;
  margin-bottom: 1rem;
}

.search-down-grade-wrap .search-down-field {
}
.search-down-grade-wrap .search-down-field .form-control {
  height: 42px;
  background: #f1f4ff;
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: var(--text-size);
  outline: none;
  font-weight: var(--text-font-weight);
}
.select-seat-wraper {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  margin-top: 10px;
  text-align: right;
}
.select-seat-wraper img {
  width: 15px;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.seat-limit-exceed {
  color: #f00;
}

.user-select-modal .modal-content {
  max-width: 750px !important;
}

.select-user-para p {
  margin-top: 15px;
  margin-bottom: 0;
}

.funkyradio-user label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #e9eeff;
  font-weight: var(--text-font-weight);
  line-height: 16px !important;
  padding: 15px;
}

.funkyradio-user input[type="radio"]:empty,
.funkyradio-user input[type="checkbox"]:empty {
  display: none;
}

.funkyradio-user input[type="radio"]:empty ~ label,
.funkyradio-user input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  margin-top: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: var(--text-size);
}

.funkyradio-user input[type="radio"]:empty ~ label:before,
.funkyradio-user input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 17px;
  bottom: 0;
  left: 20px;
  content: "";
  width: 30px;
  height: 30px;
  background: #f1f4ff;
  border-radius: 6px;
  padding-top: 7px;
}

.funkyradio-user input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio-user input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
  font-size: var(--text-size);
}

.funkyradio-user input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio-user input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: "\2714";
  text-indent: 10px;
  color: #c2c2c2;
}

.funkyradio-user input[type="radio"]:checked ~ label,
.funkyradio-user input[type="checkbox"]:checked ~ label {
  color: #333;
  font-size: var(--text-size);
}

.funkyradio-user input[type="radio"]:checked ~ label:before,
.funkyradio-user input[type="checkbox"]:checked ~ label:before {
  content: "\2714";
  text-indent: 10px;
  color: #333;
  background-color: #ccc;
}

.funkyradio-user input[type="radio"]:focus ~ label:before,
.funkyradio-user input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-default-user input[type="radio"]:checked ~ label:before,
.funkyradio-default-user input[type="checkbox"]:checked ~ label:before {
  color: #333;
  background-color: #e9eeff;
}

.funkyradio-user input[type="checkbox"]:empty ~ label a {
  display: block;
  color: #94abff;
}

.selected-user-final {
  padding: 5px 0px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: #f1f4ff; */
}

.selected-user-final-row {
  margin-top: 20px !important;
}

.selected-user-final .employee-box-wraper {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  padding: 10px;
  background-color: #f1f4ff;
}

.proceed-btn-wrap button {
  margin-top: 15px !important;
}

.employee-listing-wraper {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
