.notification-main-wraper {
  float: right;
  width: 100%;
  background: #fff;
  /* padding: 25px 35px; */
}

.notification-offcanvas-main .offcanvas-body {
  padding: 0px;
}

.notification-header-wrap {
  padding: 25px 30px 0px 30px;
  background: var(--card-color);
  box-shadow: 0px 4px 15.1px 0px var(--divider-color);
  position: sticky;
  top: 0;
  overflow-x: hidden;
}

.notification-type-tabs {
  border-bottom: none !important;
  flex-wrap: nowrap !important;
}

.notification-main-wraper h3 {
  font-weight: 500;
  color: var(--font-color);
}

.notification-section-header-text-container {
  margin-bottom: 15px;
}

.mark-all-read-text {
  color: var(--icon-color);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.1%; /* 17.052px */
  text-decoration-line: underline;
  cursor: pointer;
}

.notification-main-wraper h3 a {
  float: right;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  top: 11px;
  color: var(--app-color-1);
}

.notification-main-wraper ul button {
  border: none !important;
  outline: none !important;
  padding: 8px 25px;
  color: var(--font-color);
}
.nav-tabs .nav-item.show .nav-link {
  border: none;
  border-bottom: 3px solid var(--app-color-1) !important;
  color: var(--app-color-1);
}
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 3px solid var(--icon-color2) !important;
  background-color: var(--icon-color);
  color: white;
}

.nav-tabs .nav-link.active span {
  color: white;
}
.search-notify-main-tab-wrap {
  padding: 20px 0;
  background-color: var(--card-color);
}
.search-notify-main-tab-wrap .form-group {
  position: relative;
}
.search-notify-main-tab-wrap .form-group .form-control {
  height: 40px;
  box-shadow: none;
  border: 1px solid #ccc;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
.search-notify-main-tab-wrap .form-group button {
  border: none;
  background: none;
  outline: none;
  position: absolute;
  right: 6px;
  top: 5px;
}

.notification-list-container {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.listing-notification-wraper ul {
  list-style: none;
  padding-left: 0 !important;
}
.listing-notification-wraper ul li {
  /* background: #a7abb76b; */
  padding: 8px 22.5px 8px 22.5px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
}

.listing-notification-wraper ul li:not(:first-child) {
}

.read-notification {
  /* background: #e9eeff !important; */
}

.notification-date {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #9d9d9d;
}

.lisitng-noftt-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lisitng-noftt-wrap .lisitng-noftt-icon {
  float: left;
}
.lisitng-noftt-wrap .lisitng-noftt-icon img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}
.listing-notify-wrap {
  padding-left: 15px;
  float: left;
}
.listing-notify-wrap h5 {
  font-weight: 400;
  font-size: 16px;
  color: var(--font-color);
  line-height: normal;
  letter-spacing: -0.18px;
  margin-bottom: 2px;
}
.unread-notification-container h5 {
  font-weight: 500;
}
.listing-notify-wrap p {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  margin-bottom: 0;
  color: #949cb6;
}
.unread-notification-container p {
  color: var(--font-color);
}

.notification-pagination-container {
  position: sticky;
  bottom: 0;
  background: var(--card-color);
  box-shadow: 0px -4px 15.1px 0px rgba(0, 0, 0, 0.07);
}
 .nav-tabs .nav-link:hover{
  /* color: var(--font-color);
  background-color: var(--icon-color); */
}