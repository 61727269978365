:root {
  --app-color-1-rgb: 148, 171, 255;
}

.date-time-picker {
  max-width: 600px;
  margin: 0 auto;
  font-family: inherit;
}

.picker-toggle {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  background-color: var(--app-color-1);
  border: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.15s ease;
}

.picker-toggle:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  filter: brightness(110%);
}

.picker-dropdown {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 0.9rem;
  margin-top: 6px;
  font-size: 16px !important;
  position: absolute;
  z-index: 999999;
}

.date-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.selected-date {
  font-size: 20px;
  color: #4a4a4a;
  // font-weight: 500;
}

.picker-content {
  display: flex;
  gap: 1.5rem;
}

.calendar-container {
  flex: 2;
}

.time-container {
  flex: 1;
}

.time-slots {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 249px;
  min-width: 120px;
  overflow-y: auto;
  padding-right: 10px;
}

.time-slots-mobile {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  min-width: 120px;
  overflow-x: auto;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 30px;
  width: 100%;
}

.time-slot-mobile {
  width: max-content;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.15s ease;
  border: 1px solid #e5ebff !important;
  color: var(--app-color-2);
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth transition */
}

.time-slot-mobile.active {
  background-color: var(--app-color-1);
  color: white;
}

.time-slot {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.15s ease;
  border: 1px solid #e5ebff !important;
  color: var(--app-color-2);
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth transition */
}

.time-slot:hover {
  background-color: #e5ebff;
  color: var(--app-color-1);
}

.time-slot.active {
  background-color: var(--app-color-1);
  color: white;
}

/* Custom scrollbar for time slots */
.time-slots::-webkit-scrollbar {
  width: 6px;
}

.time-slots::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.time-slots::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.time-slots::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom styles for react-calendar */
.react-calendar {
  border: none !important;
  border-radius: 8px;
  padding: 10px;
  min-width: 350px;
}

.react-calender-mobile {
  width: 100% !important;
  margin-top: 10px;
}

.react-calendar__tile {
  padding: 10px;
  border-radius: 6px;
  transition: all 0.15s ease;
}

.react-calendar__tile:hover {
  background-color: rgba(var(--app-color-1-rgb), 0.1);
}

.react-calendar__tile--active {
  background: var(--app-color-1) !important;
  color: white !important;
}

.react-calendar__tile--now {
  background: #f8f9fa;
  font-weight: bold;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 1.2rem;
  color: #4a4a4a;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: rgba(var(--app-color-1-rgb), 0.1);
  border-radius: 6px;
}

.react-calendar__month-view__weekdays__weekday {
  color: black;
  font-weight: 500;
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(var(--app-color-1-rgb), 0.2);
}

.react-calendar__tile--hasActive {
  background: var(--app-color-1);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--app-color-1);
}

@media (max-width: 768px) {
  .picker-content {
    flex-direction: column;
  }

  .time-container {
    margin-top: 1rem;
  }

  .time-slots {
    max-height: 200px;
  }
}

.picker-bottom {
  transform: translateY(0);
}

.picker-top {
  transform: translateY(-100%);
}

.picker-bottom {
  transform: translateY(0);
}

.picker-top {
  transform: translateY(3%);
}

.picker-center {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(-100%, -3%);
  z-index: 1050;
}

.clear-date-modal-btn {
  border-radius: 4px;
  border: 1px solid #ff0000;
  color: #ff0000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-size);
  padding: 5px 10px;
}

.clear-date-modal-btn:hover {
  background-color: #ff0000;
  color: #fff;
}
