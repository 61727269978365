.chat-sidebar {
  width: 100%;
  padding-left: 10px;
}

.left-nav-main-collapse .chat-sidebar {
  padding-left: 0;
}

.chat-sidebar h2 {
  font-weight: var(--heading-weigth) !important;
  font-size: var(--text-size);
  display: block;
  color: var(--font-color);
  margin-top: 5px;
  text-transform: uppercase;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  background: var(--search-bar-bg);
}

.search-bar input {
  width: 100%;
  border: none;
  outline: none;
  font-size: var(--text-size);
  background: var(--search-bar-bg);
  color: var(--font-color);
}

.search-icon {
  color: #888;
  margin-left: 8px;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
  /* max-height: 490px;
  overflow-y: auto; */
  scroll-behavior: smooth;
}

/* .user-list:hover {
  overflow-y: auto;
} */

.chat-user-wrap {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  cursor: pointer;
}

.chat-user-wrap:hover .user-name {
  color: var(--icon-color);
}

.chat-user-wrap:last-child {
  border-bottom: none;
}

.user-img {
  width: 35px !important;
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  overflow: hidden;
  gap: 8px;
}

.user-name {
  font-weight: var(--text-font-weight);
  font-size: 13px;
  margin-bottom: 0;
  color: var(--font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: calc(100% - 60px); /* Leave space for user-time */
}

.user-time {
  font-size: 13px;
  font-weight: var(--text-font-weight);
  color: #9d9d9d;
  white-space: nowrap;
  flex-shrink: 0;
}

.online-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00c206;
  position: absolute;
  left: 24px;
  top: 20px;
}

.user-offline {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a4a4a4;
  position: absolute;
  left: 24px;
  top: 20px;
}

.away-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: hsl(58, 92%, 51%);
  position: absolute;
  left: 24px;
  top: 20px;
}

.unseen-message-count {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  background-color: var(--icon-color2);
  position: absolute;
  left: 20px;
  top: 14px;
  text-align: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 9px !important;
  color: #fff !important;
}

.chat-user-list {
  overflow: hidden;
  scroll-behavior: smooth;
}

.scroll-arrow-chat {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.scroll-arrow-chat.up {
  margin-bottom: 5px;
}

.scroll-arrow-chat.down {
  margin-top: 5px;
}

.scroll-arrow-chat:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.user-chat-collapse {
  overflow: hidden;
}

.chat-collapse-list-arrow-icon {
  color: var(--app-color-1);
  cursor: pointer;
  visibility: visible;
}
.chat-collapse-list-arrow-icon-hide {
  color: var(--app-color-1);
  cursor: pointer;
  visibility: hidden;
}
