.scrum-header .create-btn {
  top: 0 !important;
}

.backlog-wrap {
  width: 44%;
}

.scrum-wrap {
  width: 48%;
}

/* Backlog board */

.backlog-board-main {
  width: 100%;
  border: 1px solid #f1f4ff;
  border-radius: 6px;
}

.backlog-wrap:not(:last-child) .backlog-board-main {
  margin-bottom: 20px;
}

.backlog-board-head {
  padding: 15px;
  border-bottom: 1px solid #f1f4ff;
}

.backlog-head-txt h4 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
}

.backlog-head-txt p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  margin-bottom: 0 !important;
}

.backlog-board-task-list {
  padding: 10px 10px 0px 10px;
}

.backlog-task {
  background-color: #e9eeff;
  height: 50px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.scrum-taskName-label {
  min-width: 173px;
}

/* Sprint board */
.sprint-wrap {
  width: 55%;
}

.sprint-wrap :not(:last-child).sprint-board {
  margin-bottom: 20px;
}

.sprint-board {
  --bs-accordion-border-color: #f1f4ff !important;
}

.sprint-board .accordion-body {
  padding: 10px !important;
}

.sprint-board .accordion-item {
  border: 1px solid #f1f4ff !important;
}

.sprint-board .accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.sprint-tasks-wrap .backlog-task:last-child {
  margin-bottom: 0 !important;
}

.sprint-header-heading {
  margin-bottom: 0.5rem !important;
  font-size: var(--heading-size);
  font-weight: var(--heading-weigth);
}

.sprint-header-heading svg {
  color: var(--app-color-1);
}

.sprint-header-heading svg:hover {
  color: var(--app-color-2);
}

.sprint-board .accordion-button:focus {
  box-shadow: none;
  border-bottom: 1px solid #f1f4ff !important;
}

/* Style for the select element */
.select-scrum-style {
  width: 80px; /* Set the desired width */
  max-width: 80px;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  border: none;
  border-radius: 4px;
  padding: 5px;
  outline: none; /* Remove outline on focus */
}

/* Style for the selected option */
.select-scrum-style option {
  color: black; /* Adjust color as needed */
}

/* Style for the placeholder option */
.select-scrum-style option[disabled][hidden] {
  display: none; /* Hide the placeholder option */
}

/* Style for the select status element */
.select-status-scrum-style {
  width: 110px; /* Set the desired width */
  max-width: 110px;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  border: none;
  border-radius: 4px;
  padding: 5px;
  background-color: transparent;
  outline: none; /* Remove outline on focus */
}

/* Style for the selected status option */
.select-status-scrum-style option {
  color: black; /* Adjust color as needed */
}

/* Style for the placeholder status option */
.select-status-scrum-style option[disabled][hidden] {
  display: none; /* Hide the placeholder option */
}

.task-scrum-circle-wrap div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbe1f5;
}

.task-scrum-circle-wrap div p {
  margin-bottom: 0px !important;
  font-weight: var(--heading-weigth);
}

/* When no tasks present */
.no-sprint-tasks {
  height: 150px;
  border-radius: 6px;
  background-color: #e9eeff;
  border: 1px solid #f1f4ff;
}

.no-sprint-tasks h4 {
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
}

.no-sprint-tasks p {
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.no-sprint-tasks p span {
  color: var(--app-color-1);
  cursor: pointer;
}

/* Create new sprint */
.create-new-sprint {
  border-radius: 6px;
  border: 1px solid #f1f4ff;
  margin-top: 20px;
  padding: 10px;
}

.create-new-sprint-text-wrap {
  height: 80px;
  border-radius: 6px;
  background-color: #e9eeff;
}

.create-new-sprint-text-wrap p {
  margin-bottom: 0px !important;
  font-size: var(--heading-size);
  font-weight: var(--heading-weight);
}

.expiary-sprint-board-header-text {
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.sprint-task-count p {
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.sprint-task-count {
  background-color: #e9eeff;
  border-radius: 4px;
  min-width: 80px;
  max-width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sprint-task-status-count div {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sprint-task-status-count div:first-child {
  background-color: #fd1b1b;
}

.sprint-task-status-count div:nth-child(2) {
  background-color: #2098d1;
}

.sprint-task-status-count div:nth-child(3) {
  background-color: #e8910d;
}

.sprint-task-status-count div:nth-child(4) {
  background-color: #2dbf64;
}

.sprint-task-status-count div:last-child {
  background-color: transparent;
  border: 1px solid var(--app-color-2);
}

.sprint-task-status-count div:last-child i {
  margin-right: 0px;
  color: var(--app-color-2);
}

.sprint-task-status-count div:last-child:hover {
  background-color: var(--app-color-2);
}

.sprint-task-status-count div:last-child:hover i {
  color: #fff;
}

.sprint-task-status-count div:last-child p {
  color: var(--app-color-2) !important;
}

.sprint-task-status-count div:last-child:hover p {
  color: #fff !important;
}

.sprint-task-status-count div p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  margin-bottom: 0px !important;
  color: #fff;
}

.sprint-board-state-btn-wrap button {
  border-radius: 6px;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  min-width: 100px;
  height: 35px;
  font-weight: var(--heading-weigth);
}

.sprint-finish-btn {
  background-color: var(--app-color-2);
  border: none;
  color: #fff;
}

.sprint-finish-btn:hover {
  background-color: var(--app-color-1);
}

.sprint-start-btn {
  background-color: transparent;
  border: 1px solid var(--app-color-2);
  color: var(--app-color-2);
}

.sprint-start-btn:hover {
  background-color: var(--app-color-2);
  color: #fff;
}

.sprint-board-header-right-wrap {
  margin-right: 15px;
}

.sprint-board .accordion-item:first-of-type .accordion-button {
  height: 84px;
}

.user-list-scrum div {
  min-height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
}

.user-list-scrum div:first-child {
  border: 1px solid #b0e57c;
  margin-right: -10px;
}
.user-list-scrum div:nth-child(2) {
  border: 1px solid #56baec;
  margin-right: -10px;
}
.user-list-scrum div:nth-child(3) {
  border: 1px solid #b4d8e7;
}

.user-list-scrum div:last-child {
  border: 1px solid var(--app-color-2);
  cursor: pointer;
}

.user-list-scrum div:last-child:hover {
  background-color: var(--app-color-2);
}

.user-list-scrum div:last-child:hover i {
  color: #fff;
}

.user-list-scrum div p {
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
