.modal-overlay-funds {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  font-family: "Poppins", sans-serif;
}

.modal-funds {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  padding: 30px;
  padding-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  height: auto;
  position: relative;
}

.modal-funds form .form-group label {
  font-weight: 700;
  font-size: var(--text-size);
  color: #333333;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
}

.modal-funds h2 {
  margin-top: 0;
}

.modal-close-button {
  margin-top: 10px;
  background-color: #3498db;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-funds form .form-group input {
  background: #f9f9f9;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: 50px;
  font-size: var(--text-size) !important;
  padding-left: 20px;
}

.modal-funds form .form-group input::placeholder {
  color: #b6c1cc !important;
}

.btn-fund {
  background-color: var(--app-color-2) !important;
  color: #fff !important;
  margin-right: 0 !important;
  width: 100% !important;
  background: no-repeat;
  height: 42px;
  box-shadow: 5px 4px 19px 0px #ebebeb;
  text-transform: none !important;
  font-weight: bold !important;
  border-radius: 6px !important;
  /* text-transform: uppercase !important; */
  font-size: var(--text-size) !important;
  border: none !important;
  margin-top: 35px;
  margin-bottom: 10px;
  font-weight: 500 !important;
}

.btn-fund:hover {
  background-color: var(--app-color-1) !important;
}

.btn-withdraw {
  background-color: var(--app-color-2) !important;
  color: #fff !important;
  margin-right: 0 !important;
  width: 130px !important;
  background: no-repeat;
  height: 38px;
  box-shadow: 5px 4px 19px 0px #ebebeb;
  text-transform: none !important;
  font-weight: bold !important;
  border-radius: 6px !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  border: none !important;
  margin-top: 20px;
}

.ElementsApp input {
  /* background: #f9f9f9 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      height: 50px !important;
      font-size: var(--text-size) !important;
      padding-left: 20px !important;
      color: #ababab !important; */
}

.fund-group {
  margin-top: 10px !important;
  padding-top: 10px;
  text-align: start;
}

.stripe-logo {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50% !important;
  position: relative !important;
  background: var(--app-color-1) !important;
  margin-top: -110px !important;
  padding-top: 50px !important;
  text-align: center !important;
  margin-bottom: 15px !important;
}
.stripe-logo img {
  width: 130px;
}
.funds-heading {
  font-size: 25px;
  color: #333333;
}
.ElementsApp input {
  padding: 25px 15px !important;
}

/* .fund-group iframe{ */
/* background: #f9f9f9 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    height: 50px !important;
    font-size: var(--text-size) !important;
    padding-left: 20px !important;
    color: #ababab !important;
    padding: 17px 15px !important; */
/* } */

.cn-wrapper {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: 50px !important;
  font-size: var(--text-size) !important;
  padding-left: 20px !important;
  color: #ababab !important;
  padding: 17px 15px !important;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.btn-fund div {
  width: 30px !important;
  height: 30px !important;
}

.fund-info-relative {
  position: relative;
}

.fund-info {
  position: absolute;
  top: 94px;
  color: #e93131;
  font-size: 12px;
  right: 0;
}
.fund-info-amount {
  position: absolute;
  top: 82px;
  color: red;
  font-size: 12px;
}

.payment-modal-field-err {
  border: 1px solid #e93131 !important;
}

.ag-popup {
  z-index: 1000 !important; /* Adjust the value as needed */
}

.summery-payment-input {
  background-color: #fff !important;
  padding: 0 !important;
  height: 15px !important;
  color: var(--app-color-1) !important;
}

.payment-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #000;
  filter: invert(1) grayscale(100%) brightness(60%);
}

/*------------- New payment modal ---------------------------*/
.popup-new-stripe-wrap {
  padding: 45px 25px;
  background: #fff;
  position: relative;
  border-radius: 6px;
}

.popup-new-stripe-wrap .listing-popup-plan-summary {
  text-align: start !important;
}

.popup-new-stripe-wrap h4 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}
.popup-new-stripe-wrap ul {
  list-style: none;
  margin-top: 5px;
}
.popup-new-stripe-wrap ul li {
  font-size: var(--text-size);
  color: #000;
  margin-top: 5px;
  border-bottom: 1px solid #f1f4ff;
  padding: 8px 0;
}
.popup-new-stripe-wrap ul li:nth-last-child(1) {
  border: none;
}

.popup-new-stripe-wrap ul li strong {
  float: right;
  font-weight: normal;
}
.stripe-main-form-wraper {
  border: 1px solid #f1f4ff;
  padding: 25px;
  border-radius: 10px;
  padding-top: 0px;
}
.stripe-logo-wrap {
  width: 30%;
  margin: 0 auto;
}
.stripe-main-form-wraper .form-group {
  margin-bottom: 15px;
}
.stripe-main-form-wraper .form-group label {
  font-size: 12px;
  font-weight: 400;
}
.stripe-main-form-wraper .form-group .form-control {
  height: 40px;
  font-size: 12px;
  border: 1px solid #ebf0ff;
  box-shadow: none;
}
.stripe-main-form-wraper .form-group .form-control:focus {
  border: 1px solid #94abff;
}
.buy-plan-button button {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  color: #fff;
  background: var(--app-color-2);
  border: none;
  outline: none;
  font-size: 13px;
}

.buy-plan-button button:hover {
  background: var(--app-color-1);
}

.popup-new-stripe-wrap .fund-info {
  top: 66px;
}

.popup-new-stripe-wrap .fund-group {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.popup-new-stripe-wrap .cn-wrapper {
  background-color: #fff !important;
  height: 40px !important;
  font-size: var(--text-size) !important;
  border: 1px solid #ebf0ff !important;
  box-shadow: none;
  padding: 11px 15px !important;
}

.popup-new-stripe-wrap .buy-plan-button {
  margin-top: 10px;
}

.popup-new-stripe-wrap .payment-modal-field-err {
  border: 1px solid #e93131 !important;
}

.stripe-package-name {
  color: var(--app-color-2);
}

.popup-new-stripe-wrap .form-group label {
  font-size: var(--text-size);
  color: #333;
}

.stripe-logo-main {
  width: 230px;
  margin: 15px 0px;
}

.stripe-logo-one {
  width: 30px;
  margin-top: 15px;
}

.funds-fields-info {
  color: var(--app-color-2);
}

.tooltip.show {
  z-index: 99999;
}
