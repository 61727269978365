.day-time-start-modal .modal-content {
  padding: 20px 0 0 0 !important;
  border-radius: 20px;
  width: 480px;
  border: none;
  overflow: hidden;
  font-family: var(--text-font-family);
}

.day-time-start-modal .modal-footer {
  padding: 0 !important;
}

.day-time-start-modal .modal-footer > * {
  margin: 0 !important;
}

.day-time-start-modal-heading {
  color: var(--exotic-font-color);
  font-size: var(--heading-size);
  font-style: normal;
  font-weight: 600;
  line-height: 142.1%; /* 21.315px */
}

.day-time-end-modal-delete-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  top: -4px;
  right: 16px;
}
