.live-chat-dashboard-wraper {
  padding: 0px;
  padding-bottom: 75px;
  border-radius: 20px 0 0px 0px;
  background: #fff;
  min-height: 100vh;
}

.live-chat-wrapper {
  display: flex;
  /* align-items: center; */
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
}

/* // users list wrapper */
.user-lists-main-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  background-color: var(--chat-input-bg);
  /* height: 100%; */
}

.users-list-search-main {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 15px;
  height: 70px;
  border-bottom: 2px solid var(--divider-color);
  position: sticky;
  background: var(--chat-input-bg);
  top: 0;
  z-index: 1;
}

.user-lists-card-wrapper {
  /* flex: 1; */
  overflow-y: auto;
  position: sticky;
  top: 70px;
}
.user-chat-message-type-2 a {
  color: white !important;
}
.user-chat-message-type-1 a {
  color: #333333 !important;
}
.users-list-search-input-wrapper {
  background-color: var(--search-bar-bg);
  border: 1px solid var(--divider-color);
  border-radius: 6px;
  padding: 8px 10px;
  flex: 1;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}

.users-list-search-input {
  border: none;
  outline: 0;
  flex: 1;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  background-color: var(--search-bar-bg);
  color: var(--font-color);
}
.user-list-search-icon {
  color: var(--icon-color);
}
.users-list-search-input:focus {
  outline: 0;
  border: none;
  box-shadow: none;
}

.users-list-search-edit-btn {
  background-color: var(--app-color-1);
  border-radius: 6px;
  height: 40px;
  width: 38px;
  border: none;
}

.user-list-card-main {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  min-height: 68px;
  align-items: center;
  cursor: pointer;
  background-color: var(--chat-input-bg);
}

.user-list-card-main:hover {
  background-color: #b6b6b627;
}

.user-list-card-main-selected {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  min-height: 68px;
  align-items: center;
  background-color: var(--icon-color2);
  cursor: pointer;
}

.user-list-card-user-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.user-list-card-user-image-name-selected {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  color: var(--app-color-1);
  font-size: var(--heading-size);
  font-weight: var(--heading-weigth);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-list-card-user-image-name {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--icon-color);
  color: white;
  font-size: var(--heading-size);
  font-weight: var(--heading-weigth);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-list-card-user-name {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.user-list-card-user-name h3 {
  font-size: 16px;
  font-weight: var(--heading-weigth);
  margin: 0;
  padding: 0;
  color: var(--font-color);
}

.user-list-card-user-name p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #9d9d9d;
  padding: 0;
  margin: 0;
}
.user-list-card-user-name-p {
  font-size: var(--text-size);
}
.text-size-weight {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  margin: 0;
  padding: 0;
  word-break: break-all;
}

.card-text-white {
  color: white !important;
}

.card-text-gray {
  color: white !important;
}

/* // user chat wrapper */
.user-chat-main-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0.7;
  /* height: 100%; */
}

.user-chat-profile-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 11px 18px;
  height: 70px;
  border-bottom: 2px solid var(--divider-color) !important;
  /* position: sticky;
  top: 0; */
  background: var(--card-color) !important;
  z-index: 1;
}

.user-chat-profile-btns {
  background-color: #f2f5ff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-chat-profile-btns svg {
  color: var(--app-color-2);
}

.user-chat-messages-wrapper {
  flex: 1;
  padding: 5px 18px;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  gap: 8px;
}

.user-chat-message-type-1 {
  /* width: 75%; */
  padding: 6px 8px 6px 8px;
  background-color: var(--text-message-bg-1);
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  word-break: break-word;
}

.user-chat-message-type-2 {
  /* width: 75%; */
  padding: 6px 8px 6px 8px;
  background-color: var(--icon-color2);
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  word-break: break-word;
  /* // margin-left: 25%; */
}

.user-chat-message-options .dropdown button {
  background-color: #f2f5ff;
  border-radius: 50%;
  opacity: 0.7;
  border: none;
}

.user-chat-message-options .dropdown button svg {
  color: #222222;
}

.user-chat-message-options .dropdown button:hover {
  opacity: 1;
}

.user-chat-message-options .dropdown button::after {
  /* // position: absolute; */
  display: none;
}

.user-chat-message-input-wrapper {
  padding: 18px;
  width: 100%;
  /* position: sticky;
  bottom: 0; */
  background: var(--card-color);
  border-top: 1px solid var(--divider-color);
}

.user-chat-message-input-container {
  background-color: var(--chat-input-bg);
  color: var(--font-color);
  border-radius: 8px;
  padding: 11px 18px;
}

.user-chat-message-input-reply-message-close {
  background-color: white;
  border-radius: 50%;
  height: 25px;
  cursor: pointer !important;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-chat-message-input-reply-message-close svg {
  color: var(--app-color-1);
}

.user-chat-message-input-reply-message {
  border-bottom: 1px solid var(--app-color-1);
  padding-bottom: 6px;
}

.sender-reply {
  border-bottom: 1px solid #fff !important;
}

.user-chat-message-input-reply-message:first-child {
  flex: 1;
}

.user-chat-message-input-reply-message .icons {
  color: var(--app-color-1);
  min-width: 14px;
}

.reply-section-line {
  width: 2px;
  height: 100%;
  background-color: var(--app-color-1);
}

.sender-reply .icons {
  color: #fff !important;
}

.sender-reply p {
  color: #fff;
}

.user-chat-message-input-container input {
  flex: 1;
  border: none;
  outline: 0;
  background-color: transparent;
  height: 32px;
  color: var(--font-color);
}

.user-chat-message-input-container .message-link {
  color: var(--icon-color);
  cursor: pointer;
}

.user-chat-message-input-container .message-audio {
  color: var(--icon-color);
  cursor: pointer;
}

.user-chat-message-input-container .message-send {
  color: var(--icon-color);
  cursor: pointer;
}

.user-typing-text {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #fff;
}

.chat-selected-file-attachment-container {
  border-bottom: 1px solid var(--app-color-1);
}

.chat-selected-file-attachment {
  border-radius: 6px;
  background-color: #fff;
}

.chat-selected-file-attachment svg {
  width: 50px;
  height: 50px;
}

.chat-selected-file-attachment p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.chat-selected-file-attachment p span {
  color: var(--app-color-1);
  font-weight: 400;
}

.load-more-chat {
  color: var(--app-color-1);
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.load-more-chat::before,
.load-more-chat::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 150px;
  /* Adjust the width of the lines */
  height: 1px;
  /* Line thickness */
  background-color: var(--app-color-1);
  transform: translateY(-50%);
}

.load-more-chat::before {
  left: -170px;
  /* Adjust position of the left line */
}

.load-more-chat::after {
  right: -170px;
  /* Adjust position of the right line */
}

/* Forward Message List*/

.forward-user-list-modal .modal-dialog {
  max-width: 600px;
}

.forward-user-list-modal .modal-content {
  border-radius: 24px !important;
}

.forward-user-list-modal .modal-body {
  padding: 20px 26px 0px 26px !important;
}

.forward-message-button {
  border-radius: 4px;
  border: 1px solid var(--icon-color2);
  background: var(--icon-color2);
  color: #fff;
  height: 40px;
  font-size: var(--text-size);
}

.forward-message-button:hover {
  background: var(--icon-color);
  border: 1px solid var(--icon-color);
}

.forward-message-user {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--divider-color) !important;
  background-color: var(--card-color);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: var(--font-color);
  cursor: pointer;
}

.list-group-forward-users {
  max-height: 300px;
  overflow-y: auto;
  border-radius: none !important;
}

.user-list-forward-user-image-name {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--icon-color);
  color: white;
  font-size: var(--heading-size);
  font-weight: var(--heading-weigth);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-list-forward-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.forward-user-modal-footer {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 10px !important;
  padding: 20px 26px !important;
  border-top: 0 !important;
  box-shadow: 0px -1px 24px 0px rgba(0, 0, 0, 0.09);
  z-index: 1;
}

.forward-user-search {
  border-left: 0 !important;
}

.forward-input-search input {
  text-align: start;
  height: 45px;
  border: 1px solid var(--divider-color) !important;
  box-shadow: none;
  background-color: var(--chat-input-bg);
  color: var(--font-color);
  outline: none !important;
  font-size: var(--text-size) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 4px;
  padding: 0 10px;
}

.forward-no-user {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.forwarded-text {
  font-size: 13px;
  color: #89898989;
}

.forward-text-user {
  font-size: 13px;
  color: #ffffff;
}

.unseen-message-count-modal {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  background-color: var(--app-color-2);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  font-size: 9px !important;
  color: #fff !important;
  line-height: 0;
}

/* File attachments */
.message-file-attachment-section {
  margin-bottom: 6px;
}

.message-file-attachment-grid {
  display: grid;
  grid-template-columns: 1fr;
  /* Ensures only one item per row */
  gap: 16px;
  /* Spacing between grid items */
}

.message-file-attachment-item {
  background-color: #4f65ac;
  padding: 12px;
  border-radius: 8px;
}

/* .message-file-attachment-item p {
  color: #fff;
} */

.message-file-attachment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: no-wrap;
}

.message-file-attachment-name {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #1f2a49;
}

.message-file-attachment-name svg {
  width: 40px;
  height: 50px;
}

.message-file-attachment-name img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.message-file-attachment-name img {
  border-radius: 4px;
}

.message-file-attachment-size {
  border-radius: 8px;
  border: 1px solid #e5ebff;
  background: #fff;
  font-size: 12px;
  color: #1f2a49;
  padding: 7px;
  flex-shrink: 0;
  cursor: pointer;
}

.message-file-attachment-size-sender {
  border-radius: 8px;
  border: 1px solid #fff;
  background: transparent;
  font-size: 12px;
  color: #fff;
  padding: 7px;
  flex-shrink: 0;
  cursor: pointer;
}

.chat-attachment-file-name {
  font-weight: 400;
  color: #fff;
}

.chat-attachment-file-size {
  color: #fff;
}

.chat-attachment-file-name-receiver {
  font-weight: 400;
  color: #333;
}

.chat-attachment-file-size-receiver {
  color: #333;
}

.chat-time-text {
  font-size: 12px !important;
  color: rgb(137, 137, 137);
}

.message-file-attachment-size svg {
  color: var(--app-color-1);
}

.online-status-chat-profile {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00c206;
}
.away-status-chat-profile {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f5ed0f;
}

.chat-message-container {
  max-width: 90%;
}

.chat-seen-at {
  color: #9d9d9d;
}

.chat-message-textarea {
  background-color: initial;
  border: none;
  flex: 1 1;
  height: 32px;
  outline: 0;
}

@media screen and (max-width: 1300px) {
  .user-list-card-user-name h3 {
    font-size: 14px;
  }

  .user-list-card-user-name p {
    font-size: 12px;
  }
  .user-list-card-user-name-p {
    font-size: 12px;
  }
}

.chat-message-date-group-header {
  background: var(--app-color-1);
  font-size: var(--text-size);
  display: flex;
  align-items: center;
  color: #fff;
  max-width: max-content;
  border-radius: 5px;
  padding: 6px 8px;
}
