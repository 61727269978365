/* Custom styles to match the design */
.performance-title {
  font-size: 24px;
  color: #333;
  font-weight: 600;
}

.update-btn {
  background-color: #4154f1 !important;
  border-color: #4154f1 !important;
  padding: 8px 20px;
  font-size: 14px;
}

.update-btn:hover {
  background-color: #3445d6 !important;
  border-color: #3445d6 !important;
}

.main-card {
  border-radius: 10px;
  border: 1px solid #dee2f1;
  background: #fff;
}

.section-title {
  color: #1f2a49;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.kpi-main-card-section-title {
  color: #1f2a49;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.kpi-main-card-section-title span {
  font-weight: 300;
}
.kpi-main-card-section-title2 {
  color: #1f2a49;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.kpi-card-points-section {
  border-top: 1px solid #dee2f1;
  border-bottom: 1px solid #dee2f1;
}
.kpi-main-card-section-title2 span {
  font-weight: 300;
}
.description-text {
  color: #000;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 400;
}
.text-secondary {
  color: #000;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 400;
}
.info-icon {
  background: none;
  border: none;
  color: #999;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
}

.chevron-btn {
  color: #4154f1 !important;
  text-decoration: none !important;
}

.chevron-btn i {
  font-size: 20px;
  transition: transform 0.3s ease;
}

.chevron-btn i.bi-chevron-up {
  transform: rotate(0deg);
}

.chevron-btn i.bi-chevron-down {
  transform: rotate(180deg);
}

.point-input-container {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
}

.point-label {
  color: #000;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}

.input-group {
  width: auto;
  flex: 1;
}

.input-group .form-control {
  width: 60px;
  text-align: center;
  border-left: none;
  border-right: none;
  background-color: #fff;
}

.input-group .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.minus-btn {
  background-color: rgba(65, 84, 241, 0.1) !important;
  border: none !important;
  color: #94abff !important;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plus-btn {
  background-color: #94abff !important;
  border: none !important;
  color: white !important;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.section-title-second {
  color: #394b87;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.1%; /* 17.052px */
}

.form-check-input:checked {
  background-color: var(--app-color-1);
  border-color: var(--app-color-1);
}
.form-check-input {
  border-color: var(--app-color-1) !important;
}
.point-input-container-btn-section {
  border-radius: 4px;
  border: 1px solid #e9eeff;
  background: #fff;
  padding: 6px;
}
.point-input-container-btn-section p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.kpi-container {
  padding: 20px;
  margin: 0 auto;
}

.kpi-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.title-section {
  flex: 1;
}
.kpi-title-input-field {
  max-width: 320px;
}
.kpi-card-divider {
  background: #dee2f1;
  height: 1px;
}
.form-label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.info-icon {
  background: none;
  border: none;
  color: #999;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  width: 100%;
}

.form-control:focus {
  border-color: #4154f1;
  box-shadow: 0 0 0 0.2rem rgba(65, 84, 241, 0.25);
}

.button-group {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.update-btn {
  background-color: #4154f1 !important;
  border-color: #4154f1 !important;
  color: white;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 6px;
}

.update-btn:hover {
  background-color: #3445d6 !important;
  border-color: #3445d6 !important;
}

.close-btn {
  padding: 8px;
  border: none;
  background: transparent !important;
  color: #666;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.close-btn:hover {
  background-color: #f8f9fa !important;
  color: #333;
}

.performance-card-menu-btn {
  border-radius: 6px;
  border: 1px solid #dee2f1 !important;
  background: #fff;
  width: 30px;
  height: 30px;
}

.performance-card-menu-btn svg {
  color: var(--app-color-1);
}

#pskpi-my-tooltip {
  max-width: 300px;
  z-index: 9999;
}
#pskpi-canvas-my-tooltip {
  max-width: 300px;
}
#pskpi-canvas-owner-card-my-tooltip {
  max-width: 300px;
}
#pskpi-canvas-assigne-card-my-tooltip {
  max-width: 300px;
}

.pt-menu-dropdown svg {
  color: var(--app-color-1);
}

.total-point-unique-layout {
  border: 1px solid var(--app-color-1);
  background: #f2f5ff;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .kpi-header {
    flex-direction: column;
  }

  .button-group {
    width: 100%;
    justify-content: flex-end;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .point-input-container {
    margin-bottom: 16px;
  }
}
