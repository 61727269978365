.contact-modal .modal-content {
  min-width: 600px;
}

.contact-modal {
  backdrop-filter: blur(20px);
}

.contact-form-wrapper .form-control {
  min-height: 42px;
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  appearance: auto;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.contact-para p {
  font-weight: var(--text-font-weight);
  font-size: var(--text-size);
  text-align: start;
}

.contact-form-wrapper .form-label {
  font-size: var(--text-size);
  font-weight: 500;
}

.contact-form-wrapper .row {
  margin-bottom: 15px;
}

.contact-form-wrapper .PhoneInputInput {
  height: 42px;
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}

.contact-modal .modal-header {
  border-bottom: none;
}

.contact-modal .modal-dialog {
  display: flex;
  justify-content: center;
}

.contact-form-wrapper .PhoneInputInput:focus {
  outline: none;
}

.err-pn {
  font-size: 13px;
  color: #e93131;
  text-align: end;
  margin-top: 2px;
}

.invalid-phone .PhoneInputInput {
  border: 1px solid #e93131;
}

@media (max-width: 700px) {
  .contact-modal .modal-content {
    min-width: 98vw;
  }
}
