.see-more-header-span {
  font-weight: var(--text-font-weight);
}

.see-modal-header h6 {
  color: var(--font-color);
  font-size: var(--heading-size);
}

.see-modal-header .btn-close {
  position: absolute !important;
  right: 0;
  top: 0;
}

.see-more-body-wrap p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
}
